import {
    Directive,
    ElementRef, Inject, Injector, Output, EventEmitter,Input, SimpleChanges
} from "@angular/core";
import 'bootstrap-daterangepicker';

@Directive({
    selector: "[daterangepicker]"
})
export class DateRangePickerDirective {
    @Input() startDate;
    @Input() endDate;
    @Output() dateApplied = new EventEmitter<any>();
    //properties for angular-daterangepicker
    currentDate: Date;
    date = {};
    opts: {};
    constructor(@Inject(ElementRef) private el: ElementRef) { }
    ngAfterViewInit() {

        this.setDatePicker();
        
    }
    setDatePicker() {
        if ($(this.el.nativeElement).data('daterangepicker')) {
            return;
        }
        this.currentDate = new Date();
        var self = this;

        $(this.el.nativeElement).daterangepicker({
            maxDate: new Date(),
            locale: {

                applyLabel: "Apply",
                format: "YYYY-MM-DD",
                cancelLabel: 'Cancel',
                fromLabel: "From",
                toLabel: "To",
                customRangeLabel: 'Custom Range',
                daysOfWeek: [],
                monthNames: [
                    "January",
                    "February",
                    "March",
                    "April",
                    "May",
                    "June",
                    "July",
                    "August",
                    "September",
                    "October",
                    "November",
                    "December"
                ]
            },
            ranges: {
                'Last 6 Months': [this.getFilterDate('month', 6), new Date()],
                'Last 12 Months': [this.getFilterDate('year', 1), new Date()],
                'Last 3 Years': [this.getFilterDate('year', 3), new Date()],
            }
        });
        $(this.el.nativeElement).on('apply.daterangepicker', function (ev, picker) {
            self.dateApplied.emit(picker);
        });
    }
    ngOnChanges(changes: SimpleChanges) {
        if (this.el && this.el.nativeElement) {
            this.setDatePicker();
            if ("startDate" in changes || "endDate" in changes) {
                if (this.startDate) {
                    $(this.el.nativeElement).data('daterangepicker').setStartDate(this.startDate);
                }
                if (this.endDate) {
                    $(this.el.nativeElement).data('daterangepicker').setStartDate(this.endDate);
                }
            }
        }
    }
    getFilterDate(type, amount) {
        this.currentDate = new Date();
        if (type == 'month')
            // this.currentDate.setMonth(this.currentDate.getMonth() - amount);
            this.currentDate = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() - amount, 1)
        else
            //this.currentDate.setFullYear(this.currentDate.getFullYear() - amount);
            this.currentDate = new Date(this.currentDate.getFullYear() - amount, this.currentDate.getMonth(), 1)

        return this.currentDate;
    }
}