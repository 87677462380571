import {Inject, Pipe, PipeTransform} from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser'

@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
    constructor(@Inject(DomSanitizer) private sanitized: DomSanitizer) { }
    transform(value) {
        //console.log('sanitized before', value)
        setTimeout(() => {
            let postSani = this.sanitized.bypassSecurityTrustHtml(value);
            //console.log('postSani', postSani);
            return postSani;
        }, 1000);
        
    }
}
