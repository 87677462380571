///<reference path="../../../../node_modules/@types/jquery/index.d.ts"/>
import { Component, Inject, Input, OnInit } from '@angular/core';
import { DictionaryService } from './dictionary.service';
import { IDorlandDictionaryEntry } from './models';

import dictionaryDetailTemplate from './dictionary-detail.html';

@Component({
    selector: 'dictionary-detail',
    template: dictionaryDetailTemplate
})

export class DictionaryDetailComponent {
   
    constructor(@Inject(DictionaryService) private dictionaryService: DictionaryService) {
        
    }

    @Input() dictionaryid: number;
    isLoading: boolean = true;
    // Item List Array
    dict: IDorlandDictionaryEntry = null;

    // URL for dictionary web service
    // Pull back all dictionary appendices
    ngOnInit() {
        this.isLoading = true;
        this.dictionaryService.getDictionary(this.dictionaryid).then(
            (response) => {
                this.dict = response;
                setTimeout(this.dictSetup, 1000);
                this.isLoading = false;
            },
            response => {
                this.isLoading = false;
                console.error(response);
            });
    }

   

    dictSetup() {
        // Code Data mouseovers should be hidden at first
        $(".codeData").hide();

        $(".cdCode").on("mouseover mouseout", function (event) {
            if (event.type === "mouseover") {
                $(this).children(".codeData").show();
            } else {
                $(this).children(".codeData").hide();
            }
        });
        
        // Set ICD9 crosslink count in header
        const icdCodeCount = $("#divCrossLinks").find(".cdCode").length;
        $("#icdCountNew").text(icdCodeCount);

        // Hide header in data since we've got a tab header now
        $('#divCrossLinks').find('.title').hide();

        // Set up image enlargement functionality

        $(".thumbnail").click(function () {
            $("#imgbackground").css({"opacity": "0.7"})
                .fadeIn("slow");

            $("#large").html(
                "<img src='" + $(this).parent().attr("href") + "' /><div class='modal-footer'><button type='button' class='btn btn-default'>Close</button></div><br />" + "")
                .center()
                .fadeIn("slow");

            return false;
        });

        openImagesLinksInNewTab();

        $(document).keypress(e => {
            if (e.keyCode === 27) {
                $("#imgbackground").fadeOut("slow");
                $("#large").fadeOut("slow");
            }
        });

        $("#imgbackground,#large,.close-detail-img").click(() => {
            $("#imgbackground").fadeOut("slow");
            $("#large").fadeOut("slow");
            $(".closeDetailImg").fadeOut("slow");
        });
    };
}


function openImagesLinksInNewTab() {
    var definition = $('.definition')
    var links = $('a', definition)

    if (links.length > 0)
    {
        links.each(function (index, link) {
            if (/\.(?:jpg|jpeg|gif|png)$/i.test($(link).attr('href'))) {
                link.setAttribute('target', '_blank');
            }
        })
    }
}