import { Component, Inject, Input, OnInit } from '@angular/core';
import { DictionaryService } from "./dictionary.service";
import { IAppendixEntry } from "./models";

import dictionaryAppendeciesTemplate from './dictionary-appendecies.html';

@Component({
    selector: 'dictionary-appendecies',
    template: dictionaryAppendeciesTemplate
})
export class DictionaryAppendicesComponent {

    appendices: IAppendixEntry[] = [];

    constructor(@Inject(DictionaryService)private dictionaryService: DictionaryService) {
    }

    ngOnInit() {
        // Get appendix content from web service and display, replacing loading image
        this.dictionaryService.getAppendecies().then(
            (response) => {
                const data = response;
                this.appendices = [...data];
    
                for (let i = 0; i < this.appendices.length; i++) {
                    const e = this.appendices[i];
                    e.Body = e.Body.replace(e.Title, "");
                }
            },
            response => {
                console.error(response);
            });
    }
}
