import { Component, ElementRef, Inject, Input, ViewChild } from '@angular/core';
import { UserNotesService } from "./userNotes.service";

import userNotesModalTemplate from './user-notes-modal.html';

@Component({
    selector: 'user-notes-modal',
    template: userNotesModalTemplate
})
export class UserNotesModalComponent {

    constructor(@Inject(UserNotesService) private userNotesService: UserNotesService) {

    }

    @Input() isadmin: Boolean;

    // set the arrays as blank to start
    icd10CodeNotes = [];
    oasisCodeNotes = [];
    expiredCodeNotes = [];
    expiredOasisNotes = [];

    // variables for storing the sort order
    icd10currentSortOrder = "";
    oasisCurrentSortOrder = "";
    expiredCurrentSortOrder = "";
    oasisExpiredCurrentSortOrder = "";
    icd10Reverse = false;
    oasisReverse = false;
    expiredReverse = false;
    oasisExpiredReverse = false;

    // loading indicator
    notesLoading = false;

    searchText = "";

    ngOnInit() {

        this.displayUserNotes();
    }

    ngAfterViewInit() {      
        $("#noteSearch").keyup(function () {
            var noteSearchText = $("#noteSearch").val();
            if (noteSearchText != "") {
                $(".notes-expand").each(function () {
                    var isExpanded = $(this).attr('aria-expanded');
                    if (isExpanded == null || isExpanded == 'false') {
                        $(this).trigger('click');
                    }
                });
            }
            else {
                $(".notes-expand").each(function () {
                    if (!$(this).hasClass("collapsed")) {
                        $(this).trigger('click');
                    }
                });
            }
        });
    }

    displayUserNotes() {
        this.notesLoading = true;

        this.userNotesService.getUserCodeNotes().then(response => {
            const data = response;

            if (data) {
                this.icd10CodeNotes = data.icd10Notes;
                this.oasisCodeNotes = data.oasisNotes;
                this.expiredCodeNotes = data.expiredNotes;
                this.expiredOasisNotes = data.expiredOasisNotes;
                this.notesLoading = false;
            }
        });   
    }


    // Sort the notes according to which column was clicked
    sortNotes(noteCodeType, sortBy) {

        if (noteCodeType == "ICD10") {

            if (this.icd10currentSortOrder == sortBy) {
                this.icd10Reverse = !this.icd10Reverse;
            }
            else {
                this.icd10Reverse = false;
            }

            this.icd10currentSortOrder = sortBy;

            if (sortBy == "Type") {
                this.icd10CodeNotes.sort(this.doSort("IsGroupNote", this.icd10Reverse, null));
            }
            else if (sortBy == "Code") {
                this.icd10CodeNotes.sort(this.doSort("Code", this.icd10Reverse, null));
            }
            else if (sortBy == "Description") {
                this.icd10CodeNotes.sort(this.doSort("Description", this.icd10Reverse, null));
            }
        }

        if (noteCodeType == "OASIS") {
            if (this.oasisCurrentSortOrder == sortBy) {
                this.oasisReverse = !this.oasisReverse;
            }
            else {
                this.oasisReverse = false;
            }

            this.oasisCurrentSortOrder = sortBy;

            if (sortBy == "Type") {
                this.oasisCodeNotes.sort(this.doSort("IsGroupNote", this.oasisReverse, null));
            }
            else if (sortBy == "Code") {
                this.oasisCodeNotes.sort(this.doSort("Code", this.oasisReverse, null));
            }
            else if (sortBy == "UserNote") {
                this.oasisCodeNotes.sort(this.doSort("UserNote", this.oasisReverse, null));
            }
        }

        if (noteCodeType == "Expired") {

            if (this.expiredCurrentSortOrder == sortBy) {
                this.expiredReverse = !this.expiredReverse;
            }
            else {
                this.expiredReverse = false;
            }

            this.expiredCurrentSortOrder = sortBy;

            if (sortBy == "Type") {
                this.expiredCodeNotes.sort(this.doSort("IsGroupNote", this.expiredReverse, null));
            }
            else if (sortBy == "Code") {
                this.expiredCodeNotes.sort(this.doSort("Code", this.expiredReverse, null));
            }
            else if (sortBy == "Description") {
                this.expiredCodeNotes.sort(this.doSort("Description", this.expiredReverse, null));
            }
        }

        if (noteCodeType == "OasisExpired") {

            if (this.oasisExpiredCurrentSortOrder == sortBy) {
                this.oasisExpiredReverse = !this.oasisExpiredReverse;
            }
            else {
                this.oasisExpiredReverse = false;
            }

            this.oasisExpiredCurrentSortOrder = sortBy;

            if (sortBy == "Type") {
                this.expiredOasisNotes.sort(this.doSort("IsGroupNote", this.oasisExpiredReverse, null));
            }
            else if (sortBy == "Code") {
                this.expiredOasisNotes.sort(this.doSort("Code", this.oasisExpiredReverse, null));
            }
            else if (sortBy == "UserNote") {
                this.expiredOasisNotes.sort(this.doSort("UserNote", this.oasisExpiredReverse, null));
            }
        }
    }

    // function for sorting the arrays
    doSort(field, reverse, primer) {
        var key = primer ?
            function (x) { return primer(x[field]); } :
            function (x) {
                return x[field];
            };

        let sortModifier = !reverse ? 1 : -1;

        return (a: any, b: any) => {
            const aval: any = key(a);
            const bval: any = key(b);

            const cmpVal: number = (aval === bval ? 0 : (aval > bval) ? 1 : -1);
            return sortModifier * cmpVal;
        };
    }

    convertNote(genericContentNoteId) {
        this.userNotesService.convertNote(genericContentNoteId);

        window.setTimeout(() => {
            this.userNotesService.getUserCodeNotes();
            this.displayUserNotes();
        }, 500);

    }

}