import { Component, Inject, Input, OnInit } from '@angular/core';
import { UsageReportingChartsService } from "./usage-reporting-charts.service";
import * as usageChartModels from "./usage-reporting-charts.models";

import template from './usage-reporting-charts.html';

@Component({
    selector: 'usage-reporting-charts',
    template: template
})
export class UsageReportingChartsComponent {

    chartCategory: usageChartModels.ChartCategory = usageChartModels.ChartCategory.Team;
    teamChartsCategory: usageChartModels.TeamChartsCategoryType = usageChartModels.TeamChartsCategoryType.Page;

    constructor(@Inject(UsageReportingChartsService) private userReportingChartsService: UsageReportingChartsService) {

    }

    changeCategory(category: usageChartModels.ChartCategory) {
        this.chartCategory = category;

        if (category === usageChartModels.ChartCategory.Individual) {
            this.teamChartsCategory = usageChartModels.TeamChartsCategoryType.Page;
        }
    }

    changeTeamChartType(teamChartType: usageChartModels.TeamChartsCategoryType) {
        this.teamChartsCategory = teamChartType;
    }

}
