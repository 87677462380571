import { Injectable, Inject } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import * as models from "./icd10.models";

export interface IIcd10BrowseWebService {
    loadChapter(chapterId: number): Promise<models.IIcd10Chapter>;
    loadSection(sectionId: number): Promise<models.IIcd10Section>;
    loadCode(sectionId: number, parentCode: string): Promise<models.IIcd10Code>;
}

@Injectable({
    providedIn: 'root'
})
export class Icd10BrowseWebService implements IIcd10BrowseWebService {
    constructor(@Inject(HttpClient) private http: HttpClient) {
    }
    loadChapterTree(codeYear) {
        var url = "/MVC/ICD10/BrowseChapterTree";
        if (codeYear)
            url = url + "?year=" + codeYear;
        return this.http.get<models.IIcd10Chapter[]>(url).toPromise();
    }
    loadChapter(chapterId) {
        const url = "/MVC/ICD10/BrowseChapter?id=" + chapterId;
        return this.http.get<models.IIcd10Chapter>(url).toPromise();
    }

    loadSection(sectionId) {
        const url = "/MVC/ICD10/BrowseSection?id=" + sectionId;
        return this.http.get<models.IIcd10Section>(url).toPromise();
    }

    loadCode(sectionId, parentCode) {
        const url = "/MVC/ICD10/BrowseCode?sectionId=" +
            encodeURIComponent(sectionId) +
            "&parentCode=" +
            encodeURIComponent(parentCode);
        return this.http.get<models.IIcd10Code>(url).toPromise();
    }
}
