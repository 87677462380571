import { Component, Inject, Input, ViewChild } from '@angular/core';
import { Icd9MappingService } from './icd9Mapping.service';
import icd9MappingWidgetTemplate from './icd9-mapping-widget.html';
import { ICD9MappingModalComponent } from './icd9-mapping-modal.component';

@Component({
    selector: 'icd9-mapping-widget',
    template: icd9MappingWidgetTemplate
})
export class ICD9MappingWidgetComponent {
    @ViewChild(ICD9MappingModalComponent) icd9MappingModal;
    constructor(@Inject(Icd9MappingService)private icd9MappingService: Icd9MappingService) {

    }

    @Input() pcode: string;
    @Input() maximize: string;

    tryCount: number;
    widgetTotal: number;
    widgetLength: number;
    gemsLoaded: Boolean;
    tryCounter: number;
    exactWidget: any[];
    approxWidget: any[];
    comboWidget: any[];


    loadWidget(pCode, maximize) {
        this.gemsLoaded = false;
        this.widgetLength = 10;
        this.tryCounter = 0;
        this.exactWidget = [];
        this.approxWidget = [];
        this.comboWidget = [];

        this.icd9MappingService.getMappings(pCode).then(
            (response) => this.ajaxCallbackSuccess(response, pCode, maximize),
            (response) => {
                console.error({ method: "icd9MappingWidget.$scope.LoadMappingWidget", response: response });
            });
    }

    // Map the submitted code to I10 codes and show the results in a modal display
    mapToICD10Codes(pCode) {
        console.log(pCode);
        this.icd9MappingService.fullMappings(pCode);
    }

    viewAll(pCode) {
        this.icd9MappingService.pCode = pCode;
    }

    ajaxCallbackSuccess = function (response, pCode, maximize) {
        if (response.status === 200) {
            const data = response;
            if (data !== "") {
                // Get the items for the code details widget
                this.widgetTotal = data.ExactCrosswalks.length + data.ApproxCrosswalks.length + data.ComboCount;

                if (this.WidgetTotal > 0) {
                    let remainingWidget = this.widgetLength;

                    if (data.ExactCrosswalks.length > 0) {
                        let exactLength = 0;
                        if (data.ExactCrosswalks > remainingWidget) {
                            exactLength = remainingWidget;
                            remainingWidget = 0;
                        }
                        else {
                            exactLength = data.ExactCrosswalks.length;
                            remainingWidget = remainingWidget - data.ExactCrosswalks.length;
                        }

                        if (maximize) {
                            this.exactWidget = data.ExactCrosswalks;
                        }
                        else {
                            this.exactWidget = data.ExactCrosswalks.slice(0, exactLength);
                        }
                    }

                    if (data.ApproxCrosswalks.length > 0) {
                        let approxLength = 0;
                        if (data.ApproxCrosswalks.length > remainingWidget) {
                            approxLength = remainingWidget;
                            remainingWidget = 0;
                        }
                        else {
                            approxLength = data.ApproxCrosswalks.length;
                            remainingWidget = remainingWidget - data.ApproxCrosswalks.length;
                        }

                        if (maximize) {
                            this.approxWidget = data.ApproxCrosswalks;
                        }
                        else {
                            this.approxWidget = data.ApproxCrosswalks.slice(0, approxLength);
                        }
                    }

                    this.comboWidget = [];

                    for (let i = 0; i < data.ComboCrosswalks.length; i++) {
                        for (let j = 0; j < data.ComboCrosswalks[i].length; j++) {
                            if (maximize === true || remainingWidget > 0) {
                                this.comboWidget.push(data.ComboCrosswalks[i][j]);
                                remainingWidget--;
                            }
                        }
                    }
                    this.gemsLoaded = true;
                }
            }
            else {
                // Sometimes we erroneously get no data, so attempt again
                if (this.tryCounter < 2) {
                    this.tryCounter++;
                    this.loadWidget(pCode, maximize);
                }
            }
        } else {
            // any other HTTPStatusCode is considered an error
            console.error({
                method: "icd9MappingWidget.$scope.LoadMappingWidget",
                response: response
            });
        }
    };
}
