import { Component, Inject, Input, OnInit } from '@angular/core';
import { DictionaryService } from "./dictionary.service";

import { IDorlandDictionaryEntry, IAjaxResultDorlandDictionarySearchResult } from './models';

import dictionaryResultsTemplate from './dictionary-results.html';

// URL for dictionary web service
const urlDictionarySearch = "/MVC/Dictionary/RunSearch";


@Component({
    selector: 'dictionary-results',
    template: dictionaryResultsTemplate
})
export class DictionaryResultsComponent {

    @Input() query: string;
    @Input() detailurl: string;

    resultsPage: number = 0;
    moreResults: boolean = false;
    searchResults: IDorlandDictionaryEntry[] = [];
    totalResults: number = 0;
    isLoading: boolean = false;

    constructor(@Inject(DictionaryService)private dictionaryService: DictionaryService) {
        
    }

    ngOnInit() {
        this.isLoading = true;

        if (!this.query || this.query.length === 0) {
            // Must verify the query input is a string type and not a zero-length string
            this.searchResults = [];
            this.moreResults = false;
            this.totalResults = 0;
            this.isLoading = false;
        } else {
            this.dictionaryService.performSearch(this.query, this.resultsPage).then((response) => {
                const data = response;
                this.searchResults = data.Results;
                this.moreResults = data.MoreResults;
                this.totalResults = data.TotalResults;
                this.isLoading = false;
            });
        }
    }

    nextPage() {
        this.changePage(this.resultsPage + 1);
    }

    prevPage() {
        this.changePage(this.resultsPage - 1);
    }

    changePage(newPageNumber: number) {
        this.isLoading = true;

        this.dictionaryService.performSearch(this.query, newPageNumber).then(response => {
            const data = response;
            this.resultsPage = newPageNumber;
            this.searchResults = data.Results;
            this.moreResults = data.MoreResults;
            this.isLoading = false;
        })
       

    }
}

