import { Component, Inject, Input, OnInit } from '@angular/core';
import { IContentItem } from "../content/content.models";

import { VideoService } from "./video.service";


import videoTemplate from './video.html';

@Component({
    selector: 'video-page',
    template: videoTemplate
})
export class VideoComponent {

    constructor(@Inject(VideoService) private videoService: VideoService) {
    }

    @Input() tabindexvalue: number;
    @Input() isexpert: Boolean;

    // props
    videosLoaded: Boolean;
    icd10Videos: any = [];
    oasisVideos: any = [];

    totalcount: number;
    predicate: string = "DisplayDateRaw";
    reverse: Boolean;
   
    // Displays a count of a specific type of articles
    ngOnInit() {
       // Indicates if we should show results or loading wheel
        this.videosLoaded = false;
        this.videoService.getVideos().then(response => {
            this.icd10Videos = response.I10Contents;
            this.oasisVideos = response.OASISContents;
        });
    }

    sortBy(codeSet: string, prop: string) {
        if (codeSet == 'icd10') {
            if (this.icd10Videos) {
                return this.icd10Videos.sort((a, b) => a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1);
            }
        } else {
            if (this.oasisVideos) {
                return this.oasisVideos.sort((a, b) => a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1);
            }
        }    
    }

    orderResults(predicate: string) {
        this.reverse = (this.predicate === predicate) ? !this.reverse : false;
        this.predicate = predicate;
    }

    // Displays an HTML blurb of 300 characters
    bodyBlurb(description: string): string {
        return description.substring(0, 300);
    }

    // Formats JSON dates correctly
    dateFormatter(dateVal: string, format): string {
        const month: string[] = [];
        month[0] = "January";
        month[1] = "February";
        month[2] = "March";
        month[3] = "April";
        month[4] = "May";
        month[5] = "June";
        month[6] = "July";
        month[7] = "August";
        month[8] = "September";
        month[9] = "October";
        month[10] = "November";
        month[11] = "December";

        const d = new Date(parseInt(dateVal.replace(/\/Date\((.*?)\)\//gi, "$1"), 10));

        if (format === "full") {
            return month[d.getMonth()] + " " + d.getDate() + ", " + d.getFullYear();
        } else {
            return (d.getMonth() + 1) + "/" + d.getDate() + "/" + d.getFullYear();
        }
    }
}