import { Injectable, Inject } from '@angular/core';
import { HttpClient } from "@angular/common/http";

import { IAjaxResultAltSearchSuggestion, IAjaxResultI10Search, ICodeIconFlag, IIndexSearchResult, IOasisCrosslink } from './icd10.models';

@Injectable({
    providedIn: 'root'
})
export class ICD10SearchService {

    constructor(@Inject(HttpClient) private http: HttpClient) {
    }

    searchtype: number;
    keywords: string;
    year: string;
    INDEX_SEARCH_TYPE_ID: number = 8;

    doSearch(searchType, keywords, pageSize, pageIndex, sortBy, year) {
        const parameters = {
            params: {
                cst: searchType,
                sk: keywords,
                pageSize: pageSize.toString(),
                pageIndex: pageIndex.toString(),
                sortBy: sortBy,
                year: year
            }
        };

        return this.http.get<IAjaxResultI10Search>("/MVC/ICD10/ICD10Search", parameters).toPromise();
    }

    getAltResults(searchType: number, keywords: string) {
        const altSearchParam = { params: { cst: searchType.toString(), sk: keywords } };

        return this.http.get<IAjaxResultAltSearchSuggestion>("/MVC/ICD10/GetAlternativeSearchSuggestions", altSearchParam).toPromise();
    }

    getIndexParentData(indexTermId: number, indexVersionId: number, year: string) {
        const parentParams = {
            params: {
                icd10IndexTermId: indexTermId.toString(),
                icd10IndexVersionId: indexVersionId.toString(),
                year: year
            }
        };

        return this.http.get<IIndexSearchResult[]>("/MVC/ICD10/GetICD10IndexTermParents", parentParams).toPromise();
    }

    getIndexChildData(indexTermId: number, indexVersionId: number) {
        const childParams = {
            params: {
                icd10IndexTermId: indexTermId.toString(),
                icd10IndexVersionId: indexVersionId.toString(),
                year: this.year
            }
        };

        return this.http.get<any[]>("/MVC/ICD10/GetICD10IndexTermChildren", childParams).toPromise();
    }

    getIndexTermParents(indexTermId: number, versionId: number) {
        const parameters = { params: { icd10IndexTermId: indexTermId.toString(), icd10IndexVersionId: versionId.toString() } };

        return this.http.get<any[]>("/MVC/ICD10/GetICD10IndexTermParents", parameters).toPromise();
    }

    getIndexTermChildren(indexTermId: number, versionId: number) {
        const parameters = { params: { icd10IndexTermId: indexTermId.toString(), icd10IndexVersionId: versionId.toString() } };

        return this.http.get<any[]>("/MVC/ICD10/GetICD10IndexTermChildren", parameters).toPromise();
    }

    getOasisMappings(pCode: string) {
        const parameters = { params: { code: pCode } };

        return this.http.get<IOasisCrosslink[]>("/MVC/ICD10/GetOASISMappings", parameters).toPromise();
    }

    getSessionSearchResults() {
        return this.http.get<any>("/MVC/ICD10/GetICD10SavedSearchResults").toPromise();
    }

    //builds the links to the term or code search
    createIndexSearchLinks(text: string, refCodeSearch: any): string {

        // If the search type is not in the quesry string, default to alpha index
        if (!this.searchtype || isNaN(this.searchtype)) {
            this.searchtype = this.INDEX_SEARCH_TYPE_ID;
        }

        const separator1 = " > ";
        const separator2 = " || ";
        let html = "";
        let terms, i;

        if (text) {
            if (text.indexOf(separator1) > -1) {
                terms = text.split(separator1);
                for (i = 0; i < terms.length; i++) {
                    if (i > 0) {
                        html += " &gt; ";
                    }

                    if (!refCodeSearch) {
                        //term search link
                        html += this.createICD10SearchLink(this.searchtype, terms[i], this.highlightText(terms[i], this.keywords));
                    }
                    else {
                        //code search link
                        html += this.createICD10SearchLink(1, refCodeSearch, refCodeSearch);
                    }
                }
            }
            else if (text.indexOf(separator2) > -1) {
                terms = text.split(separator2);
                for (i = 0; i < terms.length; i++) {
                    if (!refCodeSearch) {
                        //term search link
                        html += this.createICD10SearchLink(this.searchtype, terms[i], this.highlightText(terms[i], this.keywords));
                    }
                    else {
                        //code search link
                        html += this.createICD10SearchLink(1, refCodeSearch, refCodeSearch);
                    }
                    if (i === (terms.length - 1)) {
                        html += ", ";
                    }
                }
            }
            else {
                if (!refCodeSearch) {
                    //term search link
                    html += this.createICD10SearchLink(this.searchtype, text, this.highlightText(text, this.keywords));
                }
                else {
                    //code search link
                    html += this.createICD10SearchLink(this.searchtype, refCodeSearch, refCodeSearch);
                }
            }
        }
        return html; //without $sce.trustAsHtml(), html will be rendered in plain text
    }

    //build the ICD-10 search link
    createICD10SearchLink(searchType: number, searchTerm: string, linkText: string): string {
        const strYear = this.year ? "&year=" + this.year : "";
        return "<a href=\"/MVC/ICD10/Search?cst=" +
            searchType.toString() +
            "&sk=" + encodeURIComponent(searchTerm).replace("category ", "").replace("subcategory", "") +
            strYear + "\">" +
            linkText + "</a>";
    }

    buildICD10CodeSearchLinkForIndexSearchResult(codeString: string, codeIconFlags: ICodeIconFlag[], includeResults: boolean): string {
        let html = "";
        const codes = (codeString ? codeString.split(" ") : []);

        for (let i = 0; i < codes.length; i++) {
            const startIndex = codes[i].indexOf("[");
            const endIndex = codes[i].indexOf("]");
            let code;

            if (startIndex > -1 && endIndex > -1) {
                code = codes[i].slice(startIndex + 1, endIndex);

                html += " <div class='code'><i>" + this.createICD10CodeDetailsLink(code, includeResults) + "</i></div> ";
                html += " <div class='code'>" + this.buildICD10Icons(code, codeIconFlags) + "</div> ";
            }
            else {
                code = codes[i];

                html += " <div class='code'>" + this.createICD10CodeDetailsLink(code, includeResults) + "</div> ";
                html += " <div class='code'>" + this.buildICD10Icons(code, codeIconFlags) + "</div> ";
            }
        }

        return html;
    }

    //build the ICD-10 code details link
    createICD10CodeDetailsLink(code: string, includeResults: boolean): string {

        // strip trailing period from code link
        let codeStripped = code;
        if (code.charAt(code.length - 1) === ".")
            codeStripped = codeStripped.substring(0, codeStripped.length - 1);

        const year = this.year;

        let link = "<a href='/MVC/ICD10/Details/" + encodeURIComponent(codeStripped) + (year ? "/" + year : "") + "?searchTerm=" + this.keywords;
        if (includeResults) {
            link = link + "&includeResults=true";
        }

        link = link + "'>" + code + "</a>";

        return link;
    }

    //get list of icons to display for a ICD-10 code (Note: codeIconFlags[i]["IconNew"] and codeIconFlags[i]["IconRevised"] are not valid properties)
    buildICD10Icons(code: string, codeIconFlags: ICodeIconFlag[]): string {
        if (!codeIconFlags) {
            return "";
        }

        let html = "<ul class='code-icons'>";

        for (let i = 0; i < codeIconFlags.length; i++) {
            if (codeIconFlags[i]["Code"] === code) {
                if (codeIconFlags[i]["IconRequires7thDigit"]) {
                    if (code.length === 7) {
                        html += "<li class='seventh' title='7th Character Required'></li>";
                    }
                    else {
                        html += "<li class='xseventh' title='7th Character Placeholder'></li>";
                    }
                }
                else if (!codeIconFlags[i]["IconRequiresAdditionalDigit"]) {
                    html += "<li class='valid' title='Valid Code'></li>";

                    if (codeIconFlags[i]["IconPDGM"]) {
                        html += "<li class='pdgm-px'  title='PDGM Primary'></li>";
                    }

                    if (codeIconFlags[i]["IconQuestionableEncounter"]) {
                        html += "<li class='questionable-encounter' title='Questionable Encounter'></li>";
                    }
                }
                else {
                    if (code.length === 3) {
                        html += "<li class='fourth' title='4th Character Required'></li>";
                    }
                    if (code.length === 4) {
                        const periodIndex = code.lastIndexOf(".");
                        const length = code.length - 1;
                        if (periodIndex === length) {
                            html += "<li class='fourth' title='4th Character Required'></li>";
                        }
                    }
                    if (code.length === 5) {
                        html += "<li class='fifth' title='5th Character Required'></li>";
                    }
                    if (code.length === 6) {
                        html += "<li class='sixth' title='6th Character Required'></li>";
                    }
                }

                break;
            }
        }
        html += "</ul>";

        return html;
    }

    //highlight the searched keywords in a string
    highlightKeywords(text: string, isTermSearch: Boolean): string {
        let html = text;

        if (isTermSearch && html) {
            html = this.highlightText(html, this.keywords);
        }

        return html;
    }

    highlightText(text: string, termString: string): string {
        let html = text;
        const terms = termString.split(" ");

        for (let i = 0; i < terms.length; i++) {
            const word = terms[i];
            if (word !== "") {
                //make sure special characters that are used in regular expressions are escaped as literal characters in the word
                const regWord = word
                    .replace("\\", "\\\\")
                    .replace("$", "\\$")
                    .replace(".", "\\.")
                    .replace("|", "\\|")
                    .replace("?", "\\?")
                    .replace("*", "\\*")
                    .replace("+", "\\+")
                    .replace("(", "\\(")
                    .replace(")", "\\)")
                    .replace("[", "\\[")
                    .replace("]", "\\]")
                    .replace("{", "\\{")
                    .replace("}", "\\}");

                const regExpKeyword = new RegExp("(" + regWord + ")", "gi");
                html = html.replace(regExpKeyword, "<span class='highlight'>$1</span>");
            }
        }

        return html;
    }
}