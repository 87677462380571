/**
 * All Angular components in this module are included by default
 * because they are used throughout the site by being included in
 * the _Layout.cshtml master template file.
 *
 * ==================================
 * | IMPORTANT CONVENTION TO FOLLOW |
 * ==================================
 * Before adding a component into this module, it must be used in
 * the [_Layout.cshtml] master template or one of its partial views.
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

//import billpadCodesController   from "./billpad/billpad.directive";
import { BillpadApiService } from "./billpad/billpadApi.service";
//import bookmarksDirective       from "./bookmarks/bookmarks.directive";
import { BulletinsComponent } from "./bulletins/bulletins.component";
import { BulletinsReadComponent } from "./bulletins/bulletins-read.component";
import { BulletinsDailyComponent } from "./bulletins/bulletins-daily.component";
import { MessagesComponent } from "./messages/messages.component";
import { ReadMessagesComponent } from "./messages/read-messages.component";
import { PagerComponent } from "./pager/pager.component";
import { TrainingComponent } from "./training/training.component";
import ngEnter from "./utilities/ng-enter.directive";
import rightClickRedirect from "./utilities/rightClickRedirect.directive";
//import tooltip                  from "./utilities/tooltip.directive";
import { AdminUserLinkComponent } from "./admin-user-link/admin-user-link.component";
import { AdminUserLinkService } from "./admin-user-link/adminUserLink.service";
import { ContentService } from "../content/content.service";
//import { InteractiveScenariosSearchComponent } from "../content/inter-scenarios-search.component";
import { ToolboxComponent } from "./toolbox/toolbox.component";
import { BillpadModalComponent } from "./billpad/billpad-modal.component";
import { BookmarksModalComponent } from "./bookmarks/bookmarks-modal.component";
import { TrainingWidgetComponent } from "./training/training-widget.component";
import { BookmarksToggleComponent } from "./bookmarks/bookmarks-toggle.component";
import { BillpadToggleComponent } from "./billpad/billpad-toggle.component";
import { TermExpanded } from "./utilities/ng-init.directive";
import { SafeHtmlPipe } from "./utilities/safe-html.pipe";
import { MaterialModule } from '../material.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        CKEditorModule,
        MaterialModule,
    ],
    providers: [
        BillpadApiService,
        AdminUserLinkService,
        ContentService,
    ],
    exports: [
        PagerComponent,
        BookmarksToggleComponent,
        TrainingWidgetComponent,
        BookmarksModalComponent,
        BillpadToggleComponent ,
        BillpadModalComponent
    ],
    declarations: [
        BulletinsComponent,
        BulletinsReadComponent,
        BulletinsDailyComponent,
        MessagesComponent,
        ReadMessagesComponent,
        PagerComponent,
        TrainingComponent,
        AdminUserLinkComponent,
      //  InteractiveScenariosSearchComponent,
        ToolboxComponent,
        BillpadModalComponent,
        TrainingWidgetComponent,
        BookmarksToggleComponent,
        BillpadToggleComponent ,
        BookmarksModalComponent,
        SafeHtmlPipe

    ]
})
export class CoreModule { }




//export default angular.module("codingCenterApp.core", [])
//    .directive("pager", downgradeComponent({ component: PagerComponent }))
//    .directive("bulletins", downgradeComponent({ component: BulletinsComponent }))
//    .directive("bulletinsRead", downgradeComponent({ component: BulletinsReadComponent }))
//    .directive("bulletinsDaily", downgradeComponent({ component: BulletinsDailyComponent }))
//    .directive("messages", downgradeComponent({ component: MessagesComponent }))
//    .directive("readMessages", downgradeComponent({ component: ReadMessagesComponent }))
//    .directive("composeMessages", downgradeComponent({ component: ComposeMessagesComponent }))
//    .directive("training", downgradeComponent({component: TrainingComponent}))
//    .directive("adminUserLink", downgradeComponent({ component: AdminUserLinkComponent }))
//   // .directive("billpadcodescontroller", billpadCodesController)
//   // .directive("bookmarkscontroller", bookmarksDirective)
//    .directive("ngEnter", ngEnter)
//  //  .directive("tooltip", tooltip)
//    .directive("rightClickRedirect", rightClickRedirect)
//    .factory("BillpadApiService", downgradeInjectable(BillpadApiService))
//    .factory("AdminUserLinkService", downgradeInjectable(AdminUserLinkService))
//    .factory("ContentService", downgradeInjectable(ContentService))
//    .directive("interactiveScenariosSearch", downgradeComponent({ component: InteractiveScenariosSearchComponent }))
//    .directive("toolbox", downgradeComponent({ component: ToolboxComponent }))
//    .directive("billpadModal", downgradeComponent({ component: BillpadModalComponent }))
//    .directive("bookmarksModal", downgradeComponent({ component: BookmarksModalComponent }))
//    .directive("trainingWidget", downgradeComponent({ component: TrainingWidgetComponent }))
//    .directive("bookmarksToggle", downgradeComponent({ component: BookmarksToggleComponent }))
//    .directive("safeHtml", downgradeComponent({component: SafeHtmlPipe}))
//  //  .directive("termExpanded", downgradeComponent({ component: TermExpanded }))
//    .name;
