import { Component, Inject, Input, OnInit } from '@angular/core';
import { Icd10BrowseWebService } from "./icd10cmBrowseWebService.service";

import icd10BrowseTreeTemplate from "./icd10-browse-tree.html";

@Component({
    selector: 'icd10-browse-tree',
    template: icd10BrowseTreeTemplate
})
export class Icd10BrowseTreeComponent  {

    @Input() code: any;
    @Input() section: any;
    @Input() codeYear: string;

    constructor(@Inject(Icd10BrowseWebService) private browseService: Icd10BrowseWebService) {

    }

    loadCode(sectionId, code) {
        if (code.ChildCodes) {
            code.expanded = !code.expanded;
        } else {
            code.loading = true;

            this.browseService.loadCode(sectionId, code.Code).then(data => {
                code.ChildCodes = data;
                code.index = code.Code.length;
                code.expanded = !code.expanded;
                code.loading = false;
            });
        }
    }
}