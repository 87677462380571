import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { PDGMCalculatorComponent } from "./pdgm-calculator.component";


import { SortByPipe } from "../shared/pipes/sortBy.pipe"

//import validatePoints from "./points-validation.directive";

@NgModule({
    imports: [
        CommonModule,
        FormsModule
    ],
    providers: [
        
    ],
    exports: [
        PDGMCalculatorComponent
    ],
    declarations: [
        PDGMCalculatorComponent,
        SortByPipe

    ]
})
export class PdgmCalculatorModule { }

//export default angular.module("codingCenterApp.pdgmCalculator", [])
//   // .directive("validatePoints", validatePoints)
//    .directive("pdgmCalculator", downgradeComponent({ component: PDGMCalculatorComponent }))
//    .name;
