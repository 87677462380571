import { Injectable, Inject } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { IAjaxResultBulletins, IBulletin } from './bulletins.models';

@Injectable({
    providedIn: 'root'
})
export class BulletinsService {
    constructor(@Inject(HttpClient) private http: HttpClient) {
    }

    getBulletins(activeOnly: Boolean, pageSize: number, pageIndex: number) {
        
        const parameters = {
            params: {
                activeOnly: activeOnly ? String(activeOnly) : "false",
                pageSize: pageSize.toString(),
                pageNum: pageIndex.toString()
            }
        };

        const urlGet = pageSize === undefined || pageSize === null ? "/MVC/Bulletins/GetAllBulletins" : "/MVC/Bulletins/GetBulletins";
        return this.http.get<IAjaxResultBulletins>(urlGet, parameters).toPromise();
    }

    getBulletin(bulletinId: number) {
        const urlGetBulletin = "/MVC/Bulletins/GetBulletin";
        console.log('bid', bulletinId);
        const parameters = { params: { bulletinId: bulletinId.toString() } };

        return this.http.get<IBulletin>(urlGetBulletin, parameters).toPromise();
    }

    saveBulletin(bulletinId: number, bulletinSubject: string, bulletinBody: string, isActive: boolean) {

        let thisBulletin: IBulletin = {
            UserContentDetailId: (bulletinId !== null ? bulletinId : 0),
            Subject: bulletinSubject,
            Body: bulletinBody,
            IsActive: isActive,
            IsRead: false,
            PubDate: "",
            SiteLicenseNum: "",
            UserContentTypeId: 0,
            UserId: 0
        };

        const parameters = {
            bulletin: thisBulletin 
        };

        return this.http.post<any>("/MVC/Bulletins/SaveBulletin", parameters).toPromise();
    }
}