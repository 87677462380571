
import { Directive, ElementRef, Inject, Input, OnInit } from '@angular/core';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of, OperatorFunction } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, tap, switchMap } from 'rxjs/operators';
import { SearchBoxComponent } from './search-box.component';
import { SearchBoxService } from './searchBox.service';


@Directive({
    selector: '[hhcc-search-autocomplete]'
})
export class HhccSearchAutocomplete {
    constructor(@Inject(SearchBoxComponent) private host: SearchBoxComponent,
        @Inject(SearchBoxService) private searchBoxService: SearchBoxService) { }

    @Input() ngModel: string;

    minLength: number;
    searching = false;
    searchFailed = false;
    remoteUrl: string;
    searchTypeId: number;
    year: number;

ngOnInit() {
    this.minLength = 3;
    this.remoteUrl = this.host.urlLookupObj[this.host.currentSearchOption.codeSet];
    this.searchTypeId = this.host.currentSearchOption.searchTypeId;
    this.year = this.host.getYearBySearchType(this.host.currentSearchOption.searchTypeId, this.host.currentSearchOption.codeYear, this.host.oasisCurrentCodeYear, this.host.oasisPrevCodeYear, this.host.oasisNextCodeYear);

    }

    doAuto: OperatorFunction<string, string[]> = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(300),
            distinctUntilChanged(),
            tap(() => this.searching = true),
            switchMap(term => {
                if (term.length > 2) {
                    return this.searchBoxService.getAutocompleteAsync(this.remoteUrl, term, this.searchTypeId, this.year).pipe(
                        tap(() => this.searchFailed = false),
                        catchError(() => {
                            this.searchFailed = true;
                            return of([]);
                        }))
                }
            }),
            tap(() => this.searching = false)
        )
}

   

