import { Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { BulletinsService } from './bulletins.service';
import { IBulletin } from './bulletins.models';

import bulletinsTemplate from './bulletins.html';



@Component({
    selector: 'bulletins',
    template: bulletinsTemplate
})
export class BulletinsComponent {

    @Input() isadmin: Boolean;
    @Input() tabindexvalue: string;

    public bulletinEditor = ClassicEditor;

    bulletins: IBulletin[];
    bulletinsOriginal: IBulletin[];
    bulletinId: number;

    bulletinSubject: string;
    bulletinBody: string = "";
    bulletinIsActive: boolean;
    bulletinPubDate: string;

    currentPageMinCount: number;
    currentPageMaxCount: number;
    paging: number[];

    defaultPageSize: number = 10;
    pageSize: number = this.defaultPageSize;
    pageIndex: number = 1;
    sortBy: string;
    pageMax: number;
    pageMin: number;
    startCount: number = 1;
    endCount: number;
    totalRecords: number;
    activeOnly: boolean;
    createEdit: string;

    constructor(@Inject(BulletinsService) private bulletinsService: BulletinsService) {
    }

    ngOnInit() {
       
        this.loadBulletins(this.pageSize, this.startCount);
    }

        // Load the this.bulletins
        loadBulletins(pgSize, pgIndex, activeOnly?: boolean) {

            // Update scope this.activeOnly value with param
            if (activeOnly !== undefined && activeOnly !== null) {
                this.activeOnly = activeOnly;
            }

            //if page size is null, return all results
            if (pgSize === undefined || pgSize === null) {
                this.pageSize = this.totalRecords;
            }
            else {
                this.pageSize = pgSize;
            }

            //set the page index
            if (pgIndex < this.pageMin || this.pageSize === this.totalRecords) {
                this.pageIndex = this.pageMin;
            }
            else if (this.pageMax) {
                this.pageIndex = (pgIndex > this.pageMax) ? this.pageMax : pgIndex;
            }
            else {
                this.pageIndex = pgIndex;
            }

            this.bulletinsService.getBulletins(this.activeOnly, this.pageSize, this.pageIndex).then(response => {
                const data = response;
                this.bulletins = data.BulletinList;
                this.bulletinsOriginal = data.BulletinList;
                this.totalRecords = data.TotalCount;

                //get the current record number view
                if (this.pageSize === undefined || this.pageSize === null) {
                    this.currentPageMinCount = this.pageMin;
                    this.currentPageMaxCount = this.pageMin;
                }
                else {
                    this.currentPageMinCount = this.pageIndex * this.pageSize - (this.pageSize - 1);
                    this.currentPageMaxCount = this.pageIndex * this.pageSize;
                    if (this.currentPageMaxCount > this.totalRecords) {
                        this.currentPageMaxCount = this.totalRecords;
                    }
                }

                // If this is the daily bulletin module, we should hide it if there are no applicable bulletins
                if (this.bulletins.length < 1) {
                    const jqBulletin = $(".bulletin");
                    if (jqBulletin.length > 0) {
                        jqBulletin.hide();
                    }
                }

                //setup paging links
                const mod = this.pageIndex % 4;
                switch (mod) {
                    case 1:
                        this.paging = [this.pageIndex, (this.pageIndex + 1), (this.pageIndex + 2), (this.pageIndex + 3)];
                        break;
                    case 2:
                        this.paging = [(this.pageIndex - 1), this.pageIndex, (this.pageIndex + 1), (this.pageIndex + 2)];
                        break;
                    case 3:
                        this.paging = [(this.pageIndex - 2), (this.pageIndex - 1), this.pageIndex, (this.pageIndex + 1)];
                        break;
                    case 0:
                        this.paging = [(this.pageIndex - 3), (this.pageIndex - 2), (this.pageIndex - 1), (this.pageIndex)];
                        break;
                }
                if (this.pageSize === undefined || this.pageSize === null) {
                    this.pageMax = 1;
                }
                else {
                    this.pageMax = Math.floor(this.totalRecords / this.pageSize);
                    const mod2 = this.totalRecords % this.pageSize;
                    if (mod2 > 0) {
                        this.pageMax++;
                    }
                }
                for (let i = 3; i > 0; i--) {
                    if (this.paging[i] > this.pageMax) {
                        this.paging = this.paging.slice(0, i);
                    }
                }
            });  
        }

        getBulletin(bulletinId) {
            this.bulletinId = bulletinId;
            this.createEdit = "Edit";
            this.bulletinsService.getBulletin(bulletinId).then(response => {
                const data = response;
                this.bulletinSubject = data.Subject;
                this.bulletinBody = data.Body;
                this.bulletinIsActive = data.IsActive;
                this.bulletinPubDate = this.dateFormatter(data.PubDate);
            });
        }

        // Saves the bulletin
        saveBulletin() {
            console.log('sub', this.bulletinSubject);
            console.log('body', this.bulletinBody);
            console.log('id', this.bulletinId);
            this.bulletinsService.saveBulletin(this.bulletinId, this.bulletinSubject, this.bulletinBody, this.bulletinIsActive).then(response => {
                this.loadBulletins(this.pageSize, this.pageIndex);
                (<any>$("#compose")).modal("hide");

            });
        }

        // Load the Bulletin editor with info from the selected Bulletin
        editBulletin(bulletinId) {
            this.bulletinId = bulletinId;
            this.createEdit = "Edit";

            this.bulletinsService.getBulletin(bulletinId).then(response => {
                const data = response;
                this.bulletinSubject = data.Subject;
                this.bulletinBody = data.Body;
                this.bulletinIsActive = data.IsActive;
            });
        }

        //Opens and loads compose window
        compose()  {
            this.createEdit = "Create";
            this.bulletinId = -1;
            this.bulletinSubject = "";
            this.bulletinBody = "";
            this.bulletinIsActive = true;
        }

        // Formats JSON dates correctly
        dateFormatter(dateVal) {
            const month: string[] = [];
            month[0] = "January";
            month[1] = "February";
            month[2] = "March";
            month[3] = "April";
            month[4] = "May";
            month[5] = "June";
            month[6] = "July";
            month[7] = "August";
            month[8] = "September";
            month[9] = "October";
            month[10] = "November";
            month[11] = "December";

            const d = new Date(parseInt(dateVal.replace(/\/Date\((.*?)\)\//gi, "$1"), 10));
            const returnDate = month[d.getMonth()] + " " + d.getDate() + ", " + d.getFullYear();
            return returnDate;
        }

        // Takes first 100 chars of html
        bodyBlurb(description) {
            const blurb = description !== null ? description.substring(0, 80) + "..." : "";
            return blurb;
        }


      
    }

