import { Component, Inject, Input, OnInit } from '@angular/core';
import { IArticle, INewsletterArticle } from './article.models';
import { ArticleService } from './article.service';
import { NewsletterService } from './newsletter.service';
import newsletterArticleTemplate from './newsletter-article.html';
import { IAjaxResultArticles } from './article.models';

@Component({
    selector: 'newsletter-article',
    template: newsletterArticleTemplate
})
export class NewsletterArticleComponent {

    @Input() newsletterid: number;
    @Input() tabindexvalue: number;

    articles: IArticle[]=[];
    totalCount: number=0;
    articlesLoaded: boolean=false;
    articleLibraryFileId: number;
    newsletterName: string;

    constructor(@Inject(NewsletterService) private newsletterService: NewsletterService,
        @Inject(ArticleService) private articleService: ArticleService) {

    }

    // Initialize all newsletter articles
    ngOnInit() {
        this.getNewsletterDetails();        
        this.getArticles(1);
    }


    getArticles(attemptNum: number) {
        this.articlesLoaded = false;
        this.articleService.getArticles(this.newsletterid, 50).then(response => {
            const data = response;
            if (!data.ArticleList || data.ArticleList.length === 0) {
                if (attemptNum < 4) {
                    this.getArticles(++attemptNum);
                } else {
                    this.articlesLoaded = true;
                }
            } else {
                this.articles = data.ArticleList;
                this.totalCount = data.TotalCount;
                this.articlesLoaded = true;
            }
        });
    }

    getNewsletterDetails() {
        this.newsletterService.getNewsletterDetails(this.newsletterid).then(response => {
            const data = response;
            this.articleLibraryFileId = data.ArticleLibraryFileId;
            this.newsletterName = data.NewsletterName;
        });
    }

    // Formats JSON dates correctly
    dateFormatter(dateVal: string): string {
        const month = new Array();
        month[0] = "January";
        month[1] = "February";
        month[2] = "March";
        month[3] = "April";
        month[4] = "May";
        month[5] = "June";
        month[6] = "July";
        month[7] = "August";
        month[8] = "September";
        month[9] = "October";
        month[10] = "November";
        month[11] = "December";

        const d = new Date(parseInt(dateVal.replace(/\/Date\((.*?)\)\//gi, "$1"), 10));
        const returnDate = (d.getMonth() + 1) + "/" + d.getDate() + "/" + d.getFullYear();

        return returnDate;
    }
}