import { Component, Inject, Input, OnInit } from '@angular/core';

import { ICD10SearchService } from './icd10-search.service';
import { IOasisCrosslink } from './icd10.models';
import mappingICD10Template from './mapping-icd10-code.html';



@Component({
    selector: 'mapping-icd10-code',
    template: mappingICD10Template
})

export class MappingIcd10CodeComponent {
    @Input() code: string;
    @Input() maximize: boolean;

    // props
    OASISXLinks: IOasisCrosslink[] = [];
    OASISXLinksDisplay: IOasisCrosslink[] = [];
    WidgetLength: number = 10;

    // Data display properties
    ICD10Code: string = "";
    RelatedCodesData: string = "";

    MappingPageSize: number = 15;
    MappingPageIndex: number = 1;
    MappingPageMin: number = 1;
    TotalItems: number = 0;

    tryCounter: number = 0;
    GemsLoaded: boolean = false;

    constructor(@Inject(ICD10SearchService)private icd10SearchService: ICD10SearchService) {

    }

    ngOnInit() {
        this.loadOASISWidget(this.code, this.maximize);
    }

    // Load the OASIS Crosslinks
    loadOASISWidget(pCode: string, maximize: boolean) {
        this.icd10SearchService.getOasisMappings(pCode).then(response => {
                const data = response;
                if (data) {
                    this.OASISXLinks = (<IOasisCrosslink[]>data);
                    this.OASISXLinksDisplay = maximize ? data : data.slice(0, 6);
                } else {
                    // Sometimes we erroneously get no data, so attempt again
                    console.log("no OASIS Crosslink data, try again; GEM attempt number " + this.tryCounter);
                    if (this.tryCounter < 2) {
                        this.tryCounter++;
                        this.loadOASISWidget(pCode, maximize);
                    }
                }
            },
            response => {
                console.error(response);
            });
    }

  
}
