import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { LoginService } from './login.service';

import eulaTemplate from './eula-modal.html';

@Component({
    selector: 'eula-modal',
    template: eulaTemplate
})
export class EulaModalComponent {

    loginError: string;

    @Input() username: string;
    @Input() rememberme: boolean;
    @Output() public eulaAccepted = new EventEmitter<boolean>();

    constructor(@Inject(LoginService) private loginService: LoginService, @Inject(DOCUMENT) public document: Document) {

    }

    acceptEula() {
        this.loginService.acceptedEula(this.username, this.rememberme).then(data => {
            if (data == "") {
                this.eulaAccepted.emit(true); 
            }
            else {
                this.loginError = "The system is unable to process your login request. Please contact Customer Service for help.";
                this.eulaAccepted.emit(false);
            }
        }).catch(() => {
            this.loginError = "The system is unable to process your login request. Please contact Customer Service for help.";
            this.eulaAccepted.emit(false);
        });
    }

    declineEula(): void {
        this.eulaAccepted.emit(false);
        
    }
}
