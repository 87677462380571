import { Component, Inject, Input, OnInit } from '@angular/core';
import { IArticle, INewsletterArticle  } from './article.models';
import { ArticleService } from './article.service';
import { NewsletterService } from './newsletter.service';
import latestIssueWidgetTemplate from './latest-issue-widget.html';
import { IAjaxResultArticles } from './article.models';

@Component({
    selector: 'latest-issue-widget',
    template: latestIssueWidgetTemplate
})
export class LatestIssueWidgetComponent {

    // props
    @Input() tabindexvalue: number;

    articleType: string = "1002";
    articlesLoaded: Boolean;
    articleCount: number = 3;

    articles: IArticle[];
    totalCount: number;
    newsletterId: number;
    libraryFileId: number;
    newsletterName: string;

    constructor(@Inject(ArticleService) private articleService: ArticleService, 
        @Inject(NewsletterService) private newsletterService: NewsletterService) {
    }

    ngOnInit() {
        this.articlesLoaded = false;

        this.articleService.getLatestIssue(this.articleType, this.articleCount).then(response => {
            const data = response;
            this.articles = data.ArticleList;
            this.totalCount = data.TotalCount;
            this.newsletterId = data.ArticleList[0].ArticleNewsletterId;
            this.getNewsletterDetails(this.newsletterId).then(() => {
                this.articlesLoaded = true;
            });

            
        });

    }

    getNewsletterDetails(newsId: number) {
        return this.newsletterService.getNewsletterDetails(this.newsletterId).then(response => {
            this.libraryFileId = response.ArticleLibraryFileId;
            this.newsletterName = response.NewsletterName;
        });
            
    }

    // Takes first 80 chars of html
    headlineBlurb(description: string) : string {
        return description.substring(0, 80) + "...";
    };

    // Takes first 80 chars of html
    abstractBlurb(description: string): string {
        return description.substring(0, 125) + "...";
    };

    // Formats JSON dates correctly
    dateFormatter(dateVal: string): string {
        const month: string[] = [];
        month[0] = "January";
        month[1] = "February";
        month[2] = "March";
        month[3] = "April";
        month[4] = "May";
        month[5] = "June";
        month[6] = "July";
        month[7] = "August";
        month[8] = "September";
        month[9] = "October";
        month[10] = "November";
        month[11] = "December";

        const d = new Date(parseInt(dateVal.replace(/\/Date\((.*?)\)\//gi, "$1"), 10));

        const returnDate = month[d.getMonth()] + " " + d.getDate() + ", " + d.getFullYear();
        return returnDate;
    };
};
