import { Injectable, Inject } from '@angular/core';
import { HttpClient } from "@angular/common/http";

export interface IAdminUserLinkService {
    canManageUsers(customerId: number): Promise<number>;
}

@Injectable({
    providedIn: 'root'
})
export class AdminUserLinkService implements IAdminUserLinkService {
    constructor(@Inject(HttpClient) private http: HttpClient) {
    }

    canManageUsers(customerId: number): Promise<number> {

        return this.http.get<number>("/MVC/Account/CanManageUsers", {
            params: {
                customerId: customerId.toString()
            }
        }).toPromise();
    }
}