import { Component, Inject, Input, OnInit } from '@angular/core';
import { IAjaxRequestRecentCode, IRecentCode } from "./recent-codes.models";
import { RecentCodesApiService } from "./recentCodesApi.service";
import { BillpadApiService } from "../core/billpad/billpadApi.service";

import recentCodesTemplate from './recent-codes.html';
import { IBillpadCode } from '../core/billpad/billpadApi.models';

function formatAMPM(date: Date): string {
    let hours: number = date.getHours();
    let minutes: number = date.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const strMinutes = minutes < 10 ? '0' + minutes : minutes.toString();
    return hours + ':' + strMinutes + ' ' + ampm;
}

@Component({
    selector: 'recent-codes',
    template: recentCodesTemplate
})
export class RecentCodesComponent {

    constructor(@Inject(RecentCodesApiService) private recentCodesService: RecentCodesApiService) {

    }

    @Input() isexpert: boolean;

    // lock variable to avoid duplicate calls
    locked: boolean = false;

    // Item List Arrays
    recentCodes: IRecentCode[] = [];

    // Sort type for the recent code results
    sort: number = 1;

    // Define constants
    defaultPageSize: number = 25;
    maxDescChars: number = 30;
    maxRecords: number = 50;

    // Paging variables
    pageSize: number = this.defaultPageSize;
    pageIndex: number = 1;
    pageMin: number = 1;
    totalCodes: number = -1;
    isShowAll: boolean = false;
    recentLoading: boolean = true;
    numPages: number;
    pages: number[];


    // Initialize page data
    ngOnInit() {

        this.sort = 1;
        this.maxDescChars = this.maxDescChars;
        this.pageSize = this.defaultPageSize = this.defaultPageSize;

        // Page size set by user control parameter
        // this.PageSize = maxCodeCount;

        // Save max chars for description field to pass in web svc call
        // this.MaxDescChars = pMaxDescChars;

        // get initial list of codes        
        this.getRecentCodes();
        
    }

    // Pull back all recent codes
    getRecentCodes() {

        // Skip this if we're already pulling recent codes (duplicate call)
        if (this.locked) {
            return;
        }
        else {
            this.locked = true;
        }

        // Show the loading wheel and clear existing records in case pagination takes extra time
        this.recentCodes = [];
        this.recentLoading = true;

        const params: IAjaxRequestRecentCode = {
            pageSize: this.pageSize,
            pageNum: this.pageIndex,
            sortOrder: this.sort,
            maxDescChars: this.maxDescChars
        };

        // Get recent code content from web service and display, replacing loading image
        this.recentCodesService.getRecentCodesAsync(params).then(
            (data: IRecentCode[]) => {
                this.recentCodes = data;

                // Get total row count for paging
                if (data && data.length > 0) {
                    this.totalCodes = Math.min(data[0].TotalRowCount, this.maxRecords);
                }

                // Only do this if not in Show All mode
                if (this.isShowAll === null || this.isShowAll === false) {
                    // Set up paging variables
                    this.pages = [];
                    this.numPages = Math.ceil(this.totalCodes / this.defaultPageSize);
                    for (let i = 1; i <= this.numPages; i++) {
                        this.pages.push(i);
                    }
                }

                // Loading is done
                this.recentLoading = false;
                this.locked = false;
            },
            (response: angular.IHttpResponse<any>) => {
                debugger;
                console.error(response);
            });
    }

    // Advances to the next page of results
    nextPage() {
        if (this.pageIndex < this.numPages) {
            this.pageIndex++;
            this.getRecentCodes();
        }
    }

    // Moves to the previous page of results
    previousPage() {
        if (this.pageIndex > 1) {
            this.pageIndex--;
            this.getRecentCodes();
        }
    }

    // Moves to a specific page of results
    goToPage(page) {
        this.pageIndex = page;
        this.getRecentCodes();
    }

    // Either shows all results or goes back to paginated view
    showAll() {

        if (this.pageSize === this.totalCodes) {
            this.isShowAll = false;

            // We're currently viewing all, go back to paginated view
            this.pageSize = this.defaultPageSize;
        }
        else {
            // Show all results on one page
            this.isShowAll = true;
            this.pageSize = this.totalCodes;
            this.numPages = 1;
            this.pages = [1];
        }
        this.pageIndex = 1;

        this.getRecentCodes();
    }
    sortChange(sortBy?: number) {
        if (typeof (sortBy) === "number" && this.sort !== sortBy) {
            this.sort = sortBy;
            this.getRecentCodes();
        }
    }

    // Formats JSON dates correctly
    dateFormatter(dateVal: string): string {
        const d = new Date(parseInt(dateVal.replace(/\/Date\((.*?)\)\//gi, "$1"), 10));

        const realMonth = d.getMonth() + 1; // JS month is 0-based
        const returnDate = realMonth + "/" + d.getDate() + "/" + d.getFullYear() + "</br>" + formatAMPM(d);

        return returnDate;
    }
}
