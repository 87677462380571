import { Component, Inject, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import trialBannerTemplate from './trial-banner.html';

@Component({
    selector: 'trial-banner',
    template: trialBannerTemplate
})
export class TrialBannerComponent {
    showTrialBanner:boolean = true;
    trialExpirationDate: string = "";
    trialDaysRemaining: number = 0; 

    constructor(@Inject(CookieService) private cookies: CookieService) {
    }
    
    ngOnInit() {
        var trialBannerCookie = this.cookies.get("TrialBanner");
        if (trialBannerCookie == "true") {
            this.showTrialBanner = true;
        } else {
            this.showTrialBanner = false;
        }

        var trialExpirationDate = new Date(this.cookies.get("TrialExpirationDate"));
        var today = new Date();
        var msPerDay = 24 * 60 * 60 * 1000;
        this.trialDaysRemaining = Math.round((trialExpirationDate.getTime() - today.getTime()) / msPerDay);
    }

    bannerClose() {
        const cookieOptions = {
            path: "/",
            secure: false
        };
        this.showTrialBanner = false;
        this.cookies.set("TrialBanner", "false", cookieOptions);
    }
}
