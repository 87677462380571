import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { ICD10SearchService } from './icd10-search.service';
import { ICodeIconFlag } from './icd10.models';
import icd10IndexTemplate from './icd10-alpha-index.html';

@Component({
    selector: 'icd10-alpha-index',
    template: icd10IndexTemplate,
})

export class Icd10AlphaIndexComponent implements OnInit {

    @Input() indexchilddata: any;
    @Input() parentobj: any;
    @Input() childdepth: number;
    @Input() parentindex: string;
    @Input() firstchildindex: string;
    @Input() lvlid: string;
    @Input() istermsearch: Boolean;

    constructor(@Inject(ICD10SearchService) private icd10SearchService: ICD10SearchService) {

    }

    ngOnInit(): void {
        this.childdepth++;
    }


    //get child term data for a ICD-10 term
    getChildData(indexTermId: number, indexVersionId: number) {
        if (!Object.prototype.hasOwnProperty.call(this.indexchilddata, indexTermId.toString())) {
            this.icd10SearchService.getIndexChildData(indexTermId, indexVersionId).then(response => {
                this.indexchilddata[indexTermId.toString()] = response;
            })
        }
    }

    createIndexSearchLinks(text: string, refCodeSearch: string): string {
        return this.icd10SearchService.createIndexSearchLinks(text, refCodeSearch);
    }

    createCodeSearchLinks(codeString: string, codeIconFlags: ICodeIconFlag[], includeResults: boolean): string {
        //codeString can be formatted as "code1" or "code1 [code2]", so BuildICD10CodeSearchLinkForIndexSearchResult parse the codes to display the individual link and icons
        const html = this.buildICD10CodeSearchLinkForIndexSearchResult(codeString, codeIconFlags, includeResults);
        return html; //without $sce.trustAsHtml(), html will be rendered in plain text
    };

    buildICD10CodeSearchLinkForIndexSearchResult(codeString: string, codeIconFlags: ICodeIconFlag[], includeResults: boolean): string {
        return this.icd10SearchService.buildICD10CodeSearchLinkForIndexSearchResult(codeString, codeIconFlags, includeResults);
    }

    buildICD10Icons(code: string, codeIconFlags: ICodeIconFlag[]): string {
        return this.icd10SearchService.buildICD10Icons(code, codeIconFlags);
    }

    highlightKeywords(text: string) : string{
        return this.icd10SearchService.highlightKeywords(text, this.istermsearch);
    }



}