import { Component, Inject, Input, OnInit } from '@angular/core';
import * as models from "./article.models";

import { ArticleService } from "./article.service";
import { BookmarksService } from '../core/bookmarks/bookmarks.service';

import breakingNewsTemplate from './breaking-news.html';

@Component({
    selector: 'breaking-news',
    template: breakingNewsTemplate
})
export class BreakingNewsComponent {

    constructor(@Inject(ArticleService) private articleService: ArticleService) {
    }

    @Input() tabindexvalue: number;

    // props
    defaultPageSize: number = 10;
    articleType: string = "1003";
    articlesLoaded: boolean;
    articles: models.IArticle[];
    totalcount: number;
    ArticleNewIconStartDate: Date;
    Display: any[];
    PageIndex: number;
   

    // Displays a count of a specific type of articles
    ngOnInit() {
        
       // Indicates if we should show results or loading wheel
        this.articlesLoaded = false;
        this.articles = [];
        this.totalcount = 0;
        this.ArticleNewIconStartDate = new Date();
        this.ArticleNewIconStartDate.setDate(this.ArticleNewIconStartDate.getDate() - 14);

        this.articleService.getAllArticlesByType(this.articleType, "-1").then(response => {
           
                const data = response;
                this.articles = data.ArticleList;
                this.totalcount = data.TotalCount;
                this.articlesLoaded = true;
            
        });
    }

    // Displays an HTML blurb of 300 characters
    bodyBlurb(description: string): string {
        return description.substring(0, 300);
    }

    // Formats JSON dates correctly
    dateFormatter(dateVal: string, format): string {
        const month: string[] = [];
        month[0] = "January";
        month[1] = "February";
        month[2] = "March";
        month[3] = "April";
        month[4] = "May";
        month[5] = "June";
        month[6] = "July";
        month[7] = "August";
        month[8] = "September";
        month[9] = "October";
        month[10] = "November";
        month[11] = "December";

        const d = new Date(parseInt(dateVal.replace(/\/Date\((.*?)\)\//gi, "$1"), 10));

        if (format === "full") {
            return month[d.getMonth()] + " " + d.getDate() + ", " + d.getFullYear();
        } else {
            return (d.getMonth() + 1) + "/" + d.getDate() + "/" + d.getFullYear();
        }
    }
}