import { Injectable, Inject } from '@angular/core';
import { HttpClient } from "@angular/common/http";

import { IAjaxResultMessages, IMessage } from './messages.models';

@Injectable({
    providedIn: 'root'
})
export class MessagesService {
    constructor(@Inject(HttpClient) private http: HttpClient) {
    }

    getMessage(messageId: number): Promise<IMessage> {
        const urlGetMessage = "/MVC/Messages/GetMessage";
        const parameters = { params: { messageId: messageId.toString() } };

        return this.http.get<any>(urlGetMessage, parameters).toPromise();
    }

    getMessages(activeOnly: Boolean, pageSize: number, pageIndex: number): Promise<IAjaxResultMessages> {

        const urlGetMessageCount = "/MVC/Messages/GetMessageCount";
        const urlGetMessages = "/MVC/Messages/GetMessages";
        const parameters = {
            params: { activeOnly: String(activeOnly), pageSize: pageSize.toString(), pageNum: pageIndex.toString() }
        };

        //this.http.get<number>(urlGetMessageCount, parameters).toPromise().then(
        //    (response) => {
        //        $scope.TotalRecords = response.data;
        //    },
        //    innerErrorHandler);

        return this.http.get<IAjaxResultMessages>(urlGetMessages, parameters).toPromise();
    }

    saveMessage(subject: string, body: string, category: string) {
        const urlSaveMessage = "/MVC/Messages/SaveMessage";
        const codeObj = {
            'subject': subject,
            'body': body,
            'category': category,
            'userIdFrom': ""
        };

        return this.http.post(urlSaveMessage, codeObj).toPromise();
    }
}