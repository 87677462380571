import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ProfileComponent } from "./profile.component";
import { ProfileService } from "./profile.service";

@NgModule({
    imports: [
        CommonModule,
        FormsModule
    ],
    providers: [
        ProfileService
    ],
    declarations: [
        ProfileComponent

    ]
})
export class ProfileModule { }

//export default angular.module("codingCenterApp.profile", [])
//    .directive("profile", downgradeComponent({ component: ProfileComponent }))
//    .factory("ProfileService", downgradeInjectable(ProfileService))
//    .name;
