export interface IMostViewedDetailPageModel {
    Total: number,
    PageUrl: string,
    PageName: string
}

export interface IMostSearchedTermsModel {
    Total: number,
    Term: string,
    SearchType: string
}

export interface IMostSearchedCodesModel {
    Total: number,
    Code: string
}

//Summary Users Inquiry Info Model
export interface IUsersSummaryInfo {
    User: string,
    AverageTime: string,
    PageViews: number,
    CustomerId: number,
    Days: number,
    TotalTimeSpent: string
}

export interface IUserIndividualInfo {
    PagesInfo: IMostViewedDetailPageModel[],
    FirstName: string,
    LastName: string
}


export enum ChartCategory  {
    Team = "Team",
    Individual = "Individual"
}

export enum TeamChartsCategoryType {
    Page = "Page",
    Term = "Term",
    Code = "Code"
}