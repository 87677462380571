import { Injectable, Inject } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class Icd9MappingService {

    constructor(@Inject(HttpClient) private http: HttpClient) {
    }

    pCode:string = '';
    ExactCrosswalks: any = [];
    ApproxCrosswalks: any = [];
    ComboCrosswalks: any = [];
    ComboCount: any = [];
    RelatedCodes: any = [];

    // Observable for when event list has been updated
    crosswalksLoaded = new Subject<string>();


getPCode() {
    return this.pCode;
}
        getExactCrosswalks() {
            return this.ExactCrosswalks;
        }
        getApproxCrosswalks() {
            return this.ApproxCrosswalks;
        }
        getComboCrosswalks() {
            return this.ComboCrosswalks;
        }
        getComboCount() {
            return this.ComboCount;
        }
        getRelatedCodes() {
            return this.RelatedCodes;
        }
        getMappings(code) {
            this.pCode = code;
            const parameters = {params: {code: code}};

            return this.http.get("/MVC/ICD9/GetI10Mappings", parameters).toPromise();
        }
        fullMappings(code) {
            this.pCode = code;
            const parameters = {params: {code: code}};

            this.http.get<any>('/MVC/ICD9/GetI10Mappings', parameters).toPromise().then(
                (response) => {
                    const data = response;
                    if (data !== "") {
                        // Save mapped code data for display    
                        this.ExactCrosswalks = data.ExactCrosswalks;
                        this.ApproxCrosswalks = data.ApproxCrosswalks;
                        this.ComboCrosswalks = data.ComboCrosswalks;
                        this.ComboCount = data.ComboCount;
                        this.RelatedCodes = data.RelatedCodes;

                        this.crosswalksLoaded.next("loaded");
                    }
                },
                function (response) {
                    console.error(response);
                });
        }
    }

