import { Component, Inject, Input, OnInit } from '@angular/core';
import { UsageReportingChartsService } from "../usage-reporting-charts.service";
import { IMostViewedDetailPageModel } from "../usage-reporting-charts.models"

import template from '../chartsTemplate.html';

@Component({
    selector: 'most-viewed-pages-chart',
    template: template
})
export class MostViewedPagesChartComponent {
    chart: any = {};
    chartData: IMostViewedDetailPageModel[];
    chartDataRows: any[];

    totalRecords: number;
    //Search
    searchQuery: string = "";

    isLoaded: boolean = false;

    constructor(@Inject(UsageReportingChartsService) private userReportingChartsService: UsageReportingChartsService) {
    }

    ngOnInit() {
       // this.chart.options = this.userReportingChartsService.getChartDefaultOptions();
        this.getMostViewedPages();
    };

    search() {
        this.chart.data = this.userReportingChartsService.search(this.chartDataRows, this.searchQuery);
        this.totalRecords = this.chart.data.length;
        this.isLoaded = true;
    }

    getMostViewedPages() {
        this.userReportingChartsService.getMostViewedDetailPages().then(response => {

            this.chartData = response ? response : [];

            
            if (this.chartData) {

                this.chart.type = "Table";
                this.chart.columnNames = ["Total", "Code"];

                this.chart.data = [];
                var self = this;
                this.chartData.forEach(function (item, index) {
                    var chartItem = [{ v: item.Total }, {v: item.PageName, f: "<a href='" + item.PageUrl + "'>" + item.PageName + "</a>" }];
                    self.chart.data.push(chartItem);
                });
                this.chart.options = this.userReportingChartsService.getChartDefaultOptions();
                this.chartDataRows = this.chart.data;
                this.totalRecords = this.chart.data.length;
                this.isLoaded = true;
            }
        }).catch(error => {
            console.error(error);
        });
    }
}

