import { Component, Inject, Input, OnInit } from '@angular/core';


import { BulletinsService } from './bulletins.service';
import { IBulletin } from './bulletins.models';

import bulletinsDailyTemplate from './bulletins-daily.html';



@Component({
    selector: 'bulletins-daily',
    template: bulletinsDailyTemplate
})
export class BulletinsDailyComponent {

    @Input() tabindexvalue: string;

    bulletins: IBulletin[];

    pageSize: number = 3;
    pageIndex: number = 1;
    activeOnly: boolean = true;

    constructor(@Inject(BulletinsService) private bulletinsService: BulletinsService) {
    }

    ngOnInit() {

        this.bulletinsService.getBulletins(this.activeOnly, this.pageSize, this.pageIndex).then(response => {
            const data = response;
            this.bulletins = data.BulletinList;
        });
    }

    // Formats JSON dates correctly
    dateFormatter(dateVal) {
        const month: string[] = [];
        month[0] = "January";
        month[1] = "February";
        month[2] = "March";
        month[3] = "April";
        month[4] = "May";
        month[5] = "June";
        month[6] = "July";
        month[7] = "August";
        month[8] = "September";
        month[9] = "October";
        month[10] = "November";
        month[11] = "December";

        const d = new Date(parseInt(dateVal.replace(/\/Date\((.*?)\)\//gi, "$1"), 10));
        const returnDate = month[d.getMonth()] + " " + d.getDate() + ", " + d.getFullYear();
        return returnDate;
    }

    // Takes first 100 chars of html
    bodyBlurb(description) {
        const blurb = description !== null ? description.substring(0, 80) + "..." : "";
        return blurb;
    }

}

