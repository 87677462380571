import { Injectable, Inject } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { IBookmarkItem, IBookmarks } from "./bookmarks.models";


@Injectable({
    providedIn: 'root'
})
export class BookmarksService {
    constructor(@Inject(HttpClient) private http: HttpClient) {
    }

    url: string = "/mvc/api/Bookmarks/";

    articlesBookmarks: IBookmarkItem[]=[];
    icd9CodeBookmarks: IBookmarkItem[]=[];
    icd10CodeBookmarks: IBookmarkItem[]=[];
    oasisCodeBookmarks: IBookmarkItem[]=[];
    userBookmarks: IBookmarks = {
        ArticlesBookmarks: this.articlesBookmarks,
        ICD9CodeBookmarks: this.icd9CodeBookmarks,
        ICD10CodeBookmarks: this.icd10CodeBookmarks,
        OASISBookmarks: this.oasisCodeBookmarks
    }

    getUserBookmarks() {
        return this.http.get<IBookmarks>(this.url + "/GetUserBookmarks").toPromise().then(response => {
            this.userBookmarks.ArticlesBookmarks = response.ArticlesBookmarks;
            this.userBookmarks.ICD10CodeBookmarks = response.ICD10CodeBookmarks;
            this.userBookmarks.OASISBookmarks = response.OASISBookmarks;

            return this.userBookmarks;
        });
    }

    toggleBookmark(contentType, contentId, isBookmarked) {
        const parameters = { params: { contentType: contentType, contentId: contentId, enabled: isBookmarked } };
        return this.http.get(this.url + "/ToggleMyFavorites", parameters).toPromise();
    }

    isArticleBookmarked(articleId) {
        if (this.userBookmarks.ArticlesBookmarks) {
            return this.checkBookmarks(articleId);
        }
        else {
            return this.getUserBookmarks().then(() => {
                return this.checkBookmarks(articleId);
            });
        }
    };

    checkBookmarks(articleId) {
        let returnValue = false;
        this.userBookmarks.ArticlesBookmarks.forEach((value) => {
            if (value.GenericContentId === articleId) {
                returnValue = true;
            }
        });
        return returnValue;
    }

    isItemBookmarked(contentType, contentId) {
        let returnValue = false;
        switch (contentType) {
           
            case "ICD10":
                this.icd10CodeBookmarks.forEach((value) => {
                    if (value.GenericContentId === contentId) {
                        returnValue = true;
                    }
                });
                break;
            case "OASIS":
                this.oasisCodeBookmarks.forEach((value) => {
                    if (value.GenericContentId === contentId) {
                        returnValue = true;
                    }
                });
                break;
        }
        return returnValue;
    };
}