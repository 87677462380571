import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';

import template from "./pdgm-calculator.html"

import { IClinicalGroupOption, IMStep } from "./pdgm-calculator.models";
import { PdgmCalculatorService } from './pdgm-calculator.service';

@Component({
    selector: 'pdgm-calculator',
    template: template
})
export class PDGMCalculatorComponent {

    calculatePoints: boolean = true;
    manualPoints: number;
    selectedClinicalGroup: IClinicalGroupOption = null;
    validPoints: boolean = true;

    isFirst: boolean = true;
    isLast: boolean = false;
    mSteps: IMStep[];
    clinicalGroups: IClinicalGroupOption[]

    constructor(@Inject(PdgmCalculatorService) public pdgmCalculatorService: PdgmCalculatorService) {
    }

    ngOnInit() {
        this.pdgmCalculatorService.GetClinicalGroupOptions().subscribe(res => {
            this.clinicalGroups = res;
        });
        this.pdgmCalculatorService.GetFeeCalcSteps().subscribe(res => {
            this.mSteps = res;
        });
    }

    getTotalPoints() {
        if (!this.calculatePoints)
            return this.manualPoints || 0;
        else {
            let result = 0;
            this.mSteps.filter(f => f.SelectedResponse).forEach(step => {
                result += step.SelectedResponse.Points;
            });
            return result;
        }
    }
    mItemsUnchecked() {
        return this.mSteps.some(s => s.SelectedResponse === null);
    }
    getImpairmentLevel() {
        if (!this.selectedClinicalGroup)
            return "";
        else {
            let pointTotal = this.getTotalPoints();
            if (pointTotal >= this.selectedClinicalGroup.HighCutoff) 
                return "High";           
            else if (pointTotal >= this.selectedClinicalGroup.MediumCutoff) 
                return "Medium";      
            else
                return "Low";            
        }
    }

    clearAll() {
        this.mSteps.filter(f => f.SelectedResponse).map(step => step.SelectedResponse = null);
        //this.calculatePoints = true;
        this.manualPoints = undefined;
        this.selectedClinicalGroup = null;
        $('.bs-vertical-wizard > ul > li').first().find('a').trigger('click');
    }

    validatePoints(value) {
        console.log('validate event', value);
        if (!value) {
            this.validPoints = true;
        }

        this.validPoints = !isNaN(value) && !(value.toString().length > 2) && value >= 0 && value <= 88;
    }

    previousTab() {
        $('.bs-vertical-wizard > ul > .active').prevAll('li:not(.ng-hide)').first().find('a').trigger('click');
    }

    nextTab() {      
        $('.bs-vertical-wizard > ul > .active').nextAll('li:not(.ng-hide)').first().find('a').trigger('click');
    }
    isNotFirst() {
       return  $('.bs-vertical-wizard > ul > .active').find('a').attr("data-target") !== '#stepPoints';
    }

    isNotLast() {
        return $('.bs-vertical-wizard > ul > .active').find('a').attr("data-target") !== '#stepLevel';
    }
}

