import { Component, ElementRef, Inject, Input, ViewChild } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { BillpadApiService } from './billpadApi.service';

import billpadModalTemplate from './billpad-modal.html';
import { IBillpadCode } from './billpadApi.models';

import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';

@Component({
    selector: 'billpad-modal',
    template: billpadModalTemplate
})
export class BillpadModalComponent {

    @Input() haspdgmexclusion: Boolean=false;

    @ViewChild('bpText') bpText: ElementRef;

   
    
    billpadCodesForCopy: string= "";
    pdgmResults: any = {
        PrimaryDx: false,
        LowComorbidityAdjustment: false,
        HighComorbidityInteractionAdjustment: false
    };
    pdgmLoaded: Boolean = false;
    bpSelected: Boolean = false;

    constructor( @Inject(BillpadApiService) private billpadService: BillpadApiService,
        @Inject(CookieService) private cookieService: CookieService) {
    }
    get billpadCodes(): IBillpadCode[] {
        return this.billpadService.billpadCodes;
    }

    ngOnInit() {

    }

    selectBillpad() {

        var codes = this.billpadCodes;
        if (codes !== null) {
            for (let i = 0; i < codes.length; i++) {
                this.billpadCodesForCopy = this.billpadCodesForCopy + codes[i].Code + "\n";
            }
        }
        this.bpSelected = true;
        this.bpText.nativeElement.select();
    }

    hideSelect() {
        this.bpSelected = false;
    }

    printBillpad() {
        this.saveBillPadToCookie();
        window.open("/MVC/api/Billpad/Print", "_blank");
    }

    exportBillpad() {
        this.saveBillPadToCookie();
        window.open("/MVC/api/Billpad/CSV", "_blank");
    }

    saveBillPadToCookie() {
        const codeObjJson = JSON.stringify(this.billpadCodes);
        this.cookieService.set("billpadCodes", codeObjJson, { path: '/' });
    }

    removeBillpadCode(code) {
        this.removeCode(code);

        // Clear PDGM results since they no longer apply
        this.pdgmLoaded = false;

        // Refresh billpad icon in case we're on a details page
        //this.refreshBillpadIcon(code);
    }

    removeCode(code) {
        this.billpadService.removeCodeTracker(code,false);
    }

    clearBillpadCodes() {
      
       this.billpadService.clearBillpadCodes();

        // Clear PDGM results since they no longer apply
        this.pdgmLoaded = false;
    }
   

    discardBillpadChanges() {
        this.billpadService.discardBillpadChanges();

        // Clear PDGM results since they no longer apply
        this.pdgmLoaded = false;
    }

    performPDGMValidation(){
        this.billpadService.performPDGMValidationAsync(this.billpadCodes).then(
            (response) => {
                const data = response;
                this.pdgmResults = { ...data };
                this.pdgmLoaded = true;
            },
            (response: angular.IHttpResponse<any>) => {
                console.error("error performing PDGM validation: " + response.statusText);
            }
        );
    }
    saveBillpadCodes() {
        this.billpadService.saveCodesToBillpadAsync();
    }
    drop(event: CdkDragDrop<IBillpadCode[]>) {
        moveItemInArray(this.billpadCodes, event.previousIndex, event.currentIndex);
        // Clear PDGM results since they no longer apply
        this.pdgmLoaded = false;
    }
} 