import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ForumComponent } from './forum.component';
import { ForumService } from './forum.service';

@NgModule({
    imports: [
        CommonModule,
        FormsModule
    ],
    providers: [
        ForumService
    ],
    exports: [
        ForumComponent
    ],
    declarations: [
        ForumComponent

    ]
})
export class ForumModule { }

   
