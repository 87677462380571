import { Component, Inject, Input, OnInit } from '@angular/core';
import * as adminUserModels from './manage-users.models';

import membershipGridTemplate from './membership-grid.html';

@Component({
    selector: 'membership-grid',
    template: membershipGridTemplate
})
export class MembershipGridComponent  {
    @Input() memberships: adminUserModels.IWebAuthMembership[];
    @Input() adminCustomerId: number;

    ngOnInit() {
        if (!this.memberships) {
            this.memberships = [];
        }
        if (!this.adminCustomerId) {
            this.adminCustomerId = -1;
        }
    }

    getExpiredDate(effectiveDates: adminUserModels.IEffectiveDate[], expiredDate: string): string {
        if (effectiveDates.length > 0) {
            return this.formatDate(effectiveDates[0].StopDate);
        }
        else {
            return this.formatDate(expiredDate);
        }
    }

    formatDate(date: string): string {
        let jsDate: Date = new Date(parseInt(date.substr(6)));
        let m: number = jsDate.getMonth() + 1;
        let d: number = jsDate.getDate();
        let y: number = jsDate.getFullYear();
        return m.toString() + "/" + d.toString() + "/" + y.toString();
    }
}
