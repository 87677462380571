import { Pipe, PipeTransform } from '@angular/core';
import { IHHCCUser, UserSortOrder } from './manage-users.models';

@Pipe({
    name: 'sortUsers'
})
export class SortUsersPipe implements PipeTransform {

    transform(users: Array<IHHCCUser>, sortColumn: string, userReverseSort:boolean): Array<IHHCCUser> {
        if (sortColumn) {

            users.sort((n1, n2) => {
                
                switch (sortColumn) {
                    case UserSortOrder.FirstName:
                    return this.compareString(n1.FirstName, n2.FirstName, userReverseSort) || this.compareString(n1.LastName, n2.LastName, userReverseSort);
                    case UserSortOrder.LastName:
                        return this.compareString(n1.LastName, n2.LastName, userReverseSort) || this.compareString(n1.FirstName, n2.FirstName, userReverseSort);
                    case UserSortOrder.Email:
                        return this.compareString(n1.Email, n2.Email, userReverseSort) || this.compareString(n1.LastName, n2.LastName, userReverseSort) || this.compareString(n1.FirstName, n2.FirstName, userReverseSort);
                    case UserSortOrder.Username:
                        return this.compareString(n1.UserName, n2.UserName, userReverseSort) || this.compareString(n1.LastName, n2.LastName, userReverseSort) || this.compareString(n1.FirstName, n2.FirstName, userReverseSort);
                    case UserSortOrder.Company:
                        return this.compareString(n1.Company, n2.Company, userReverseSort) || this.compareString(n1.LastName, n2.LastName, userReverseSort) || this.compareString(n1.FirstName, n2.FirstName, userReverseSort);
                    case UserSortOrder.ServiceLevel:
                        return this.compareString(n1.ServiceLevel, n2.ServiceLevel, userReverseSort) || this.compareString(n1.LastName, n2.LastName, userReverseSort) || this.compareString(n1.FirstName, n2.FirstName, userReverseSort);
                    case UserSortOrder.Admin:
                        return this.compareBoolean(n1.IsAdmin, n2.IsAdmin, userReverseSort) || this.compareString(n1.LastName, n2.LastName, userReverseSort) || this.compareString(n1.FirstName, n2.FirstName, userReverseSort);
                    case UserSortOrder.Locked:                        
                        return this.compareBoolean(n1.IsLockedOut, n2.IsLockedOut, userReverseSort) || this.compareString(n1.LastName, n2.LastName, userReverseSort) || this.compareString(n1.FirstName, n2.FirstName, userReverseSort);
                    
                    default: 
                        return this.compareString(n1.LastName, n2.LastName, userReverseSort) || this.compareString(n1.FirstName, n2.FirstName, userReverseSort);
                }
            });
            return users;
        }
        else {
            return users;
        }

    }
    compareString(a:string,b:string,reverse:boolean):number {
        if (reverse) {
            return b.localeCompare(a);
        }
        else {
            return a.localeCompare(b);
        }
    }

    compareBoolean(a: boolean, b: boolean, reverse: boolean): number {
        if ((a > b && !reverse) || (a < b && reverse)) {
            return 1;
        }
        else {
            return -1;
        }
    }
}
