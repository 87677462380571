import { Pipe, PipeTransform } from '@angular/core';

import { IUserNote } from './userNotes.models';

@Pipe({ name: 'filterNotes' })
export class FilterNotesPipe implements PipeTransform {
    transform(allNotes: IUserNote[], filterVal: string) {
        if (!allNotes || !filterVal) {
            return allNotes;
        }
        var filteredList = allNotes.filter(note => (note.UserNote!="" && note.UserNote?.toLowerCase().includes(filterVal.toLowerCase())) || (note.Code !="" && note.Code?.toLowerCase().includes(filterVal.toLowerCase())) || (note.Description != "" && note.Description?.toLowerCase().includes(filterVal.toLowerCase())));
        return filteredList;
    }
}