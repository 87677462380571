import { Injectable, Inject } from '@angular/core';
import { HttpClient } from "@angular/common/http";

import { IBillpadCode } from "./billpadApi.models";


@Injectable({
    providedIn: 'root'
})
export class BillpadApiService  {

    private _billpadCodes: IBillpadCode[];
    private loadingbills: Boolean= false;
    get billpadCodes(): IBillpadCode[] {
      
        if (!this._billpadCodes && !this.loadingbills) {
            this.loadingbills = true;
            this.getBillpadCodes().then(response => { this._billpadCodes = response; this.loadingbills = false;});
        }
        else {
            return this._billpadCodes;
        }
       
    }
    constructor(@Inject(HttpClient) private http: HttpClient) {

    }

    private  getBillpadCodes(): Promise<IBillpadCode[]> {
       return this.http.get<IBillpadCode[]>("/mvc/api/Billpad/GetBillpadCodes").toPromise();
    }
    get numCodes(): number {
        if (this.billpadCodes != null) {
            return this.billpadCodes.length;
        } else {
            return 0;
        }
    };

    saveCodeToBillpadAsync(code: IBillpadCode): Promise<any> {
        return this.http.post<any>("/mvc/api/Billpad/SaveCodeToBillPad", code).toPromise();
    }

    saveCodesToBillpadAsync(): Promise<any> {
        
        return this.http.post<any>("/mvc/api/Billpad/SaveBillPadCodes", this._billpadCodes).toPromise();
    }

    performPDGMValidationAsync(codes: IBillpadCode[]): Promise<any> {
        return this.http.post<any>("/mvc/api/Billpad/PDGMValidation", codes).toPromise();
    }

    isCodeInBillpad(recentCode: string) {
        if (this.billpadCodes) {
           var code = this.billpadCodes.find(x => x.Code===recentCode);
            if (code) {
                return true;
            }
        }
        return false;
    }
    removeCodeTracker(code, save) {
        if (this._billpadCodes != null) {
            const index = this._billpadCodes.findIndex(item => item.Code === code);
            this._billpadCodes.splice(index, 1);
        }
        if (save) {
            this.saveCodesToBillpadAsync();
        }
    }
    addCodeTracker(code, description) {
        const codeObj: IBillpadCode = {
            Code: code,
            Description: description
        };
        this.saveCodeToBillpadAsync(codeObj).then(response => {
            this._billpadCodes.push(codeObj);
        });

    }

    clearBillpadCodes() {
        this._billpadCodes = [];
        this.saveCodesToBillpadAsync();
    }
    discardBillpadChanges() {
        this.getBillpadCodes().then(response => { this._billpadCodes = response; });
    }
   
}
