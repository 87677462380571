import { Component, Inject, Input, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import * as models from "./article.models";
import { ArticleService } from "./article.service";

import articleSearchTemplate from './article-search.html';

@Component({
    selector: 'article-search',
    template: articleSearchTemplate
})
export class ArticleSearchComponent {
    constructor(@Inject(ArticleService) private articleService: ArticleService,
        @Inject(CookieService) private cookies: CookieService,
        @Inject(DOCUMENT) private document: Document) {
    }

    articleSection: string;
    keywords: string;
    recentSearches: models.IRecentArticleSearchTerm[];

   
    ngOnInit() {
        this.keywords = "";

        this.articleSection = this.cookies.get("ArticleSection");
        if (!this.articleSection) {
            this.articleSection = "All Articles";
        }

        const lastSearchTerm = this.cookies.get("LastArticleSearchTerm");
        // if on the search or details page, retain the search term
        if (lastSearchTerm) {
            this.keywords = lastSearchTerm;
        }

        this.articleService.getRecentSearches().then(result => {
            this.recentSearches = result;
        });
    }

    // On click of one of the Recent Searches, take user to appropriate search page
    recentSearchClick(t: models.IRecentArticleSearchTerm) {
        this.document.location.href = "/MVC/DCP/Search?st=" + t.searchType + "&sk=" + encodeURIComponent(t.searchTerm) + "&tab=1";
    }

    // Update the article selection based on the drop down selection
    updateArticleSection(section: string) {
        this.articleSection = section;
        this.cookies.delete("ArticleSection", "/");
        this.cookies.set("ArticleSection", section, {'path': "/"});
    }

    searchArticles() {
        if (this.keywords.trim().length > 0) {
            this.cookies.delete("LastArticleSearchTerm", "/");
            this.cookies.set("LastArticleSearchTerm", this.keywords.trim(), {'path': "/"});

            // Save the recent search to the session
            this.articleService.addRecentArticleSearch(this.articleSection, this.keywords);

            this.document.location.href = "/MVC/DCP/Search?sk=" + encodeURIComponent(this.keywords)
                + "&st=" + this.articleSection
                + "&tab=1";
        }
    }
}
