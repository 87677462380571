import { Component, Inject, Input, OnInit, Output } from '@angular/core';
import { ProfileService } from './profile.service';
import * as models from "./models";

import profileTemplate from './profile.html';

@Component({
    selector: 'profile',
    template: profileTemplate
})
export class ProfileComponent {

    // props
    showNotification: boolean = false;
    notificationClass: string = "";
    notificationMsg: string = "";
    currentUser: models.IAccountProfile;
    manageAccount: any;


    constructor(@Inject(ProfileService)private profileService: ProfileService) {
    }

    ngOnInit() {


        this.profileService.getLoggedInUser().then(
            response => {
                const data = response;
                if (data) {
                    this.currentUser = data;
                }
            },
            response => {
                console.error(response);
                this.showNotification = true;
                this.notificationClass = "alert-danger";
                this.notificationMsg = "Something went wrong while trying to retrieve your user information.";
            }
        );
    }

    formatDate(date: string): string {
        let jsDate = new Date(parseInt(date.substr(6)));
        var m = jsDate.getMonth() + 1;
        var d = jsDate.getDate();
        var y = jsDate.getFullYear();
        return m.toString() + "/" + d.toString() + "/" + y.toString();
    }

    updateProfile() {
        this.showNotification = false;

        if (this.manageAccount.$valid) {
            
            this.profileService.updateUser(this.currentUser).then(
                response => {
                    console.log('ProfileController-updateProfile: ', response);
                    this.showNotification = true;
                    this.notificationClass = "alert-success";
                    this.notificationMsg = "Save successful!";
                    this.sendEmail();
                },
                response => {
                    console.error('ProfileController-updateProfile-error: ', response);
                    this.showNotification = true;
                    this.notificationClass = "alert-danger";
                    this.notificationMsg = "Something went wrong while trying to retrieve your user information.";
                }
            );
        }
    };

    sendEmail(): void {
        this.profileService.sendEmail(this.currentUser).then(result => {
            console.log('ProfileController-sendEmail: ', result);
        }).catch(result => {
            console.error('ProfileController-sendEmail-error: ', result);
        });
    }
}
