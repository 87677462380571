import { Injectable, Inject } from '@angular/core';
import { HttpClient } from "@angular/common/http";

import { IAjaxResultForumInfo } from './forum.models';

@Injectable({
    providedIn: 'root'
})
export class ForumService {

    constructor(@Inject(HttpClient) private http: HttpClient) {
    }

    loadForum(threadCount: number, forumName: string) {

        const parameters = { params: { threadCount: threadCount.toString(), forumName: forumName, pageNum: "1" } };
        return this.http.get<IAjaxResultForumInfo>("/MVC/Forum/LoadForum", parameters).toPromise();
         
    }

    loadLatestForum(threadCount: number, forumId: number) {

        const parameters = { params: { forumId: forumId.toString(), threadCount: threadCount.toString() } };
        return this.http.get<IAjaxResultForumInfo>("/MVC/Forum/LoadLatestForum", parameters).toPromise();

    }
}