import { Injectable, Inject } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import * as adminUsersModels from '../manage-users.models';

export interface IRemoveUsersService {
    removeUser(user: adminUsersModels.IHHCCUser): Promise<any>;
}

@Injectable({
    providedIn: 'root'
})
export class RemoveUsersService implements IRemoveUsersService {
    constructor(@Inject(HttpClient) private http: HttpClient) {
    }

    removeUser(user: adminUsersModels.IHHCCUser): Promise<any> {
        return this.http.post("/MVC/ManageUsers/RemoveUser", { customerId: user.CustomerId.toString() }, { responseType: 'text' }).toPromise();
    }
}