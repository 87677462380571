import { Injectable, Inject } from '@angular/core';
import { HttpClient } from "@angular/common/http";

import { IContentItem } from './content.models';

@Injectable({
    providedIn: 'root'
})
export class ContentService {
    constructor(@Inject(HttpClient) private http: HttpClient) {
    }

    /** Search for content by type and code */
    loadContent(contentTypeId: number, codeTypeId: number, code: string): Promise<IContentItem[]> {
        return this.http.get<IContentItem[]>("/MVC/Content/Search", {
            params: {
                contentType: contentTypeId.toString(),
                codeType: codeTypeId.toString(),
                code: encodeURIComponent(code)
            }
        }).toPromise();
    }

    /** Search for content by type and text */
    searchContent(contentTypeId: number, searchText: string): Promise<IContentItem[]> {
        return this.http.get<IContentItem[]>("/MVC/Content/SearchText", {
            params: {
                contentType: contentTypeId.toString(),
                searchText: searchText
            }
        }).toPromise();
    }
}
