import { Injectable, Inject } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { IVideoContent } from "./video.models";

@Injectable({
    providedIn: 'root'
})
export class VideoService  {
    constructor(@Inject(HttpClient) private http: HttpClient) {
    }

    url: string = '/mvc/Video/GetVideos';

    getVideos(){
        return this.http.get<IVideoContent>(this.url).toPromise();
    }

    
}