import { Component, Inject, Input, OnInit } from '@angular/core';
import { DictionaryService } from "./dictionary.service";

import dictionarySearchTemplate from './dictionary-search.html';

@Component({
    selector: 'dictionary-search',
    template: dictionarySearchTemplate
})
export class DictionarySearchComponent {


    constructor(@Inject(DictionaryService)private dictionaryService: DictionaryService) {
    }

    @Input() query: string= "";

    performSearch(page: number) {
        window.location.href = "/MVC/Dictionary/Search?dictionarySearchTerm=" + this.query;
    }
}
