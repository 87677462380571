import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { TipsModalComponent } from "./tips-modal.component";
//import SiteContentDisplayController from "./siteContentDisplayController";
import { SiteContentService } from "./siteContent.service";

@NgModule({
    imports: [
        CommonModule,
        FormsModule
    ],
    providers: [
        SiteContentService
    ],
    declarations: [
        TipsModalComponent

    ]
})
export class SiteContentModule { }

//export default angular.module("codingCenterApp.sitecontent", [])
//    .directive("tipsModal", downgradeComponent({ component: TipsModalComponent }))
//  //  .controller("SiteContentDisplayController", SiteContentDisplayController)
//    .factory("SiteContentService", downgradeInjectable(SiteContentService))
//    .name;
