import { Component, Inject, Input } from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { UserNotesService } from './userNotes.service'
import userNotesLookbackTemplate from './user-notes-lookback.html';
import { UserNotesComponent } from './user-notes.component';

@Component({
    selector: 'user-notes-lookback',
    template: userNotesLookbackTemplate
})
export class UserNotesLookbackComponent  {

    constructor(@Inject(UserNotesService) public userNotesService: UserNotesService) {
    }

    @Input() itemid: number;
    @Input() itemtype: string;
    @Input() isadmin: Boolean;

    public userEditor = ClassicEditor;
    public groupEditor = ClassicEditor;

    origUserNote: string = "";
    origGroupNote: string = "";
    groupNoteContent: string;
    userNoteContent: string;
    disabled: Boolean;
    noteDetails: any;



    // Loads the user and group notes for the MItem
    ngOnInit() {

        const urlUser = "/MVC/UserNotes/GetByCodeAndUser";
        const urlGroup = "/MVC/UserNotes/GetByCodeAndAgreement";

        let iItemType = 0;

        switch (this.itemtype) {
            case "MItem":
                iItemType = 15;
                break;
            case "ICD10":
                iItemType = 13;
                break;
        }

        this.userNotesService.getByCodeAndUser(this.itemid, iItemType).then((response) => {
            const data = response;
                if (data) {
                    this.origUserNote = data.UserNote;
                    this.userNoteContent = data.UserNote;
                }
            },
            (response) => {
                console.error(response);
            });

        this.userNotesService.getByCodeAndAgreement(this.itemid, iItemType).then((response) => {
            const dataTwo = response;

            if (dataTwo) {
                    this.origGroupNote = dataTwo.UserNote;
                    this.groupNoteContent = dataTwo.UserNote;
                }
            },
            (response) => {
                console.error(response);
            });

        this.disabled = false;
    }

    populateNoteDetails(noteId, noteType, isGroupNote) {
        this.noteDetails = {
            noteId: noteId,
            noteType: noteType,
            isGroupNote: isGroupNote
        }
    }


    //Option for deleting loockback Notes for OASIS
    deleteUserNotes()
    {     
        let codeByType = this.getCodeTypeIdByItemType(this.noteDetails.noteType);

        this.userNotesService.deleteUserNotes(this.noteDetails, codeByType).then((response) => {
            if (this.noteDetails.noteType == "MItem") {
                if (this.noteDetails.isGroupNote) {
                    this.groupNoteContent = '';
                    return;
                }
                this.userNoteContent = '';
            }
        }).catch((response) => {
            console.error("Note wasn't deleted: " + response);
        })
    }

 

    getCodeTypeIdByItemType(itemType) {
        switch (itemType) {
            case "MItem":
                return 15;
            case "ICD10":
                return 13;
        }
    }
}