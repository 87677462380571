import { Component, ElementRef, Inject, Input, ViewChild } from '@angular/core';
import { BillpadApiService } from './billpadApi.service';

import billpadToggleTemplate from './billpad-toggle.html';

@Component({
    selector: 'billpad-toggle',
    template: billpadToggleTemplate
})
export class BillpadToggleComponent {
    

    constructor(@Inject(BillpadApiService) private billpadService: BillpadApiService) {

    }
    @Input() code: string;
    @Input() description: string;
    //@Input() coloredicon: boolean;
    private _coloredicon = false;

    @Input('coloredicon')
    get coloredicon(): boolean {
        return this._coloredicon;
    }
    set coloredicon(value: boolean) {
        this._coloredicon = value !== undefined;
    }
    ngOnInit() {
    }
    ngAfterViewInit() {
    }
    isCodeInBillpad() {
        return this.billpadService.isCodeInBillpad(this.code);
    }
    addCodeTracker() {
        this.billpadService.addCodeTracker(this.code,this.description);
        //c.InBillpad = true;

    }

    removeCodeTracker() {
        this.billpadService.removeCodeTracker(this.code, true);
        //c.InBillpad = false;
    }
}