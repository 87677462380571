import { Injectable, Inject } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import * as adminUsersModels from '../manage-users.models';

export interface IAddUserService {
    getUserByUsername(username: string): Promise<adminUsersModels.IWebAuthUser>;
    addNewUser(user: adminUsersModels.IAddUser): Promise<adminUsersModels.INewUserId>;
    grantRoleToUser(userId: number, roleCode: string): Promise<any>;
    linkMembershipToCustomer(parentCustomerId: number, membershipId: number, childCustomerId: number): Promise<boolean>;
    sendAccountCreatedEmail(newUser: adminUsersModels.IAddUser): Promise<boolean>;
}

@Injectable({
    providedIn: 'root'
})
export class AddUserService implements IAddUserService {
    constructor(@Inject(HttpClient) private http: HttpClient) {
    }

    user: adminUsersModels.IAddUser = {
        Username: '',
        Password: '',
        FirstName: '',
        LastName: '',
        Company: '',
        IsAdmin: false,
        ServiceLevelMembershipId: null,
        AddOnMembershipIds: []
    };

    clearUsername(): void {
        this.user.Username = '';
    }

    clearNewUser(): void {
        this.user.Password = '';
        this.user.FirstName = '';
        this.user.LastName = '';
        this.user.Company = '';
        this.user.IsAdmin = false;
        this.user.ServiceLevelMembershipId = null;
        this.user.AddOnMembershipIds = [];
    }

    loadAddUser(addUser: adminUsersModels.IAddUser) {
        this.user = {
            Username: addUser.Username,
            Password: addUser.Password,
            FirstName: addUser.FirstName,
            LastName: addUser.LastName,
            Company: addUser.Company,
            IsAdmin: addUser.IsAdmin,
            ServiceLevelMembershipId: addUser.ServiceLevelMembershipId,
            AddOnMembershipIds: addUser.AddOnMembershipIds.slice()
        };
    }

    getUserByUsername(username: string): Promise<adminUsersModels.IWebAuthUser> {
        return this.http.get<adminUsersModels.IWebAuthUser>("/MVC/ManageUsers/GetUserByUsername", { params: { username: username } }).toPromise();
    }

    addNewUser(user: adminUsersModels.IAddUser): Promise<adminUsersModels.INewUserId> {
        return this.http.get<adminUsersModels.INewUserId>("/MVC/ManageUsers/AddUser", {
            params: {
                Username: user.Username,
                Password: user.Password,
                FirstName: user.FirstName,
                LastName: user.LastName,
                Company: user.Company,
                IsAdmin: user.IsAdmin.toString(),
                ServiceLevel: user.ServiceLevelMembershipId.toString(),
                AddOns: user.AddOnMembershipIds.toString()
            }
        }).toPromise();
    }

    grantRoleToUser(userId: number, roleCode: string): Promise<angular.IHttpResponse<any>> {
        return this.http.get<any>("/MVC/ManageUsers/GrantRoleToUser", { params: { userId: userId.toString(), roleCode: roleCode } }).toPromise();
    }

    linkMembershipToCustomer(parentCustomerId: number, membershipId: number, childCustomerId: number): Promise<any> {
        return this.http.get("/MVC/ManageUsers/LinkMembershipToCustomer",
            {
                responseType: 'text', params:
                    { parentCustomerId: parentCustomerId.toString(), membershipId: membershipId.toString(), childCustomerId: childCustomerId.toString() }
            }).toPromise();
    }

    sendAccountCreatedEmail(newUser: adminUsersModels.IAddUser): Promise<any> {
        return this.http.get("/MVC/ManageUsers/SendNewUserEmail", {
            responseType: 'text',
            params: {
                firstName: newUser.FirstName,
                lastName: newUser.LastName,
                username: newUser.Username,
                password: newUser.Password
            }
        }).toPromise();
    }
}