import { Component, Inject, Input, OnInit } from '@angular/core';
import { IArticle, INewsletterArticle } from './article.models';
import { ArticleService } from './article.service';
import { NewsletterService } from './newsletter.service';
import newsletterTemplate from './newsletter.html';
import { IAjaxResultArticles } from './article.models';

@Component({
    selector: 'newsletter',
    template: newsletterTemplate
})

export class NewsletterComponent {
   
    @Input() newslettertype: string;
    @Input() tabindexvalue: number;

    articlesLoaded: Boolean;
    currentPageMinCount: number;
    currentPageMaxCount: number;
    referrer = document.referrer;
    defaultPageSize: number = 25;
    
    newsletterType = 0; // Diagnosis Coding Pro
    newsletterSearchResults: INewsletterArticle[] = [];
    pageIndex: number = 1;
    pageMin: number= 1;
    pageMax: number;
    pageSize: number;
    paging: number[];
    totalRecords = -1;

    constructor(@Inject(NewsletterService) private newsletterService: NewsletterService) {

    }

    //performs the search
    ngOnInit() {
        this.searchNewsletters(25,1);
    }
    setPaging() {
        var size = this.pageSize == this.defaultPageSize ? -1 : this.defaultPageSize;
        this.searchNewsletters(size, 1);
    }
    searchNewsletters(pgSize: number, pgIndex: number) {
        this.articlesLoaded = false;
        this.pageSize = pgSize; //if page size equals -1, return all results

        //set the page index
        if (pgIndex < this.pageMin || this.pageSize === -1) {
            this.pageIndex = this.pageMin;
        } else if (this.pageMax) {
            this.pageIndex = (pgIndex > this.pageMax) ? this.pageMax : pgIndex;
        } else {
            this.pageIndex = pgIndex;
        }

        this.newsletterService.getNewsletter(this.newslettertype, this.pageSize, this.pageIndex).then(response => {
            const data = response;
            this.newsletterSearchResults = data.Newsletters;

            //find total number of records
            this.totalRecords = (this.newsletterSearchResults.length > 0) ? data.TotalCount : 0;

            //get the current record number view
            if (this.pageSize === -1) {
                this.currentPageMinCount = this.pageMin;
                this.currentPageMaxCount = this.totalRecords;
            } else {
                this.currentPageMinCount = this.pageIndex * this.pageSize - (this.pageSize - 1);
                this.currentPageMaxCount = this.pageIndex * this.pageSize;
                if (this.currentPageMaxCount > this.totalRecords) {
                    this.currentPageMaxCount = this.totalRecords;
                }
            }

            //setup paging links
            const mod = this.pageIndex % 4;
            switch (mod) {
                case 1:
                    this.paging = [this.pageIndex, (this.pageIndex + 1), (this.pageIndex + 2), (this.pageIndex + 3)];
                    break;
                case 2:
                    this.paging = [(this.pageIndex - 1), this.pageIndex, (this.pageIndex + 1), (this.pageIndex + 2)];
                    break;
                case 3:
                    this.paging = [(this.pageIndex - 2), (this.pageIndex - 1), this.pageIndex, (this.pageIndex + 1)];
                    break;
                case 0:
                    this.paging =
                        [(this.pageIndex - 3), (this.pageIndex - 2), (this.pageIndex - 1), (this.pageIndex)];
                    break;
            }
            if (this.pageSize === -1) {
                this.pageMax = 1;
            } else {
                this.pageMax = Math.floor(this.totalRecords / this.pageSize);
                const mod2 = this.totalRecords % this.pageSize;
                if (mod2 > 0) {
                    this.pageMax++;
                }
            }
            for (let i = 3; i > 0; i--) {
                if (this.paging[i] > this.pageMax) {
                    this.paging = this.paging.slice(0, i);
                }
            }

            this.articlesLoaded = true;
        });
       
    }

    // Formats JSON dates correctly
    dateFormatter(dateVal: string): string {
        const d = new Date(parseInt(dateVal.replace(/\/Date\((.*?)\)\//gi, "$1"), 10));
        const returnDate = d.getMonth() + "/" + d.getDate() + "/" + d.getFullYear();
        return returnDate;
    }
}
