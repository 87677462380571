///<reference path="../../../../node_modules/@types/bootstrap/index.d.ts"/>
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { LoginService } from './login.service';

import loginTemplate from './login.html';
import { areAllEquivalent } from '@angular/compiler/src/output/output_ast';

@Component({
    selector: 'login',
    template: loginTemplate
})
export class LoginComponent {

    loginError: string;
    showEula: boolean = false;
    userName: string;
    password: string;
    rememberMe: boolean = false;


    constructor(@Inject(LoginService) private loginService: LoginService, @Inject(DOCUMENT) public document: Document) {

    }

    displayEula() {
        this.showEula = true;
    }

    public onEulaAccepted(value): void {
        if (value) {
            this.document.location.href = '/MVC/';
        }
        else {
            this.showEula = false;
        }
    }

    logUserIn() {
        this.loginService.logUserIn(this.userName, this.password, this.rememberMe).then(data => {
            if (data == "") {
                this.document.location.href = '/MVC/';
            }
            else if (data == "SHOW_EULA") {
                this.displayEula();
            }
            else {
                this.loginError = data;
            }
        }).catch((e) => {
            console.log(e);
            this.loginError = "The system is unable to process your login request. Please contact Customer Service for help.";
        });

    }

}