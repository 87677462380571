import { Component, Inject, Input, OnInit } from '@angular/core';
import { UsageReportingChartsService } from "../usage-reporting-charts.service";
import { IMostSearchedTermsModel } from "../usage-reporting-charts.models"

import template from '../chartsTemplate.html';

@Component({
    selector: 'most-searched-terms-chart',
    template: template
})
export class MostSearchedTermsChartComponent {
    chart: any = {};
    chartData: IMostSearchedTermsModel[];
    chartDataRows: any[];

    totalRecords: number = 0;
    //Search
    searchQuery: string = "";

    isLoaded: boolean = false;

    constructor(@Inject(UsageReportingChartsService) private userReportingChartsService: UsageReportingChartsService) {

    }

    ngOnInit() {
        this.getMostSearchedTerms();
    }

    search() {
        this.chart.data = this.userReportingChartsService.search(this.chartDataRows, this.searchQuery);
        this.totalRecords = this.chart.data.length;
        this.isLoaded = true;
    }

    getMostSearchedTerms() {
        this.userReportingChartsService.getMostSearchedTerms().then(response => {
            this.chartData = response ? response : [];
            if (this.chartData) {
                this.chart.type = "Table";
                this.chart.columnNames = [{ label: "Total", type: "number" }, { label: "Term(s)", type: "string" }, { label: "Search Type", type: "string" }];

                this.chart.data= [];
                var self = this;
                this.chartData.forEach(function (item, index) {
                    var chartItem = [{ v: item.Total }, { v: item.Term }, { v: item.SearchType }];
                   self.chart.data.push(chartItem);
                })
                this.chart.options = this.userReportingChartsService.getChartDefaultOptions();
                this.chartDataRows = this.chart.data;
                this.totalRecords = this.chart.data.length;
                this.isLoaded = true;
            }
        }).catch(error => {
            console.error(error);
        });
    }
}
