import { Injectable, Inject } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { IAjaxResultDorlandDictionarySearchResult, IAppendixEntry, IDorlandDictionaryEntry } from './models';

@Injectable({
    providedIn: 'root'
})
export class DictionaryService {

    constructor(@Inject(HttpClient) private http: HttpClient) {
    }

    getAppendecies() {
        return this.http.get<IAppendixEntry[]>("/MVC/Dictionary/ListAppendices").toPromise();
    }

    performSearch(query: string, pageIndex: number) {

        const urlDictionarySearch = "/MVC/Dictionary/RunSearch";
        const parameters = { params: { query: query, page: pageIndex.toString() } };

        return this.http.get<IAjaxResultDorlandDictionarySearchResult>(urlDictionarySearch, parameters).toPromise();

    }

    getDictionary(dictionaryId: number) {
        const urlGetDictionary = "/MVC/Dictionary/GetDictionary";
        const parameters = { params: { id: dictionaryId.toString() } };
        return this.http.get<IDorlandDictionaryEntry>(urlGetDictionary, parameters).toPromise();
    }
}