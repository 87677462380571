import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TrialBannerComponent } from "./trial-banner.component";



@NgModule({
    imports: [
        CommonModule
    ],
    providers: [

    ],
    declarations: [
        TrialBannerComponent
    ]
})
export class TrialBannerModule { }