import { Component, Inject, Input, OnInit } from '@angular/core';
import * as adminUserModels from './manage-users.models';

import notificationTemplate from './notification.html';

@Component({
    selector: 'notification',
    template: notificationTemplate
})
export class NotificationComponent {
    @Input() notification: adminUserModels.INotification;

    ngOnInit() {
        if (!this.notification) {
            this.notification = {
                Show: false,
                Message: '',
                CssClass: ''
            };
        }
    }
}
