import { Injectable, Inject } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import * as model from "./usage-reporting-charts.models";




@Injectable({
    providedIn: 'root'
})
export class UsageReportingChartsService  {

    constructor(@Inject(HttpClient) private http: HttpClient) {
    }

    apiUrl: string = '/mvc/api/UsageReportingCharts/';


    getMostViewedDetailPages(): Promise<model.IMostViewedDetailPageModel[]> {
        return this.http.get<model.IMostViewedDetailPageModel[]>(this.apiUrl + 'GetMostViewedDetailPages').toPromise();
    }

    getMostSearchedTerms(): Promise<model.IMostSearchedTermsModel[]> {
        return this.http.get<model.IMostSearchedTermsModel[]>(this.apiUrl + 'GetMostSearchedTerms').toPromise();
    }

    getMostSearchedCodes(): Promise<model.IMostSearchedCodesModel[]> {
        return this.http.get<model.IMostSearchedCodesModel[]>(this.apiUrl + 'GetMostSearchedCodes').toPromise();
    }

    getUsersSummaryInfo(): Promise<model.IUsersSummaryInfo[]> {
        return this.http.get<model.IUsersSummaryInfo[]>(this.apiUrl + 'GetUsersSummaryInfo').toPromise();
    }

    getUserIndividualInfo(childId: number): Promise<model.IUserIndividualInfo> {
        return this.http.get<model.IUserIndividualInfo>(this.apiUrl + 'GetUserIndividualInfo',
            { params: { childId: childId.toString() } }).toPromise();
    }

    search(items: any[], searchQuery: string): any[] {

        if (!items)
            return [];

        if (!searchQuery) 
            return items;

        var filteredItems = [];
        var self = this;

        items.forEach(function (row, index) {
            for (var prop in row) {
                if (self.searchMatch(row[prop].v.toString(), searchQuery)) {
                    filteredItems.push(row);
                    break;
                }
            }

        });
        return filteredItems;
    }

    searchMatch(item, query) {
        if (!query)
            return false;

        return item.toLowerCase().indexOf(query.toLowerCase()) !== -1
    }

    getChartDefaultOptions(): any {
        return  {
            allowHtml: true,
            sort: 'disable',
            page: 'enable',
            pageSize: 25,
            width: 700
        }
    }
}