import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { HhccSearchAutocomplete } from "./hhccSearchAutocomplete.directive";
import { SearchBoxComponent } from "./search-box.component";
import { SearchBoxService } from "./searchBox.service";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule
    ],
    providers: [
        SearchBoxService
    ],
    declarations: [
        HhccSearchAutocomplete,
        SearchBoxComponent

    ]
})
export class SearchBoxModule { }