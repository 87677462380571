import { Injectable, Inject } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { IAjaxResultArticles, IAjaxResultArticleSearch, IRecentArticleSearchTerm, IAjaxResultLatestHeadlines, IArticle } from "./article.models";
import { BookmarksService } from "../core/bookmarks/bookmarks.service";

@Injectable({
    providedIn: 'root'
})
export class ArticleService {
    constructor(@Inject(HttpClient) private http: HttpClient, @Inject(BookmarksService) private bookmarksSvc: BookmarksService) {
    }

    getArticlesByType(pCount: number, pType: string, pSubtype: string) : Promise<IAjaxResultArticles> {
        const parameters = { params: { type: pType, subtype: pSubtype, count: pCount.toString() } };
        return this.http.get<IAjaxResultArticles>("/MVC/DCP/GetArticlesByType", parameters).toPromise();
    }

    getRecentSearches() : Promise<IRecentArticleSearchTerm[]> {
        return this.http.get<IRecentArticleSearchTerm[]>("/MVC/DCP/GetRecentSearches").toPromise();
    }

    addRecentArticleSearch(articleSection: string, keywords: string) {
        const parameters = { params: { searchType: articleSection, searchTerm: keywords } };

        this.http.get<any>("/MVC/DCP/AddRecentArticleSearch", parameters);
    }

    getLatestHeadlines() {
        const url = "/MVC/DCP/GetArticlesByType";
        const parameters = {
            params: {
                type: "1003",
                subType: "-1",
                count: "3"
            }
        };

        return this.http.get<IAjaxResultLatestHeadlines>(url, parameters).toPromise();
    }

    getAllArticlesByType(type: string, subtype:string) {
        const parameters = { params: { type: type, subtype: subtype} };
        return this.http.get<IAjaxResultArticles>("/MVC/DCP/GetAllArticlesByType", parameters).toPromise().then(response => {
            var articles = response;
            return articles;
        });
    }

    getLatestIssue(pType: string, pCount: number) {
        const parameters = { params: { type: pType, count: pCount.toString() } };
        return this.http.get<IAjaxResultArticles>("/MVC/DCP/GetLatestIssue", parameters).toPromise();
    }

    getArticleDetail(articleId: number) {
        const parameters = { params: { articleId: articleId.toString() } };
        return this.http.get<IArticle>("/MVC/DCP/GetArticleDetails", parameters).toPromise();
    }

    searchArticles(articleSection: string, searchTerm: string, pageSize: number, pageIndex: number, sortBy: string,
        startDate: string, endDate: string) {

        const parameters = {
            params: {
                searchType: articleSection,
                searchTerm: searchTerm,
                pageSize: pageSize.toString(),
                pageIndex: pageIndex.toString(),
                sortBy: sortBy,
                startDate: startDate,
                endDate: endDate
            }
        };

        return this.http.get<IAjaxResultArticleSearch>("/MVC/DCP/SearchArticles", parameters).toPromise();
    }

    getArticles(newsId: number, count: number) {
        const parameters = {
            params: { newsId: newsId.toString(), count: count.toString() }
        };

        return this.http.get<IAjaxResultArticles>("/MVC/DCP/GetArticlesByNewsletter", parameters).toPromise();
    }
}