import { Component, Inject, Input, OnInit } from '@angular/core';
import { ForumService } from './forum.service';
import forumTemplate from './forum.html';

@Component({
    selector: 'forum',
    template: forumTemplate
})
export class ForumComponent {

    @Input() threadcount: number;
    @Input() forumid: number;
    panelClass: string;
    threads: any;
    categoryName: string;
    widgetTitle: string;

    constructor(@Inject(ForumService) private forumService: ForumService) {
        
        
    }

    ngOnInit() {
        if (this.forumid == 1) {
            this.panelClass = "oasis-l";
        }
        this.forumService.loadLatestForum(this.threadcount, this.forumid).then(
        response => {
            const data = response;
            this.threads = data.Threads;
            this.categoryName = data.CategoryName;
            this.widgetTitle = data.ForumTitle;
            this.threadcount = data.ThreadCount;
        },
            (response: angular.IHttpResponse<any>) => { console.error(response); });
    }

    // Formats JSON dates correctly
    dateFormatter = function (dateVal) {
        const month = new Array();
        month[0] = "January";
        month[1] = "February";
        month[2] = "March";
        month[3] = "April";
        month[4] = "May";
        month[5] = "June";
        month[6] = "July";
        month[7] = "August";
        month[8] = "September";
        month[9] = "October";
        month[10] = "November";
        month[11] = "December";

        const d = new Date(parseInt(dateVal.replace(/\/Date\((.*?)\)\//gi, "$1"), 10));
        const returnDate = month[d.getMonth()] + " " + d.getDate() + ", " + d.getFullYear();
        return returnDate;
    };
}
