import { Injectable, Inject } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Subject } from "rxjs";
import * as model from "./userNotes.models";

@Injectable({
    providedIn: 'root'
})
export class UserNotesService {
    constructor(@Inject(HttpClient) private http: HttpClient) {
    }

    content: model.IAjaxResultUserNoteContent;
    userNotesLoaded: Subject<model.IAjaxResultUserNoteContent>;

    getContent() {
        return this.content;
    }
    getUserCodeNotes() {
        const remoteUrl = "/MVC/UserNotes/GetUserCodeNotes";

        return this.http.get<model.IAjaxResultUserNoteContent>(remoteUrl).toPromise();
    }

    getByCodeAndUser(itemId: number, iItemType: number) {
        const urlUser = "/MVC/UserNotes/GetByCodeAndUser";

        let parameters = { params: { codeStaticId: itemId.toString(), codeType: iItemType.toString() } };

        return this.http.get<any>(urlUser, parameters).toPromise();
    }

    getByCodeAndAgreement(itemId: number, iItemType: number) {
        const urlGroup = "/MVC/UserNotes/GetByCodeAndAgreement";
        let parameters = { params: { codeStaticId: itemId.toString(), codeType: iItemType.toString() } };

        return this.http.get<any>(urlGroup, parameters).toPromise();
    }

    saveUserNote(itemId: number, iItemType: number, isGroupNote: Boolean, encodedContent: string) {
        const urlSave = "/MVC/UserNotes/SaveUserCodeNoteForItem";

        const parameters = {
            codeStaticId: itemId.toString(),
            codeType: iItemType.toString(),
            isGroupNote: isGroupNote,
            noteText: encodedContent
        };

        return this.http.post<any>(urlSave, parameters).toPromise();
    }

    deleteUserNotes(noteDetails: any, codeByType: number) {
        const urlDelete = "/MVC/UserNotes/DeleteUserNotes";

        const parameters = {
            codeStaticId: noteDetails.noteId.toString(),
            codeType: codeByType,
            isGroupNote: noteDetails.isGroupNote
        };

        return this.http.post<any>(urlDelete, parameters).toPromise();
    }

    convertNote(noteId: number) {
        const convertNoteUrl = "/MVC/UserNotes/ConvertToGroupNote";
        const parameters = {params: {noteId: noteId.toString()}};

        this.http.get<string>(convertNoteUrl, parameters).toPromise().then(
            (response) => {
                const data = response;
                console.log('convert note', data);
                if (data === "False") {
                    alert("The user note could not be converted - A group note already exists for this item.");
                }
            },
            (response) => {
                console.error(response);
            });
    }
}
