import { Component, Inject, Input, OnInit } from '@angular/core';
import { IArticleHeadline, IAjaxResultLatestHeadlines } from './latest-headlines.model';
import { ArticleService } from './article.service';
import latestHeadlinesTemplate from './latest-headlines.html';

@Component({
    selector: 'latest-headlines',
    template: latestHeadlinesTemplate
})
export class LatestHeadlinesComponent {

    // props
    @Input() tabindexvalue: number;
    @Input() isdcp: Boolean = false;
    
    headlinesLoaded: boolean;
    BNNewIconStartDate: Date;
    headlines: IArticleHeadline[];

    constructor(@Inject(ArticleService)private articleService: ArticleService) {
    }

    ngOnInit() {
        this.headlinesLoaded = false;
        this.BNNewIconStartDate = new Date();
        this.BNNewIconStartDate.setDate(this.BNNewIconStartDate.getDate() - 14);

        
        this.articleService.getLatestHeadlines().then(result => {
            this.headlines = result.ArticleList;
            this.headlinesLoaded = true;
            if (this.headlines) {
                for (let i = 0; i < this.headlines.length; i++) {
                    const h = this.headlines[i];
                    if (h.PublishDate) {
                        // parse PublishDate to an actual Date object so it can be compared
                        h.PublishDateObj = new Date(parseInt(h.PublishDate.replace(/\/Date\((.*?)\)\//gi, "$1"), 10));
                    }
                }
            }
        });
    }

    // Takes first 80 chars of html
    headlineBlurb(description: string) : string {
        return description.substring(0, 80) + "...";
    };

    // Takes first 80 chars of html
    abstractBlurb(description: string): string {
        return description.substring(0, 125) + "...";
    };

    // Formats JSON dates correctly
    dateFormatter(dateVal: string): string {
        const month: string[] = [];
        month[0] = "January";
        month[1] = "February";
        month[2] = "March";
        month[3] = "April";
        month[4] = "May";
        month[5] = "June";
        month[6] = "July";
        month[7] = "August";
        month[8] = "September";
        month[9] = "October";
        month[10] = "November";
        month[11] = "December";

        const d = new Date(parseInt(dateVal.replace(/\/Date\((.*?)\)\//gi, "$1"), 10));

        const returnDate = month[d.getMonth()] + " " + d.getDate() + ", " + d.getFullYear();
        return returnDate;
    };
};
