import { Component, Inject, Input } from '@angular/core';

import { BookmarksService } from './bookmarks.service';
import { IBookmarkItem } from './bookmarks.models';

import bookmarksModalTemplate from './bookmarks-modal.html';

@Component({
    selector: 'bookmarks-modal',
    template: bookmarksModalTemplate
})
export class BookmarksModalComponent {

    constructor(@Inject(BookmarksService) private bookmarksService: BookmarksService) {

    }

    @Input() tabindexvalue: string;


    ngOnInit() {
        this.getUserBookmarks();
    }

    getUserBookmarks() {
        this.bookmarksService.getUserBookmarks().then(response => {
            let data = response;
            this.bookmarksService.articlesBookmarks = data.ArticlesBookmarks;
            this.bookmarksService.icd10CodeBookmarks = data.ICD10CodeBookmarks;
            this.bookmarksService.oasisCodeBookmarks = data.OASISBookmarks;
        })
    }

    toggleBookmark(contentType: string, contentId: number, isBookmarked: Boolean) {
        this.bookmarksService.toggleBookmark(contentType, contentId, isBookmarked).then(() => {
            this.getUserBookmarks();
        })
    }
}