import { Component, Inject, Input, OnInit } from '@angular/core';
import { ContentService } from "./content.service";
import { IContentItem } from './content.models';

import interactiveScenariosSearchTemplate from './inter-scenarios-search.html';

@Component({
    selector: 'interactive-scenarios-search',
    template: interactiveScenariosSearchTemplate
})
export class InteractiveScenariosSearchComponent {

    @Input() contenttypeid: number;
    @Input() codeTypeId: number;
    @Input() code: string;
    @Input() count: number;

    noRecords: boolean = false;
    content: IContentItem[] = [];
    pagedContent: IContentItem[] = [];
    pagedCount: number = 0;
    maxCount: number = 3;
    totalCount: number = 0;
    loading: boolean = false;
    searchTerm: string = "";

    constructor(@Inject(ContentService) private contentService: ContentService) {
    }

    ngOnInit() {
        this.pagedCount = this.count;
        this.searchContent();

    }

    searchContent() {

        // Load initial data
        this.noRecords = false;
        this.loading = true;
        this.contentService.searchContent(this.contenttypeid, this.searchTerm).then(data => {
            this.content = data;
            this.totalCount = data.length;

            // Hide when no records
            if (data.length < 1) {
                this.noRecords = true;
            }

            this.loading = false;
        });

    }
}
