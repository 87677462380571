///<reference path="../../../../../node_modules/@types/bootstrap/index.d.ts"/>

import { Component, EventEmitter, Inject, Input, ViewChild, Output } from '@angular/core';
import * as adminUserModels from '../manage-users.models';
import { AddUserService } from '../add-user/add-user.service';
import { FormsModule, ReactiveFormsModule, NgForm } from '@angular/forms';

import addUserTemplate from './add-user.html';

const CUSTOMER_SERVICE_PHONE = "800-650-6787";


@Component({
    selector: 'add-user',
    template: addUserTemplate
})
export class AddUserComponent {

    @Input() users: adminUserModels.IHHCCUser[];
    @Input() servicelevels: adminUserModels.IHHCCMembership[];
    @Input() addons: adminUserModels.IHHCCMembership[];

    @Output() addcompleted = new EventEmitter();
    @Output() public onedit = new EventEmitter<adminUserModels.IHHCCUser>();
    @ViewChild('formUsername', { read: NgForm }) formUsername: any;
    @ViewChild('formNewUser', { read: NgForm }) formNewUser: any;
    showUsernameForm: boolean = true;
    showNewUserForm: boolean = false;
    notification: adminUserModels.INotification = {
        Show: false,
        Message: '',
        CssClass: ''
    };
    confirmEditUser: boolean = false;
    confirmEditText: string = '';
    editUser: adminUserModels.IHHCCUser;
    currentDate: Date = new Date();
    

    constructor(@Inject(AddUserService) private addUserService: AddUserService) {

    }

    ngOnInit() {
        
        if (!this.users) {
            this.users = [];
        }
        if (!this.servicelevels) {
            this.servicelevels = [];
        }
        if (!this.addons) {
            this.addons = [];
        }
    }

    cancel(): void {
        this.clearUsernameForm();
        this.clearNewUserForm();
        this.editUsername(true);
        this.notification.Show = false;
        this.confirmEditUser = false;
    }

    clearUsernameForm(): void {
        this.addUserService.clearUsername();
    }

    clearNewUserForm(): void {
        this.addUserService.clearNewUser();
    }

    validateUsername(): void {
        if (this.formUsername.invalid) {
            return;
        }

        this.notification.Show = false;

        //make sure the user isn't a member
        if (this.isUserInTable()) {
            this.confirmEditText = 'This user is already a member of your account. Would you like to update the member\'s personal and/or membership information?';
            this.confirmEditUser = true;
            return;
        }

        this.addUserService
            .getUserByUsername(this.addUserService.user.Username.toLowerCase().trim())
            .then(response => {
                let u: adminUserModels.IWebAuthUser | null = response;
                console.debug(u);

                if (u != null && u.Id > 0) {
                    if (u.Memberships.length > 0) {
                        this.notification = {
                            Show: true,
                            Message: 'This user name/email is already in use. Please call Customer Service toll-free at 800-650-6787.',
                            CssClass: "alert-danger"
                        };
                        console.debug("validateUsername: false");
                    }
                    else {
                        this.editUser = {
                            CustomerId: u.Customers.length > 0 ? u.Customers[0].Id : 0,
                            UserName: u.UserName,
                            Email: u.Email,
                            FirstName: u.Customers.length > 0 ? u.Customers[0].ProfileAddress.FirstName : '',
                            LastName: u.Customers.length > 0 ? u.Customers[0].ProfileAddress.LastName : '',
                            Company: u.Customers.length > 0 ? u.Customers[0].ProfileAddress.Company : '',
                            IsAdmin: u.IsAdmin,
                            IsLockedOut: u.IsLockedOut,
                            IsSelected: false,
                            ServiceLevel: ''
                        };

                        this.confirmEditText = "This user name/email is already in use but does not have any HHCC subscriptions. Would you like to add this user to your account?";
                        this.confirmEditUser = true;
                    }
                }
                else {
                    console.debug("validateUsername: true");
                    this.editUsername(false);
                }
            }).catch(response => {
                this.notification = {
                    Show: true,
                    Message: `An error has occurred while validating the username. Please try again or call Customer Service toll free at ${CUSTOMER_SERVICE_PHONE}.`,
                    CssClass: "alert-danger"
                };
                console.error(response);
            });
    }

    isUserInTable(): boolean {
        for (let user of this.users) {
            if (user.Email.toLowerCase().trim() == this.addUserService.user.Username.toLowerCase().trim()) {
                return true;
            }
        }
        return false;
    }

    editUsername(show: boolean): void {
        this.showUsernameForm = show;
        this.showNewUserForm = !show;
    }

    displayEditUser(): void {
        let editUsers = this.users.filter(user => user.UserName.toLowerCase().trim() == this.addUserService.user.Username.toLowerCase().trim());
        console.debug("filtered edit users: ", editUsers);

        if (editUsers.length > 0) {
            //hide confirm message
            this.confirmEditUser = false;

            console.debug('display edit user: ', editUsers[0]);
            this.onedit.emit(editUsers[0]);
        }
        else if (this.editUser) {
            this.confirmEditUser = false;

            console.debug('display edit user: ', this.editUser);
            this.onedit.emit(this.editUser);
        }
    }

    validateNewUser(): boolean {
        if (this.formNewUser.invalid) {
            return false;
        }

        this.notification.Show = false;
        let accountCreated: boolean = false;

        console.debug(this.addUserService.user);

        this.addUserService.addNewUser(this.addUserService.user)
            .then(response => {
                let userId: adminUserModels.INewUserId = response;
                if (userId != null && userId.CustomerId > 0 && userId.UserId > 0) {
                    //grant admin role to user
                    if (this.addUserService.user.IsAdmin) {
                        this.addUserService.grantRoleToUser(userId.UserId, "ADMIN");
                    }

                    //link service level membership to user
                    if (this.addUserService.user.ServiceLevelMembershipId > 0) {
                        for (let sl of this.servicelevels) {
                            if (sl.Membership.Id == this.addUserService.user.ServiceLevelMembershipId) {
                                this.addUserService.linkMembershipToCustomer(sl.Membership.CustomerId, sl.Membership.Id, userId.CustomerId);
                            }
                        }
                    }

                    //link add-on membership to user
                    for (let aoMembershipId of this.addUserService.user.AddOnMembershipIds) {
                        if (aoMembershipId > 0) {
                            for (let ao of this.addons) {
                                if (ao.Membership.Id == aoMembershipId) {
                                    this.addUserService.linkMembershipToCustomer(ao.Membership.CustomerId, ao.Membership.Id, userId.CustomerId);
                                }
                            }
                        }
                    }

                    //update status
                    accountCreated = true;
                }
                else {
                    this.notification = {
                        Show: true,
                        Message: `An error has occurred while creating the new user. Please try again or call Customer Service toll free at ${CUSTOMER_SERVICE_PHONE}.`,
                        CssClass: "alert-danger"
                    };
                    console.debug("No new Ids generated");
                    console.debug(userId);
                }
            }).catch(response => {
                this.notification = {
                    Show: true,
                    Message: `An error has occurred while creating the new user. Please try again or call Customer Service toll free at ${CUSTOMER_SERVICE_PHONE}.`,
                    CssClass: "alert-danger"
                };
                console.error(response);
            }).finally(() => {
                console.debug('Account created? ', accountCreated);
                if (accountCreated) {

                    //email user account credentials
                    this.addUserService.sendAccountCreatedEmail(this.addUserService.user);

                    //clear the fields and display the username lookup form
                    this.cancel();

                    //close the add new user modal
                    $("#modalTransaction").modal('hide');

                    //update user and membership tables from parent controller
                    this.addcompleted.emit();
                }
            });

        return accountCreated;
    }

    
}
