import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import * as adminUserModels from '../manage-users.models';
import { RemoveUsersService } from './remove-users.service';

import removeUsersTemplate from './remove-users.html';

@Component({
    selector: 'remove-users',
    template: removeUsersTemplate
})
export class RemoveUsersComponent {

    @Output() completed = new EventEmitter();
    @Output() cancelled = new EventEmitter();

    @Input() users: adminUserModels.IHHCCUser[];
    failedUsers: adminUserModels.IHHCCUser[] = [];


    constructor(@Inject(RemoveUsersService) private removeUsersService: RemoveUsersService) {

    }

    cancelTransaction(): void {
        this.cancelled.emit();
    }

    removeUsers(): void {
        //get list of users to remove
        let selectedUsers: adminUserModels.IHHCCUser[] = this.users.filter(user => user.IsSelected);
        let removeCount: number = selectedUsers.length;
        let failed: adminUserModels.IHHCCUser[] = [];

        //remove users and get list of users that cannot be removed
        //this.failedUsers = this.removeUsersService.removeUsers(selectedUsers);
        for (let user of selectedUsers) {
            console.debug('Removing user ' + user.UserName);
            this.removeUsersService.removeUser(user).then(result => {
                console.debug('User removed.');
            }).catch(result => {
                console.error('Remove user error: ', result);
            }).finally(() => {
                removeCount--;
                if (removeCount <= 0) {
                    //process the completed event from the parent
                    this.completed.emit();
                }
            });
        }
    }

}