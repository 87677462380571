import { Injectable, Inject } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { IClinicalGroupOption, IMStep } from './pdgm-calculator.models';


@Injectable({
    providedIn: 'root'
})
export class PdgmCalculatorService {

    constructor(@Inject(HttpClient) private http: HttpClient) {
    }

    GetFeeCalcSteps() {
      

        return this.http.get<IMStep[]>("/MVC/api/PdgmFeeCalc/GetMSteps");
    }

    GetClinicalGroupOptions() {
        return this.http.get<IClinicalGroupOption[]>("/MVC/api/PdgmFeeCalc/GetClinicalGroups");
    }
}