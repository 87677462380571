import { Injectable, Inject } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import * as models from "./article.models";

interface INewsletterService {
    getNewsletterDetails(newsId) : Promise<models.INewsletterArticle>;
}

@Injectable({
    providedIn: 'root'
})
export class NewsletterService implements INewsletterService {

    constructor(@Inject(HttpClient) private http: HttpClient) {
    }

    getNewsletterDetails(newsId): Promise<models.INewsletterArticle> {
        const parameters = { params: { newsId: newsId } };
        return this.http.get<models.INewsletterArticle>("/MVC/DCP/GetNewsletterDetails", parameters).toPromise();
    }

    getNewsletter(newsletterType: string, pageSize: number, pageIndex: number) {
        const parameters = {
            params: {
                type: newsletterType,
                pageSize: pageSize.toString(),
                pageIndex: pageIndex.toString()
            }
        };

        return this.http.get<models.IAjaxResultNewsletters>("/MVC/DCP/GetNewslettersByType", parameters).toPromise();
    }

}
