import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { VideoComponent } from "./video.component";
import { VideoWidgetComponent} from "./video-widget.component";
import { VideoService } from "./video.service";

@NgModule({
    imports: [
        CommonModule,
        FormsModule
    ],
    providers: [
        VideoService
    ],
    declarations: [
        VideoComponent,
        VideoWidgetComponent
    ]
})
export class VideoModule { }
