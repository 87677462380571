import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CoreModule } from '../core/core.module';
import { ForumModule } from '../forum/forum.module';

import { ArticleListComponent } from "./article-list.component";
import { ArticleSearchComponent } from "./article-search.component";
import { ArticleSearchResultsComponent } from "./article-search-results.component";
import { BreakingNewsComponent } from "./breaking-news.component";
import { LatestHeadlinesComponent } from "./latest-headlines.component";
import { NewsletterArticleComponent } from "./newsletter-article-component";
import { NewsletterComponent } from "./newsletter-component";
import { NewsletterService } from "./newsletter.service";
import { ArticleWidgetComponent } from './article-widget.component';
import { LatestIssueWidgetComponent } from './latest-issue-widget.component';
import { LatestIssueComponent } from './latest-issue.component';
import { DateRangePickerModule } from '../shared/directives/daterangepicker.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        CoreModule,
        ForumModule,
        DateRangePickerModule

    ],
    providers: [
        NewsletterService
    ],
    declarations: [
        ArticleListComponent,
        ArticleSearchComponent,
        ArticleSearchResultsComponent,
        BreakingNewsComponent,
        LatestHeadlinesComponent,
        NewsletterArticleComponent,
        NewsletterComponent,
        ArticleWidgetComponent,
        LatestIssueWidgetComponent,
        LatestIssueComponent
        ]
    
})
export class DcpModule { }


