import { Injectable, Inject } from '@angular/core';
import { HttpClient } from "@angular/common/http";

import { ICategory, ISubCategory } from './library.models';

@Injectable({
    providedIn: 'root'
})
export class LibraryService {
    constructor(@Inject(HttpClient) private http: HttpClient) {
    }

    url: string = "/MVC/Library/";

    getCategory(categoryId: string, productId: string) : Promise<ISubCategory[]> {

        const params = { params: { categoryId: categoryId, productId: productId, sortBy: "LibraryFileId" } };
        return this.http.get<ISubCategory[]>(this.url + "GetLibraryFiles", params).toPromise();
    }
}