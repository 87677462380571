import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { CoreModule } from '../core/core.module';

import { RecentCodesApiService } from "./recentCodesApi.service";
import { RecentCodesWidgetComponent } from "./recent-codes-widget.component";
import { RecentCodesComponent } from "./recent-codes.component";
import { RecentCodesSortOrderComponent } from "./recent-codes-sort-order.component";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        CoreModule
    ],
    providers: [
        RecentCodesApiService
    ],
    declarations: [
        RecentCodesWidgetComponent,
        RecentCodesComponent,
        RecentCodesSortOrderComponent

    ]
})
export class RecentCodesModule { }

//export default angular.module("codingCenterApp.recentCodes", [])
//    .directive("recentCodesWidget", downgradeComponent({ component: RecentCodesWidgetComponent }))
//    .directive("recentCodesSortOrder", downgradeComponent({ component: RecentCodesSortOrderComponent }))
//    .directive("recentCodes", downgradeComponent({ component: RecentCodesComponent }))
//    .factory("RecentCodesApiService", downgradeInjectable(RecentCodesApiService))
//    .name;
