import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

//import icd9CodeDetailsUrl from "./icd9CodeDetailsUrl.filter";
import {ICD9MappingModalComponent } from "./icd9-mapping-modal.component";
import { Icd9MappingService } from "./icd9Mapping.service";
import { ICD9MappingWidgetComponent } from "./icd9-mapping-widget.component";

@NgModule({
    imports: [
        CommonModule,
        FormsModule
    ],
    providers: [
        Icd9MappingService
    ],
    declarations: [
        ICD9MappingModalComponent,
        ICD9MappingWidgetComponent
    ]
})
export class Icd9Module { }

