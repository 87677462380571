import { Component, Inject, Input } from '@angular/core';

import { BookmarksService } from './bookmarks.service';
import { IBookmarkItem } from './bookmarks.models';

import bookmarksToggleTemplate from './bookmarks-toggle.html';

@Component({
    selector: 'bookmarks-toggle',
    template: bookmarksToggleTemplate
})
export class BookmarksToggleComponent {

    constructor(@Inject(BookmarksService) private bookmarksService: BookmarksService) {

    }

    @Input() tabindexvalue: string;
    @Input() contenttype: string;
    @Input() contentid: number;
    @Input() iconclass: string = "icon-bookmark"
    @Input() icondisabledclass: string = "icon-bookmark-disabled"
    ngOnInit() {
        this.getUserBookmarks();
    }

    getUserBookmarks() {
        this.bookmarksService.getUserBookmarks().then(response => {
            let data = response;
            this.bookmarksService.articlesBookmarks =   data.ArticlesBookmarks;
            this.bookmarksService.icd10CodeBookmarks = data.ICD10CodeBookmarks;
            this.bookmarksService.oasisCodeBookmarks = data.OASISBookmarks;
        })
    }

    isBookmarked() {
        switch (this.contenttype) {
            case "Article":
                for (var i = 0; i < this.bookmarksService.articlesBookmarks.length; i++) {
                    if (this.bookmarksService.articlesBookmarks[i].GenericContentId == this.contentid) {
                        return true;
                    }
                }
                break;
            case "ICD10":
                for (var i = 0; i < this.bookmarksService.icd10CodeBookmarks.length; i++) {
                    if (this.bookmarksService.icd10CodeBookmarks[i].GenericContentId == this.contentid) {
                        return true;
                    }
                }
                break;
            case "OASIS":
                for (var i = 0; i < this.bookmarksService.oasisCodeBookmarks.length; i++) {
                    if (this.bookmarksService.oasisCodeBookmarks[i].GenericContentId == this.contentid) {
                        return true;
                    }
                }
                break;
        }
        return false;
    }

    toggleBookmark(isBookmarked: Boolean) {
        this.bookmarksService.toggleBookmark(this.contenttype, this.contentid, isBookmarked).then(() => {
            this.getUserBookmarks();
        })
    }
}