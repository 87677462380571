import { Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { AdminUserLinkService } from "./adminUserLink.service";

import adminUserLinkTemplate from './admin-user-link.html';
import { BookmarksService } from '../bookmarks/bookmarks.service';

@Component({
    selector: '[admin-user-link]',
    template: adminUserLinkTemplate   
})
export class AdminUserLinkComponent {

    @Input() customerid: number;
    @Input() globalid: number;
    @Input() isowner: Boolean;
    @Input() isadmin: Boolean;
    @Input() hascph: Boolean;
    @Input() isexpert: Boolean;
    @Input() hasicd: Boolean;
    @Input() firstname: string;
    @Input() isauthenticated: Boolean;
    @Input() tabindexvalue: number;

    showLink: Boolean;
    @Input() showAdmin: Boolean;

   
    constructor(@Inject(AdminUserLinkService) private adminUserLinkService: AdminUserLinkService) {

    }

    ngOnInit() {
        if (this.customerid > 0) {
            this.adminUserLinkService.canManageUsers(this.customerid).then(response => {
                this.showLink = response > 0;
            }).catch(response => {
                this.showLink = false;
            });
        }
        else {
            this.showLink = false;
        }
    }
}