import { Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { BulletinsService } from './bulletins.service';
import { IBulletin } from './bulletins.models';

import bulletinsReadTemplate from './bulletins-read.html';


@Component({
    selector: 'bulletins-read',
    template: bulletinsReadTemplate
})
export class BulletinsReadComponent {

    @Input() isadmin: Boolean;
    @Input() bulletinid: number;
    @Input() tabindexvalue: string;

    public bulletinEditor = ClassicEditor;

    bulletins: IBulletin[];
    bulletinsOriginal: IBulletin[];
    bulletinId: number;

    bulletinSubject: string;
    bulletinBody: string = "";
    bulletinIsActive: boolean;
    bulletinPubDate: string;

    currentPageMinCount: number;
    currentPageMaxCount: number;
    paging: number[];

    defaultPageSize: number = 10;
    pageSize: number = this.defaultPageSize;
    pageIndex: number = 1;
    sortBy: string;
    pageMax: number;
    pageMin: number;
    startCount: number = 1;
    endCount: number;
    totalRecords: number;
    activeOnly: boolean;
    createEdit: string;

    constructor(@Inject(BulletinsService) private bulletinsService: BulletinsService) {
    }

    ngOnInit() {
        this.getBulletin(this.bulletinid);
    }


        getBulletin(bulletinId) {
            this.bulletinId = bulletinId;
            this.createEdit = "Edit";
            this.bulletinsService.getBulletin(bulletinId).then(response => {
                const data = response;
                this.bulletinSubject = data.Subject;
                this.bulletinBody = data.Body;
                this.bulletinIsActive = data.IsActive;
                this.bulletinPubDate = this.dateFormatter(data.PubDate);
            });
            
        }


        // Saves the bulletin
        saveBulletin() {
            this.bulletinsService.saveBulletin(this.bulletinId, this.bulletinSubject, this.bulletinBody, this.bulletinIsActive);
        }

        // Load the Bulletin editor with info from the selected Bulletin
        editBulletin(bulletinId) {
            this.bulletinId = bulletinId;
            this.createEdit = "Edit";

            this.bulletinsService.getBulletin(bulletinId).then(response => {
                const data = response;
                this.bulletinSubject = data.Subject;
                this.bulletinBody = data.Body;
                this.bulletinIsActive = data.IsActive;
            });
        }

        //Opens and loads compose window
        compose()  {
            this.createEdit = "Create";
            this.bulletinId = -1;
            this.bulletinSubject = "";
            this.bulletinBody = "";
            this.bulletinIsActive = true;
           
        }

        // Formats JSON dates correctly
        dateFormatter(dateVal) {
            const month: string[] = [];
            month[0] = "January";
            month[1] = "February";
            month[2] = "March";
            month[3] = "April";
            month[4] = "May";
            month[5] = "June";
            month[6] = "July";
            month[7] = "August";
            month[8] = "September";
            month[9] = "October";
            month[10] = "November";
            month[11] = "December";

            const d = new Date(parseInt(dateVal.replace(/\/Date\((.*?)\)\//gi, "$1"), 10));
            const returnDate = month[d.getMonth()] + " " + d.getDate() + ", " + d.getFullYear();
            return returnDate;
        }

        // Takes first 100 chars of html
        bodyBlurb(description) {
            const blurb = description !== null ? description.substring(0, 80) + "..." : "";
            return blurb;
        }


      
    }

