import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { GoogleChartsModule } from 'angular-google-charts';

import { UsageReportingChartsComponent } from "./usage-reporing-charts.component";
import { UsageReportingChartsService } from "./usage-reporting-charts.service";
import { MostViewedPagesChartComponent } from "./teamCharts/most-viewed-pages-chart.component";
import { MostSearchedTermsChartComponent } from "./teamCharts/most-searched-terms-chart.component";
import { MostSearchedCodesChartComponent } from "./teamCharts/most-searched-codes-chart.component";
import { UsersSummaryInfoChartComponent } from "./individualCharts/users-summary-info.component";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        GoogleChartsModule
    ],
    providers: [
        UsageReportingChartsService
    ],
    declarations: [
        UsageReportingChartsComponent,
        MostViewedPagesChartComponent,
        MostSearchedTermsChartComponent,
        MostSearchedCodesChartComponent,
        UsageReportingChartsComponent,
        UsersSummaryInfoChartComponent

    ]
})
export class UsageReportingModule { }


//export default angular.module("codingCenterApp.usageReportingCharts", [])
//    .directive("usageReportingCharts", downgradeComponent({ component: UsageReportingChartsComponent }))
//    .directive("mostViewedPagesChart", downgradeComponent({ component: MostViewedPagesChartComponent }))
//    .directive("mostSearchedTermsChart", downgradeComponent({ component: MostSearchedTermsChartComponent }))
//    .directive("mostSearchedCodesChart", downgradeComponent({ component: MostSearchedCodesChartComponent }))
//    .directive("usersSummaryInfoChart", downgradeComponent({ component: UsersSummaryInfoChartComponent }))
//    .factory("UsageReportingChartsService", downgradeInjectable(UsageReportingChartsService))
//    .name;