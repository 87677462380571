import { Injectable, Inject } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { IAjaxRequestRecentCode, IRecentCode } from "./recent-codes.models";
import { IAccountProfile } from "../profile/models";

@Injectable({
    providedIn: 'root'
})
export class RecentCodesApiService {
    constructor(@Inject(HttpClient) private http: HttpClient) {
    }

    loadingPromise: Promise<angular.IHttpResponse<any> | IRecentCode[]>;

    getRecentCodesAsync(parameters: IAjaxRequestRecentCode): Promise<angular.IHttpResponse<any> | IRecentCode[]> {
        if (this.loadingPromise) {
            return this.loadingPromise;
        }

        var params = {
            pageSize: parameters.pageSize.toString(),
            pageNum: parameters.pageNum.toString(),
            sortOrder: parameters.sortOrder.toString(),
            maxDescChars: parameters.maxDescChars.toString()

        }

        return this.http.get<IRecentCode[]>("/MVC/RecentCodes/GetUserRecentCodes", { params: params }).toPromise()
            .then(response => {
                this.loadingPromise = null;
                return response;
            });
    }

    getUserRole() {
        this.http.get<IAccountProfile>("/MVC/Account/GetLoggedInUserAsync").toPromise().then(
            response => {
                const data = response;
                if (data) {
                   // var currentUser = data;
                    var roles = [];
                    if (data.Memberships.length == 1)
                        roles = data.Memberships[0].Roles;
                    else
                        data.Memberships.forEach(function (membership, index) { roles = roles.concat(membership.Roles); })
                    if (roles) {
                        roles.forEach(function (role, index) {
                            if (role.RoleCode === this.userExpertRole) {
                                return true;
                            }
                        })
                    }
                }
                return false;
            });
    }
}