import { Component, Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, OperatorFunction } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, tap, switchMap } from 'rxjs/operators';

import { IRecentSearchTerm } from "./search-box.models";


@Injectable({
    providedIn: 'root'
})
export class SearchBoxService {
    constructor(@Inject(HttpClient) private http: HttpClient) {
    }

    saveToRecentSearchesAsync(searchTypeId, searchTerm, codeYear) {
        const data = { searchType: searchTypeId, searchTerm: searchTerm, year: codeYear };
        return this.http.post("/mvc/api/Search/AddRecentSearch", data).toPromise();
    }

    getAutocompleteAsync(remoteUrl, searchTerm, searchTypeId, year){
        const PARAMS = new HttpParams();
        //var params = {
        //    count: 10,
        //    prefixText: searchTerm,
        //    contextKey: "" + searchTypeId,
        //    year: year
        //};
        return this.http
            .get<[any, string[]]>(remoteUrl, { params: PARAMS.set('prefixText', searchTerm).set('contextKey', "" + searchTypeId).set('year', year).set('count', '10') }).pipe(
                map(response => response)
            );
       // return this.http.post<string[]>(remoteUrl, params).toPromise();
    }

    getUtcNow(): Promise<string> {
        return this.http.get<string>("/MVC/Home/GetUtcNow").toPromise();
    }

    getCount(searchTypeId, searchTerm, year) {
        var params = {
            searchTypeId: searchTypeId,
            searchTerm: searchTerm,
            year: year
        };
        return this.http.post<number>("/MVC/ICD10/GetSearchCount", params).toPromise();
    }

    getSearches() {
        return this.http.get<IRecentSearchTerm[]>("/mvc/api/Search/GetRecentSearches").toPromise();
    }
}
