import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { LibraryService } from './library.service';
import { LibraryComponent } from "./library.component";

@NgModule({
    imports: [
        CommonModule,
        FormsModule
    ],
    providers: [
        LibraryService
    ],
    exports: [
        LibraryComponent
    ],
    declarations: [
        LibraryComponent
    ]
})
export class LibraryModule { }

//export default angular.module("codingCenterApp.library", [])
//    .factory("LibraryService", downgradeInjectable(LibraryService))
//    .directive("library", downgradeComponent({ component: LibraryComponent }))
//    .name;
