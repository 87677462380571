///<reference path="../../../../../node_modules/@types/bootstrap/index.d.ts"/>
import { Component, EventEmitter, Inject, Input, ViewChild, Output } from '@angular/core';
import * as adminUserModels from '../manage-users.models';
import { EditUserService } from './edit-user.service';
import { FormsModule, ReactiveFormsModule, NgForm  } from '@angular/forms';

import editUserTemplate from './edit-user.html';

const CUSTOMER_SERVICE_PHONE = "800-650-6787";

@Component({
    selector: 'edit-user',
    template: editUserTemplate
})
export class EditUserComponent  {

   
    @Input() servicelevels: adminUserModels.IHHCCMembership[];
    @Input() addons: adminUserModels.IHHCCMembership[];
    @Input() sendexistsnotification: boolean;
    @Output() cancelled = new EventEmitter();
    @Output() completed = new EventEmitter();
    @ViewChild('formEditUser', { read: NgForm }) formEditUser: any;
    
    notification: adminUserModels.INotification = {
        Show: false,
        Message: '',
        CssClass: ''
    };
    currentDate: Date = new Date();

    constructor(@Inject(EditUserService) private editUserService: EditUserService) {

    }

    ngOnInit() {
        
                
        if (!this.servicelevels) {
            this.servicelevels = [];
        }
        if (!this.addons) {
            this.addons = [];
        }
    }

    cancel(): void {
        this.notification.Show = false;
        this.cancelled.emit();
        //close the edit user modal
        $('#modalTransaction').modal('hide');
    }

    validateEditUser(): void {
        if (this.formEditUser.invalid) {
            return;
        }

        this.notification.Show = false;

        this.editUserService.updateUser(this.editUserService.user).then(response => {
            let sendUserMembershipUpdateEmail: boolean = false;

            if ((this.editUserService.user.ServiceLevelMembershipId != this.editUserService.user.PreviousServiceLevel
                                                                    || this.editUserService.user.AddOnMembershipIds != this.editUserService.user.PreviousAddOns)
                                                                    && !this.sendexistsnotification) {
                sendUserMembershipUpdateEmail = true;
            }

            if (sendUserMembershipUpdateEmail) {
                this.editUserService.sendUserUpdateEmailNotification(this.editUserService.user.Email, this.editUserService.user.FirstName, this.editUserService.user.LastName, this.editUserService.user.CustomerId)
                    .then(response => { console.debug('message about updated memberships was sent successfully') })
                    .catch(response => { console.error('message about updated memberships was sent successfully') })

            }

            if (this.sendexistsnotification) {
                this.editUserService.sendExistingUserWelcomeEmail(this.editUserService.user.Username, this.editUserService.user.FirstName, this.editUserService.user.LastName).then(response => {
                    console.debug('message for existing user was sent successfully')
                }).catch(response => {
                    console.error("message for existing user wasn't sent");
                });
            }

            //close the edit user modal
            $('#modalTransaction').modal('hide');

            //update membership and user tables from the parent controller
            this.completed.emit();
        }).catch(response => {
            console.error('user updated unsucessfully: ', response);
            this.notification = {
                Show: true,
                Message: `An error has occurred while updating the user's account. Please try again or call Customer Service toll free at ${CUSTOMER_SERVICE_PHONE}.`,
                CssClass: "alert-danger"
            };
        });
    }

    disableServiceLevel(serviceLevel: adminUserModels.IHHCCMembership): boolean {
        if (!serviceLevel.IsActive) {
            return true;
        }

        if (serviceLevel.Membership.RemainingChildAccounts > 0) {
            return false;
        }

        //if there are no remaining child accounts, allow accounts that already has the add-on membership to uncheck the add-on option
        if (this.editUserService.user.PreviousServiceLevel == serviceLevel.Membership.Id) {
            return false;
        }

        return true;
    }

    disableAddOn(addOn: adminUserModels.IHHCCMembership): boolean {
        if (!addOn.IsActive) {
            return true;
        }

        if (addOn.Membership.RemainingChildAccounts > 0) {
            return false;
        }

        //if there are no remaining child accounts, allow accounts that already has the add-on membership to uncheck the add-on option
        let found: boolean = false;
        for (let ao of this.editUserService.user.PreviousAddOns) {
            if (ao == addOn.Membership.Id) {
                found = true;
            }
        }

        return !found;
    }

    public setFlag(obj: any, property: any, trueValue: any, falseValue: any): any {
        if (obj[property] === trueValue) {
            obj[property] = falseValue;
        } else {
            obj[property] = trueValue;
        }
    }
}
