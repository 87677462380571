import { Injectable, Inject } from '@angular/core';
import { HttpClient } from "@angular/common/http";

export interface ISiteContentData {
    BucketName: string;
    Content1: string;
    ProductId: number;
    ProductName: string;
    SiteContentBucketTypeId: number;
}


@Injectable({
    providedIn: 'root'
})
export class SiteContentService {
    constructor(@Inject(HttpClient) private http: HttpClient) {
    }

    content: ISiteContentData;
    getContent(): ISiteContentData{
        return this.content;
    }

    loadContentBucket() {
        return this.http.get<ISiteContentData>("/MVC/SiteContent/LoadContent").toPromise();
    }
}
