import { Component, Inject, Input, OnInit } from '@angular/core';
import { IContentItem } from "../content/content.models";

import { ContentService } from "../content/content.service";


import videoWidgetTemplate from './video-widget.html';

@Component({
    selector: 'video-widget',
    template: videoWidgetTemplate
})
export class VideoWidgetComponent {

    constructor(@Inject(ContentService) private contentService: ContentService) {
    }

    @Input() tabindexvalue: number;
    @Input() isexpert: Boolean;

    @Input() contenttypeid: number;
    @Input() codetypeid: number;
    @Input() code: string;

    // props
    videosLoaded: Boolean;
    allVideos: any = [];
    shownVideos: any = [];
    pagedCount: number = 0;
    initialCount: number = 3;
    totalCount: number=0;

    ngOnInit() {
        this.loadVideos(false);
    }

    loadVideos(showAll: Boolean) {
        if (showAll) {
            this.pagedCount = this.totalCount;
        }
        else {
            this.pagedCount = this.initialCount;
        }
        if (this.allVideos.length < 1) {
            this.videosLoaded = false;

            this.contentService.loadContent(this.contenttypeid, this.codetypeid, this.code).then(data => {
                this.allVideos = data;
                this.totalCount = data.length;

                // Only show max count of records
                if (this.totalCount > this.initialCount) {
                    this.shownVideos = this.allVideos.slice(0, this.pagedCount);
                }
                else {
                    this.shownVideos = this.allVideos;
                }

                this.videosLoaded = true;
            });
        }
        else {
            // Only show max count of records
            if (this.totalCount > this.initialCount) {
                this.shownVideos = this.allVideos.slice(0, this.pagedCount);
            }
            else {
                this.shownVideos = this.allVideos;
            }
        }
    }

    

    // Displays an HTML blurb of 300 characters
    bodyBlurb(description: string): string {
        return description.substring(0, 300);
    }

    // Formats JSON dates correctly
    dateFormatter(dateVal: string, format): string {
        const month: string[] = [];
        month[0] = "January";
        month[1] = "February";
        month[2] = "March";
        month[3] = "April";
        month[4] = "May";
        month[5] = "June";
        month[6] = "July";
        month[7] = "August";
        month[8] = "September";
        month[9] = "October";
        month[10] = "November";
        month[11] = "December";

        const d = new Date(parseInt(dateVal.replace(/\/Date\((.*?)\)\//gi, "$1"), 10));

        if (format === "full") {
            return month[d.getMonth()] + " " + d.getDate() + ", " + d.getFullYear();
        } else {
            return (d.getMonth() + 1) + "/" + d.getDate() + "/" + d.getFullYear();
        }
    }
}