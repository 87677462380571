import { Component, Inject, Input } from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { UserNotesService } from './userNotes.service'
import userNotesTemplate from './user-notes.html';

@Component({
    selector: 'user-notes',
    template: userNotesTemplate
})
export class UserNotesComponent {

    constructor(@Inject(UserNotesService) public userNotesService: UserNotesService) { }

    @Input() itemid: number;
    @Input() itemtype: string;
    @Input() isadmin: Boolean;

    public userEditor = ClassicEditor;
    public groupEditor = ClassicEditor;

    origUserNote: string = "";
    origGroupNote: string = "";
    groupNoteContent: string = "";
    userNoteContent: string = "";
    disabled: Boolean;
    noteDetails: any;


    // Loads the user and group notes for the MItem
    ngOnInit() {

        const urlUser = "/MVC/UserNotes/GetByCodeAndUser";
        const urlGroup = "/MVC/UserNotes/GetByCodeAndAgreement";

        let iItemType = 0;

        switch (this.itemtype) {
            case "MItem":
                iItemType = 15;
                break;
            case "ICD10":
                iItemType = 13;
                break;
        }

        this.userNotesService.getByCodeAndUser(this.itemid, iItemType).then((response) => {
                const data = response;
                if (data) {
                    this.origUserNote = data.UserNote;
                    this.userNoteContent = data.UserNote;
                }
            },
            (response) => {
                console.error(response);
            });

        this.userNotesService.getByCodeAndAgreement(this.itemid, iItemType).then((response) => {
            const dataTwo = response;

            if (dataTwo) {
                    this.origGroupNote = dataTwo.UserNote;
                    this.groupNoteContent = dataTwo.UserNote;
                }
            },
            (response) => {
                console.error(response);
            });

        this.disabled = false;
    }

    isContentLoaded(isGroupNote) {
        const content = isGroupNote
            ? this.groupNoteContent
            : this.userNoteContent;

        return content && content !== "Enter your note";
    }

    // Saves the note
    saveItemUserNote = function (itemId, itemType, isGroupNote) {
        
        let iItemType = 0;
        iItemType = this.getCodeTypeIdByItemType(itemType);

        let encodedContent;

        if (isGroupNote) {
            this.groupNoteContent = this.removeBorderingTags(this.groupNoteContent);
            encodedContent = encodeURIComponent(this.groupNoteContent);
        } else {
            this.userNoteContent = this.removeBorderingTags(this.userNoteContent);
            encodedContent = encodeURIComponent(this.userNoteContent);
        }

        this.userNotesService.saveUserNote(itemId, iItemType, isGroupNote, encodedContent).then((response) => {
                let editLink;
                if (isGroupNote) {
                    this.origGroupNote = this.groupNoteContent;
                    editLink = $("#editGroupNote");
                }
                else {
                    this.origUserNote = this.userNoteContent;
                    editLink = $("#editUserNote");
                }
                editLink.hide()
                    .parent()
                    .children(".editLink")
                    .show();
            },
            (response) => {
                console.error(response);
            });
    }

    // Cancels the edit and returns existing value
    cancelUserNote(isGroupNote) {
        let editLink;
        if (isGroupNote) {
            this.groupNoteContent = this.origGroupNote;
            editLink = $("#editGroupNote");
            editLink.hide();
            editLink.parent().children(".editLink").show();
        }
        else {
            this.userNoteContent = this.origUserNote;
            editLink = $("#editUserNote");
            editLink.hide();
            editLink.parent().children(".editLink").show();
        }
    }

    populateNoteDetails(noteId, noteType, isGroupNote) {
        this.noteDetails = {
            noteId: noteId,
            noteType: noteType,
            isGroupNote:isGroupNote
        }
    }

    //Option for deleting loockback Notes for OASIS
    deleteUserNotes()
    {     
        let codeByType = this.getCodeTypeIdByItemType(this.noteDetails.noteType);

        this.userNotesService.deleteUserNotes(this.noteDetails, codeByType).then((response) => {
            if (this.noteDetails.noteType == "MItem") {
                if (this.noteDetails.isGroupNote) {
                    this.groupNoteContent = '';
                    return;
                }
                this.userNoteContent = '';
            }
        }).catch((response) => {
            console.error("Note wasn't deleted: " + response);
        })
    }

    // Since the textAngular wraps note content in p tags, we need to remove them
    removeBorderingTags(field) {
        let newField = field;
        if (field !== null && field !== "") {
            const pattern = new RegExp("^<p>.*</p>$");
            if (pattern.test(field)) {
                newField = newField.substring(3, newField.length - 4);
            }
        }
        return newField;
    }

    getCodeTypeIdByItemType(itemType) {
        switch (itemType) {
            case "MItem":
                return 15;
            case "ICD10":
                return 13;
        }
    }
}