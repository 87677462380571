import { Component, Inject, Input, OnInit } from '@angular/core';
import { MessagesService } from './messages.service';
import { } from './messages.models';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import messagesTemplate from './messages.html';

@Component({
    selector: 'messages',
    template: messagesTemplate
})
export class MessagesComponent {

    @Input() tabindexvalue: number;
    // Item List Arrays
    messages = [];
    messagesOriginal = [];

    constDefaultPageSize: number = 25;
    pageSize = this.constDefaultPageSize;
    pageIndex = 1;
    activeOnly = true;
    messageSubject: string;
    messageCategory: string;
    messageBody: string;

    public messageEditor = ClassicEditor;
   
    constructor(@Inject(MessagesService) private messagesService: MessagesService) {
    }

    //Load all the messages
    ngOnInit() {
      
        this.getMessages();
    }
    getMessages() {
        this.messagesService.getMessages(this.activeOnly, this.pageSize, this.pageIndex).then(response => {
            const data = response;
            console.log('messages', data);
            this.messages = data.MessageList;
        });
    }
    // Formats JSON dates correctly
    dateFormatter(dateVal: string) {
        if (dateVal === undefined) {
            return "";
        }
        const d = new Date(parseInt(dateVal.replace(/\/Date\((.*?)\)\//gi, "$1"), 10));
        const returnDate = (d.getMonth() + 1) + "/" + d.getDate() + "/" + d.getFullYear();
        return returnDate;
    }

    // Takes the first 100 characters of a string and returns as html
    bodyBlurb(description) {
        const blurb = description.substring(0, 100);
        return blurb;
    }

    // Save a new message
    createMessage() {

        console.log('messageBody', this.messageBody);

        this.messagesService.saveMessage(this.messageSubject, this.messageBody, this.messageCategory).then(response => {
            this.getMessages();
        })
    }

}