import { Injectable, Inject } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import * as models from "./models";

@Injectable({
    providedIn: 'root'
})
export class ProfileService {
    constructor(@Inject(HttpClient) private http: HttpClient) {
    }

    getLoggedInUser() {
        return this.http.get<models.IAccountProfile>("/MVC/Account/GetLoggedInUserAsync").toPromise();
    }

    updateUser(currentUser: models.IAccountProfile) {
        return this.http.post<any>("/MVC/Account/UpdateUser", {
            User: {
                Id: currentUser.User.Id,
                FirstName: currentUser.User.FirstName,
                LastName: currentUser.User.LastName,
                Email: currentUser.User.Email
            },
            Address: {
                Company: currentUser.Address.Company
            }
        }).toPromise();
    }

    sendEmail(currentUser: models.IAccountProfile) {
        return this.http.get<boolean>("/MVC/Account/SendAccountChangedEmail", {
            params: {
                email: currentUser.User.Email,
                firstName: currentUser.User.FirstName,
                lastName: currentUser.User.LastName,
                username: currentUser.User.Username,
                company: currentUser.Address.Company,
                customerId: currentUser.User.Customers[0].Id.toString()
            }
        }).toPromise();
    }
}