import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class LoginService {
    constructor(@Inject(HttpClient) private http: HttpClient) {
    }

    logUserIn(userName, password, rememberMe) {
        
        const base64Credential: string = btoa(userName + ':' + password);
        const headers = { 'Authorization': 'Basic ' + base64Credential, 'Content-Type': 'application/json' };

        return this.http.post<any>("/mvc/api/Account/Login", JSON.stringify(rememberMe), { headers: headers }).toPromise().then(result => {
            return result;
        }, result => {
            console.error("loginService-login", result);
            return result;
        });
    }

    acceptedEula(userName, rememberMe) {
        
        const parameters = {
            params: {
                username: userName,
                isPersistent: rememberMe ? String(rememberMe) : "false"
            }
        };
        return this.http.get<any>("/mvc/api/Account/AcceptedEula", parameters).toPromise().then(result => {
            return result;
        }, result => {
            console.error("loginService-acceptedEula", result);
            return result;
        });
    }

}
