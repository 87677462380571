import {
    Directive,
    ElementRef,
    HostBinding,
    Input,
    OnChanges,
    SecurityContext,
    SimpleChanges, Inject, Injector
} from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

@Directive({
    selector: "[highlight]"
})
export class HighlightDirective implements OnChanges {
    @Input("highlight") searchTerm: string;
    @Input() caseSensitive = false;
    @Input() customClasses = "";
    @Input() wholeTerm = "";

    @HostBinding("innerHtml")
    content: string;
    constructor(@Inject(ElementRef) private el: ElementRef, @Inject(DomSanitizer) private sanitizer: DomSanitizer) { }

    ngOnChanges(changes: SimpleChanges) {
        if (this.el && this.el.nativeElement) {
            if ("searchTerm" in changes || "caseSensitive" in changes || 'wholeTerm' in changes) {
                const text = this.wholeTerm;
                if (this.searchTerm === "") {
                    this.content = text;
                } else {

                    const terms = this.searchTerm.split(" ");
                    var newText = text;
                    for (let i = 0; i < terms.length; i++) {
                        var word = terms[i];
                        if (word !== "") {
                            //make sure special characters that are used in regular expressions are escaped as literal characters in the word
                            word = word
                                .replace("\\", "\\\\")
                                .replace("$", "\\$")
                                .replace(".", "\\.")
                                .replace("|", "\\|")
                                .replace("?", "\\?")
                                .replace("*", "\\*")
                                .replace("+", "\\+")
                                .replace("(", "\\(")
                                .replace(")", "\\)")
                                .replace("[", "\\[")
                                .replace("]", "\\]")
                                .replace("{", "\\{")
                                .replace("}", "\\}");
                            var regWord = `((?!<mark class="highlight ${this.customClasses}">)(?!</mark>)`;
                            regWord=regWord.concat(word,")");
                            var regex = new RegExp(
                                regWord,
                                this.caseSensitive ? "g" : "gi"
                            );
                            newText = newText.replace(regex, (match: string) => {
                                return `<mark class="highlight ${this.customClasses}">${match}</mark>`;
                            });
                        }
                    }
                    const sanitzed = this.sanitizer.sanitize(
                        SecurityContext.HTML,
                        newText
                    );
                    this.content = sanitzed;
                }
            }
        }
    }
}