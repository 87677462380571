import { Component, Inject, Input, OnInit } from '@angular/core';
import { LibraryService } from './library.service';
import { ICategory, ISubCategory } from './library.models';
import libraryTemplate from './library.html';


@Component({
    selector: 'library',
    template: libraryTemplate
})
export class LibraryComponent implements OnInit {

    @Input() categoryid: string;

    categoryId: string;
    categoryList: ICategory[];
    categoryName: string;
    loading: boolean = false;
    predicate: { [id: string]: string } = {};
    productId: number = 1;
    reverse: { [id: string]: boolean } = {};
    subCategories: ISubCategory[] = [];

    constructor(@Inject(LibraryService) private libraryService: LibraryService) {

    }

    

    // Load the library files for the given categoryId
    ngOnInit() {
        const self = this;
        if (this.categoryid) {

            this.loading = true;
            this.libraryService.getCategory(this.categoryid, this.productId.toString()).then(response => {
                
                const data = response;
                this.categoryName = data[0].categoryName;
                this.subCategories = data;

                for (let i = 0; i < this.subCategories.length; i++) {
                    const subCat = this.subCategories[i];
                    this.predicate[subCat.categoryName] = "Year";
                    this.reverse[subCat.categoryName] = true;
                }

                this.loading = false;
            });

        }
    }

    sortBy(libraryFiles: any, prop: string) {
        return libraryFiles.sort((a, b) => a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1);
    }

    // Sort the search results
    orderFiles(subCat: string, predicate: string) {
        this.reverse[subCat] = (this.predicate[subCat] === predicate) ? !this.reverse[subCat] : false;
        this.predicate[subCat] = predicate;
    }
}
