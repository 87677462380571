import { Injectable, Inject } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import * as adminUserModels from '../manage-users.models';

export interface IEditUserService {
    updateUser(user: adminUserModels.IEditUser);
}

@Injectable({
    providedIn: 'root'
})
export class EditUserService implements IEditUserService {
    constructor(@Inject(HttpClient) private http: HttpClient) {
    }

    user: adminUserModels.IEditUser = {
        Username: '',
        FirstName: '',
        LastName: '',
        Company: '',
        IsAdmin: false,
        ServiceLevelMembershipId: null,
        AddOnMembershipIds: [],
        CustomerId: -1,
        Email: '',
        PreviousServiceLevel: null,
        PreviousAddOns: []
    };

    clearUser(): void {
        this.user = {
            Username: '',
            FirstName: '',
            LastName: '',
            Company: '',
            IsAdmin: false,
            ServiceLevelMembershipId: null,
            AddOnMembershipIds: [],
            CustomerId: -1,
            Email: '',
            PreviousServiceLevel: null,
            PreviousAddOns: []
        };
    }

    setUser(accountMember: adminUserModels.IAccountMember, serviceLevelMembershipId: number, addOnMembershipIds: number[]): void {
        this.user = {
            Username: accountMember.UserName,
            FirstName: accountMember.FirstName,
            LastName: accountMember.LastName,
            Company: accountMember.Company,
            IsAdmin: accountMember.IsAdmin,
            Email: accountMember.Email,
            CustomerId: accountMember.CustomerId,
            ServiceLevelMembershipId: serviceLevelMembershipId,
            PreviousServiceLevel: serviceLevelMembershipId,
            AddOnMembershipIds: addOnMembershipIds.slice(),
            PreviousAddOns: addOnMembershipIds.slice()
        };
    }

    updateUser(user: adminUserModels.IEditUser) {
        var addOns: adminUserModels.IAddOn[] = [];
        for (let ao of user.AddOnMembershipIds) {
            let addOn: adminUserModels.IAddOn = {
                MembershipId: ao
            };
            addOns.push(addOn);
        }

        let updateUserModel: any = {
            CustomerId: user.CustomerId,
            FirstName: user.FirstName,
            LastName: user.LastName,
            Company: user.Company,
            IsAdmin: user.IsAdmin,
            ServiceLevel: user.ServiceLevelMembershipId,
            AddOns: addOns
        };

        return this.http.put("/MVC/ManageUsers/UpdateUser", updateUserModel, { responseType: 'text'}).toPromise();
    }

    sendExistingUserWelcomeEmail(username: string, firstName: string, lastName: string) {
        return this.http.get("/MVC/ManageUsers/SendExistingUserWelcomeEmail", { responseType: 'text', params: { username: username, firstName: firstName, lastName: lastName } }).toPromise();
    }

    sendUserUpdateEmailNotification(email: string, firstName: string, lastName: string, customerId: number) {
        return this.http.get("/MVC/ManageUsers/SendUserUpdateNotification", { responseType: 'text', params: { email: email, firstname: firstName, lastname: lastName, customerId: customerId.toString() } }).toPromise();
    }

}