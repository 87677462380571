import { Component, Inject, Input, OnInit } from '@angular/core';
import { IArticle, INewsletterArticle  } from './article.models';
import { ArticleService } from './article.service';
import { NewsletterService } from './newsletter.service';
import latestIssueTemplate from './latest-issue.html';
import { IAjaxResultArticles } from './article.models';

@Component({
    selector: 'latest-issue',
    template: latestIssueTemplate
})
export class LatestIssueComponent {

    // props
    @Input() tabindexvalue: number;
    @Input() newsletterid: number;

    articlesLoaded: Boolean;

    articles: IArticle[];
    totalCount: number;
    newsletterName: string;

    constructor(@Inject(ArticleService) private articleService: ArticleService) {
    }

    ngOnInit() {
        this.articlesLoaded = false;
        this.getArticles(1);

    }

    getArticles(attemptNum: number) {
        this.articleService.getArticles(this.newsletterid, 50).then(response => {
            const data = response;
            if (!data.ArticleList || data.ArticleList.length === 0) {
                if (attemptNum < 4) {
                    this.getArticles(++attemptNum);
                } else {
                    this.articlesLoaded = true;
                }
            } else {
                this.articles = data.ArticleList;
                this.totalCount = data.TotalCount;
                this.articlesLoaded = true;
            }
        });
    }

    // Formats JSON dates correctly
    dateFormatter(dateVal: string): string {
        const month: string[] = [];
        month[0] = "January";
        month[1] = "February";
        month[2] = "March";
        month[3] = "April";
        month[4] = "May";
        month[5] = "June";
        month[6] = "July";
        month[7] = "August";
        month[8] = "September";
        month[9] = "October";
        month[10] = "November";
        month[11] = "December";

        const d = new Date(parseInt(dateVal.replace(/\/Date\((.*?)\)\//gi, "$1"), 10));

        const returnDate = month[d.getMonth()] + " " + d.getDate() + ", " + d.getFullYear();
        return returnDate;
    };
};
