import { Pipe, PipeTransform } from '@angular/core';
import { IHHCCUser } from './manage-users.models';

@Pipe({
    name: 'filterUsers'
})
export class FilterUsersPipe implements PipeTransform {

    filterColumns: string[] = ["FirstName", "LastName", "Email", "ServiceLevel", "Company","Username"]

    transform(users: Array<IHHCCUser>, searchTerm: string): Array<IHHCCUser> {
        if (searchTerm) {
            let filteredUsers: IHHCCUser[] = [];
            let hasTerm = false;
            users.forEach(user => {
                for (let key of Object.keys(user)) {
                    if (this.filterColumns.includes(key) && user[key].toString().toLowerCase().includes(searchTerm.toLowerCase())) {
                        filteredUsers.push(user);
                        break;
                    }
                }
                
            });
            return filteredUsers;
        }
        else {
            return users;
        }

    }
}
