import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

//import UserNotesController from "./userNotesController";
import { UserNotesLookbackComponent } from "./user-notes-lookback.component";
import { UserNotesModalComponent } from "./user-notes-modal-component";
import { UserNotesService } from "./userNotes.service";
import { UserNotesComponent } from "./user-notes.component";
import { FilterNotesPipe } from "./filter-notes.pipe";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        CKEditorModule
    ],
    providers: [
        UserNotesService
    ],
    declarations: [
        UserNotesLookbackComponent,
        UserNotesModalComponent,
        UserNotesComponent,
        FilterNotesPipe
    ]
})
export class UserNotesModule { }

