import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';


import pagerTemplate from './pager.html';

@Component({
    selector: 'pager',
    template: pagerTemplate
})
export class PagerComponent {

    @Output() onPageChange = new EventEmitter<number>();
    @Input() page: number;
    @Input() pagecount: number;

    // public props
    className: string;

    // internal props
    private pages: number[] = [];

    ngOnInit() {
        if (!this.className) {
            this.className = "pagination";
        }

        this.pages = [];
        for (let i = 0; i < this.pagecount; i++) {
            this.pages.push(i + 1);
        }
    }

    change(page: number) {
        this.onPageChange.emit(page);
    }
    nextPage() {
        if (this.page < this.pagecount) {
            this.change(this.page + 1);
        }
    }
    prevPage() {
        if (this.page > 1) {
            this.change(this.page - 1);
        }
    }
}
