import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';


//import * as angular from "angular";
//import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';

import { ContentService } from "./content.service";
import { InteractiveScenariosSearchComponent } from "./inter-scenarios-search.component";

@NgModule({
    imports: [
        CommonModule,
        FormsModule
    ],
    providers: [
        ContentService
    ],
    declarations: [
        InteractiveScenariosSearchComponent
    ]
})
export class ContentModule { }

//export default angular.module("codingCenterApp.content", [])
//    .factory("ContentService", downgradeInjectable(ContentService))
//    .directive("interactiveScenariosSearch", downgradeComponent({ component: InteractiveScenariosSearchComponent }))
//    .name;
