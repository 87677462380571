import { Component, Inject, Input, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { Icd9MappingService } from './icd9Mapping.service';
import icd9MappingModalTemplate from './icd9-mapping-modal.html';

@Component({
    selector: 'icd9-mapping-modal',
    template: icd9MappingModalTemplate
})
export class ICD9MappingModalComponent {

    showFullCategory: Boolean;
    showRelatedCodes: Boolean;
    showNoCrosswalks: Boolean;

    mappingPageSize: number = 15;
    mappingPageIndex: number = 1;
    mappingPageMin: number = 1;
    mappingPageMax: number;
    totalItems: number = 0;
    firstItem: number;
    lastItem: number;

    exactCrosswalks: any;
    approxCrosswalks: any;
    comboCrosswalks: any;
    relatedCodes: any;

    exactDisplay: any;
    approxDisplay: any;
    comboDisplay: any;
    comboItems: any;
    scenarioCombo: any;
    mappingPaging: any;

    comboCount: number;
    icd9Code: string;

    noResults: Boolean = false;

    crosswalksSub: Subscription;

    constructor(@Inject(Icd9MappingService) private icd9MappingService: Icd9MappingService) {
        this.crosswalksSub = this.icd9MappingService.crosswalksLoaded.subscribe(() => {
            this.updateValues();
        })
    }

    ngOnInit() {
        
        $(".fullCategory").hide();
        $(".relatedCodesSection").hide();
    }

    updateValues() {
        this.exactCrosswalks = this.icd9MappingService.getExactCrosswalks();
        this.approxCrosswalks = this.icd9MappingService.getApproxCrosswalks();
        this.comboCrosswalks = this.icd9MappingService.getComboCrosswalks();
        this.relatedCodes = this.icd9MappingService.getRelatedCodes();
        this.comboCount = this.icd9MappingService.getComboCount();
        this.icd9Code = this.icd9MappingService.getPCode();

        // Calculate the total items and number of pages
        this.totalItems = this.exactCrosswalks.length + this.approxCrosswalks.length + this.comboCount;
        this.mappingPageMax = Math.floor(this.totalItems / this.mappingPageSize);
        const mod = this.totalItems % this.mappingPageSize;
        if (mod > 0) {
            this.mappingPageMax++;
        }

        if (this.totalItems > 0) {
            this.noResults = false;
            this.displayCrosswalks(1);
        }
        else {
            this.noResults = true;
        }
    };

    // Display the crosswalk info on page
    displayCrosswalks (pageIndex) {
        // We don't want to see related codes section on initial load or page change
        this.showRelatedCodes = false;

        this.firstItem = ((pageIndex - 1) * this.mappingPageSize) + 1;
        this.lastItem = this.firstItem + this.mappingPageSize;

        let itemsRemaining = this.mappingPageSize;

        // Display the exact crosswalks section
        if (this.exactCrosswalks !== null && this.exactCrosswalks.length > 0 && this.firstItem <= this.exactCrosswalks.length) {
            var startIndex = this.firstItem - 1;
            var endIndex = this.lastItem;

            if (this.lastItem > this.exactCrosswalks.length) {
                endIndex = this.exactCrosswalks.length;
            }
            else {
                this.approxDisplay = [];
                this.comboDisplay = [];
                itemsRemaining = 0;
            }

            this.exactDisplay = this.exactCrosswalks.slice(startIndex, endIndex);
            itemsRemaining = itemsRemaining - this.exactDisplay.length;
        }
        else {
            this.exactDisplay = [];
        }

        // Display the approximate crosswalks section
        if (this.approxCrosswalks !== null && this.approxCrosswalks.length > 0 && itemsRemaining > 0 && this.firstItem <= (this.exactCrosswalks.length + this.approxCrosswalks.length)) {
            startIndex = 0;

            // If we don't have any exact displayed, we might not be on the first page
            if (this.exactDisplay.length === 0) {
                startIndex = this.firstItem - this.exactCrosswalks.length - 1;
            }

            endIndex = startIndex + itemsRemaining + 1;

            if (this.lastItem > this.exactCrosswalks.length + this.approxCrosswalks.length) {
                endIndex = this.approxCrosswalks.length;
            }
            else {
                this.comboDisplay = [];
                itemsRemaining = 0;
            }

            this.approxDisplay = this.approxCrosswalks.slice(startIndex, endIndex);
            itemsRemaining = itemsRemaining - (this.exactDisplay.length + this.approxDisplay.length);
        }
        else {
            this.approxDisplay = [];
        }

        // Display the combination crosswalks section
        if (this.comboCrosswalks !== null && this.comboCrosswalks.length > 0 && itemsRemaining > 0
            && this.firstItem <= (this.exactCrosswalks.length + this.approxCrosswalks.length + this.comboCount)) {
            startIndex = 0;

            // If we don't have any exact or approximate displayed, we might not be on the first page
            if (this.exactDisplay.length === 0 && this.approxDisplay.length === 0) {
                startIndex = this.firstItem - (this.exactCrosswalks.length + this.approxCrosswalks.length) - 1;
            }

            endIndex = startIndex + itemsRemaining + 1;

            if (this.lastItem > this.comboCount) {
                endIndex = this.comboCount;
            }

            this.comboItems = [];

            let k = 0;

            for (var i = 0; i < this.comboCrosswalks.length; i++) {
                this.scenarioCombo = [];
                for (let j = 0; j < this.comboCrosswalks[i].length; j++) {
                    if (k >= startIndex && k < endIndex) {
                        this.scenarioCombo.push(this.comboCrosswalks[i][j]);
                    }
                    k++;
                }
                if (this.scenarioCombo.length > 0) {
                    this.comboItems.push(this.scenarioCombo);
                }
            }

            this.comboDisplay = this.comboItems;
        }
        else {
            this.comboDisplay = [];
        }

        //setup paging links
        this.mappingPageIndex = pageIndex;
        const mod = this.mappingPageIndex % 4;
        switch (mod) {
            case 1:
                this.mappingPaging = [this.mappingPageIndex, (this.mappingPageIndex + 1), (this.mappingPageIndex + 2), (this.mappingPageIndex + 3)];
                break;
            case 2:
                this.mappingPaging = [(this.mappingPageIndex - 1), this.mappingPageIndex, (this.mappingPageIndex + 1), (this.mappingPageIndex + 2)];
                break;
            case 3:
                this.mappingPaging = [(this.mappingPageIndex - 2), (this.mappingPageIndex - 1), this.mappingPageIndex, (this.mappingPageIndex + 1)];
                break;
            case 0:
                this.mappingPaging = [(this.mappingPageIndex - 3), (this.mappingPageIndex - 2), (this.mappingPageIndex - 1), (this.mappingPageIndex)];
                break;
        }
        for (let i = 3; i > 0; i--) {
            if (this.mappingPaging[i] > this.mappingPageMax) {
                this.mappingPaging = this.mappingPaging.slice(0, i);
            }
        }

        if (this.exactDisplay.length > 0 || this.approxDisplay.length > 0 || this.comboDisplay.length > 0) {
            setTimeout(() => {
                this.initRelatedCodes();
            }, 500);
        }
        else {
            setTimeout(() => {
                this.showNoCrosswalks = true;
                $("#noi9crosswalks").show();
            }, 500);
        }
    };

    crosswalkMouseover(thisTR, code) {
      

       // let code = $(this).find(".fullCategory").attr("code");

        code = code.replace(".", "");

        $(".current").removeClass("current");
        $("#code" + code).addClass("current");

        const baseCode = code.substring(0, 3);
        let parentCodeClass = "parentCode" + code.substring(0, code.length - 1);

        $(".relatedCodesSection").hide();
        $("table.icd10crosswalks tr").css("background-color", "");
        $(".comboCode").css("background-color", "");

        $(".relatedCodesSection[code='" + baseCode + "']").show();
        $(".relatedChild").hide();
        $(".parentCode").show();

        //13 because 'parentCode' + 3 letters for code before the '.'  
        while (parentCodeClass.length >= 13) {
            $("." + parentCodeClass).show();
            parentCodeClass = parentCodeClass.substring(0, parentCodeClass.length - 1);
        }

        $(this).css("background-color", "#fee5a0");
    }

    // Function for initializing the related codes section of the I9 code mapper once it has been set from the mappingicd9codecontroller controller
    initRelatedCodes() {

        //this.showFullCategory = false;
        $(".fullCategory").hide();

        // Display related codes on mouseover for exact/approx sections
        $("table.icd10crosswalks tr").mouseenter(function () {

            let code = $(this).find(".fullCategory").attr("code");

            code = code.replace(".", "");

            $(".current").removeClass("current");
            $("#code" + code).addClass("current");

            const baseCode = code.substring(0, 3);
            let parentCodeClass = "parentCode" + code.substring(0, code.length - 1);

            $(".relatedCodesSection").hide();
            $("table.icd10crosswalks tr").css("background-color", "");
            $(".comboCode").css("background-color", "");

            $(".relatedCodesSection[code='" + baseCode + "']").show();
            $(".relatedChild").hide();
            $(".parentCode").show();

            //13 because 'parentCode' + 3 letters for code before the '.'  
            while (parentCodeClass.length >= 13) {
                $("." + parentCodeClass).show();
                parentCodeClass = parentCodeClass.substring(0, parentCodeClass.length - 1);
            }

            $(this).css("background-color", "#fee5a0");
        });

        // Display related codes on mouseover for combination sections 
        $(".comboCode").mouseenter(function () {

            let code = $(this).find(".fullCategory").attr("code");

            code = code.replace(".", "");

            $(".current").removeClass("current");
            $("#code" + code).addClass("current");

            const baseCode = code.substring(0, 3);
            let parentCodeClass = "parentCode" + code.substring(0, code.length - 1);

            $(".relatedCodesSection").hide();
            $("table.icd10crosswalks tr").css("background-color", "");
            $(".comboCode").css("background-color", "");

            $(".relatedCodesSection[code='" + baseCode + "']").show();
            $(".relatedChild").hide();
            $(".parentCode").show();

            //13 because 'parentCode' + 3 letters for code before the '.'  
            while (parentCodeClass.length >= 13) {
                $("." + parentCodeClass).show();
                parentCodeClass = parentCodeClass.substring(0, parentCodeClass.length - 1);
            }

            $(this).css("background-color", "#fee5a0");
        });

        if ($("table.icd10crosswalks tr").length > 0) {
            $("table.icd10crosswalks tr").first().mouseenter();
        }
        else if ($(".comboCode").length > 0) {
            $(".comboCode").first().mouseenter();
        }
    }

    relatedChildClass(rc) {
        var theCode = rc.Code;
        var codeAfter = "";

        if (theCode.indexOf(".") > -1) {
            codeAfter = theCode.substring(theCode.indexOf(".") + 1);
        }

        const level = "level" + codeAfter.length;
        const codeDigits = theCode.replace(".", "");
        const parentCode = "parentCode" + (codeAfter.length === 0 ? "" : codeDigits.substring(0, codeDigits.length - 1));
        return "relatedChild " + level + " " + parentCode;
    }

    relatedChildId(rc) {
        var theCode = rc.Code;
        var codeAfter = "";

        if (theCode.indexOf(".") > -1) {
            codeAfter = theCode.substring(theCode.indexOf(".") + 1);
        }
        const codeDigits = theCode.replace(".", "");
        return "code" + (codeAfter.length === 0 ? "" : codeDigits);
    }


}
