import { Component, Inject, Input, OnInit } from '@angular/core';
import { ArticleService } from "./article.service";

import { DOCUMENT } from '@angular/common';
import * as models from "./article.models";
import articleSearchResultsTemplate from './article-search-results.html';

@Component({
    selector: 'article-search-results',
    template: articleSearchResultsTemplate
})
export class ArticleSearchResultsComponent {
    @Input() tabindexvalue: number;
    @Input() articlesection: string;
    @Input() searchkeywords: string;

    defaultPageSize: number;
    referrer: string;
    pageSize: number;
    articleSearchResults: models.IArticleSearchResult[];
    startDate: Date;
    pageIndex: number;
    currentPageMinCount: number;
    currentPageMaxCount: number;
    pageMax: number;
    pageMin: number;
    paging: number[];
    totalRecords: number;
    sortBy: string;
    resultsLoaded: boolean;
    filterStartDate;
    filterEndDate;

    


    constructor(@Inject(ArticleService) private articleService: ArticleService, @Inject(DOCUMENT) public document: Document) {
    }

    ngOnInit() {
        this.referrer = document.referrer;

        // declare constants
        this.defaultPageSize = 25;
        this.pageSize = this.defaultPageSize;

        this.pageIndex = 1;
        this.pageMin = 1;
        this.totalRecords = -1;
        this.sortBy = "RANK";

        this.resultsLoaded = false;
        //Date of ICD - 10 implementation
        this.startDate = new Date(2015, 9, 1);
      

        this.runSearch(this.pageSize, 1, '', '');
    }

 

    //performs the search
    runSearch(pgSize: number, pgIndex: number, startDate, endDate, sortBy?: string) {
        if (sortBy) {
            this.sortBy = sortBy;
        }
        this.resultsLoaded = false;

        this.pageSize = pgSize; //if page size equals -1, return all results
        this.filterStartDate = startDate;
        this.filterEndDate = endDate;

        //set the page index
        if (pgIndex < this.pageMin || this.pageSize === -1) {
            this.pageIndex = this.pageMin;
        } else if (this.pageMax) {
            this.pageIndex = (pgIndex > this.pageMax && this.pageMax > 0) ? this.pageMax : pgIndex;
        } else {
            this.pageIndex = pgIndex;
        }
        this.articleService.searchArticles(this.articlesection, this.searchkeywords, this.pageSize, this.pageIndex, this.sortBy,
            startDate, endDate).then(results => {
                const data = results;
                this.articleSearchResults = data.Results;

                //find total number of records
                if (this.articleSearchResults.length > 0) {
                    this.totalRecords = data.TotalSearchResultCount;
                } else {
                    this.totalRecords = 0;
                }

                //get the current record number view
                if (this.pageSize === -1) {
                    this.currentPageMinCount = this.pageMin;
                    this.currentPageMaxCount = this.totalRecords;
                } else {
                    this.currentPageMinCount = this.pageIndex * this.pageSize - (this.pageSize - 1);
                    this.currentPageMaxCount = this.pageIndex * this.pageSize;
                    if (this.currentPageMaxCount > this.totalRecords) {
                        this.currentPageMaxCount = this.totalRecords;
                    }
                }

                //setup paging links
                const mod = this.pageIndex % 4;
                switch (mod) {
                    case 1:
                        this.paging = [this.pageIndex, (this.pageIndex + 1), (this.pageIndex + 2), (this.pageIndex + 3)];
                        break;
                    case 2:
                        this.paging = [(this.pageIndex - 1), this.pageIndex, (this.pageIndex + 1), (this.pageIndex + 2)];
                        break;
                    case 3:
                        this.paging = [(this.pageIndex - 2), (this.pageIndex - 1), this.pageIndex, (this.pageIndex + 1)];
                        break;
                    case 0:
                        this.paging = [(this.pageIndex - 3), (this.pageIndex - 2), (this.pageIndex - 1), (this.pageIndex)];
                        break;
                }
                if (this.pageSize === -1) {
                    this.pageMax = 1;
                } else {
                    this.pageMax = Math.floor(this.totalRecords / this.pageSize);
                    const mod2 = this.totalRecords % this.pageSize;
                    if (mod2 > 0) {
                        this.pageMax++;
                    }
                }
                for (let i = 3; i > 0; i--) {
                    if (this.paging[i] > this.pageMax) {
                        this.paging = this.paging.slice(0, i);
                    }
                }
                this.resultsLoaded = true;
            });

    }

    // Formats JSON dates correctly
    dateFormatter(dateVal: string): string {
        const d = new Date(parseInt(dateVal.replace(/\/Date\((.*?)\)\//gi, "$1"), 10));
        const returnDate = (d.getMonth() + 1) + "/" + d.getDate() + "/" + d.getFullYear();
        return returnDate;
    };
    dateApplied(picker) {
        this.runSearch(this.pageSize, 1, picker.startDate.format('MM/DD/YYYY'), picker.endDate.format('MM/DD/YYYY'));
    }
};