import { Component, Inject, Input, OnInit } from '@angular/core';
//import { GoogleChartInterface, GoogleChartComponent } from 'ng2-google-charts';

import { UsageReportingChartsService } from "../usage-reporting-charts.service";
import { IMostSearchedCodesModel } from "../usage-reporting-charts.models"

import template from '../chartsTemplate.html';

@Component({
    selector: 'most-searched-codes-chart',
    template: template
})
export class MostSearchedCodesChartComponent {
    //chart: GoogleChartInterface = {
    //    chartType: 'Table',
    //    options: {}
    //};
    chart: any = {};

    chartData: IMostSearchedCodesModel[];
    chartDataRows: any[];
    totalRecords: number = 0;
    searchQuery: string = "";

    isLoaded: boolean = false;

    constructor(@Inject(UsageReportingChartsService) private userReportingChartsService: UsageReportingChartsService) {

    }

    ngOnInit() {
       
        this.getMostSearchedCodes();
    };

    search() {
        this.chart.data = this.userReportingChartsService.search(this.chartDataRows, this.searchQuery);
        this.totalRecords = this.chart.data.count;
        this.isLoaded = true;
    }

    getMostSearchedCodes() {
        this.userReportingChartsService.getMostSearchedCodes().then(response => {
            this.chartData = response ? response : [];
            if (this.chartData) {
                this.chart.type = "Table";
                this.chart.columnNames = [{ label: "Total", type: "number" }, { label: "Code", type: "string" }];

                this.chart.data = [];
                var self = this;
                this.chartData.forEach(function (item, index) {
                    var chartItem =  [{ v: item.Total }, { v: item.Code }] ;
                    self.chart.data.push(chartItem);
                })
                this.chart.options = this.userReportingChartsService.getChartDefaultOptions();
                this.chartDataRows = this.chart.data;
                this.totalRecords = this.chart.data.length;
                this.isLoaded = true;
            }
        }).catch(error => {
            console.error(error);
        });
    }
}

