import { Component, Inject, Input, OnInit } from '@angular/core';

import ArticleWidgetTemplate from './article-widget.html';

@Component({
    selector: 'article-widget',
    template: ArticleWidgetTemplate
})
export class ArticleWidgetComponent {

    @Input() tabindexvalue: number;
    @Input() viewarticleurl: string;
    @Input() viewallurl: string;
    @Input() articletype: string;
    @Input() articlesubtype: string;
    @Input() paneltitle: string;
    @Input() count: number;
    @Input() type: string;

    constructor() {
       
    }
    ngOnInit() {
       
    }
    }