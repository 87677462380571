import { Component, Inject, Input, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ICD10SearchService} from './icd10-search.service';
import { ICodeIconFlag, IIndexSearchResult, ITabularSearchResult } from './icd10.models';
import icd10SearchSlideTemplate from './icd10-search-slide.html';
import { Icd10SearchComponent } from './icd10-search.component';




@Component({
    selector: 'icd10-search-slide',
    template: icd10SearchSlideTemplate
})
export class Icd10SearchSlideComponent extends Icd10SearchComponent {

    constructor(@Inject(ICD10SearchService) public icd10SearchService: ICD10SearchService, @Inject(DOCUMENT) public document: Document) {
        super(icd10SearchService, document);
    }

    @Input() includeresults: Boolean;
    

    ngOnInit() {
        this.icd10SearchService.searchtype = this.searchtype;
        this.icd10SearchService.keywords = this.keywords;
        this.icd10SearchService.year = this.year;
        this.getSessionSearchResults(this.year);
    }

}
