import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { DictionaryAppendicesComponent } from "./dictionary-appendices.component";
import { DictionarySearchComponent } from "./dictionary-search.component";
import { DictionaryService } from './dictionary.service';
import { DictionaryDetailComponent } from "./dictionary-detail.component";
import { DictionaryResultsComponent } from "./dictionary-results.component";
import { HighlightModule } from '../shared/directives/highlight.module';



@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        HighlightModule
    ],
    providers: [
        DictionaryService
    ],
    declarations: [
        DictionaryAppendicesComponent,
        DictionarySearchComponent,
        DictionaryDetailComponent,
        DictionaryResultsComponent

    ]
})
export class DictionaryModule { }