import { Component, Inject, Input } from '@angular/core';
import { TrainingService } from './training.service';

import trainingTemplate from './training.html';

@Component({
    selector: 'training',
    template: trainingTemplate
})
export class TrainingComponent {
    @Input() isdcp: Boolean=false;
    @Input() trainingtype: number;
    @Input() count: number=99;
    @Input() tabindexvalue: string;
    trainingOpps: any = []=[];
    totalCount: any = 0;
    trainingLoaded: Boolean;
    constructor(@Inject(TrainingService)private trainingService: TrainingService) {

    }

    // Initialize all training opportunities
    ngOnInit() {
        this.loadTraining();
    }

    loadTraining() {
        this.trainingLoaded = false;
        if (this.trainingtype==2) {
            this.trainingService.getWebinarsOpps(this.count ).then(response => {
                const webops = response;
                this.trainingOpps = webops.Events;
                this.totalCount = webops.TotalCount;
                this.trainingLoaded = true;
            },
                response => {
                    this.trainingLoaded = false;
                    console.error(response);
                });
        }
        else if (this.trainingtype == 1)
        {
            this.trainingService.getTrainingOpps(this.count).then(response => {
                const data = response;
                this.trainingOpps = data.Events;
                this.totalCount = data.TotalCount;
                this.trainingLoaded = true;
            },
                response => {
                    this.trainingLoaded = false;
                    console.error(response);
                });
        }
    }
}