import { Component, Inject, Input } from '@angular/core';
import trainingWidgetTemplate from './training-widget.html';

@Component({
    selector: 'training-panel',
    template: trainingWidgetTemplate
})
export class TrainingWidgetComponent {

    constructor() {

    }

    @Input() isdcp: Boolean = false;
    @Input() trainingtype: number;
    @Input() count: number = 99;
    @Input() tabindexvalue: string;
    @Input() title: string;

    ngOnInit() {
    }
}