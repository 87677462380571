import { Component, Inject, Input, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ICD10SearchService} from './icd10-search.service';
import { IAltSearchSuggestion, ICodeIconFlag, IIndexSearchResult, ITabularSearchResult, IAjaxResultI10Search } from './icd10.models';
import icd10SearchTemplate from './icd10-search.html';
import { version } from 'punycode';


const INDEX_SEARCH_TYPE_ID: number = 8;

@Component({
    selector: 'icd10-search',
    template: icd10SearchTemplate
})
export class Icd10SearchComponent {

    @Input() searchtype: number;
    @Input() searchtypestring: string;
    @Input() keywords: string;
    @Input() year: string;
    @Input() searchtypes: string;

    altLoading: boolean = false;
    altSearchGroups: any[];
    altSearchResults: IAltSearchSuggestion[];
    btnNextPageShow: boolean;
    btnPrevPageShow: boolean;
    chunkRecSize: number = 25; // number of records contained in a chunk of search results
    chunkTimeoutMillisecs: number = 1000; // number of milliseconds to wait in between displaying chunks of search results
    codeIconFlags: ICodeIconFlag[];
    codeSearchResult: ITabularSearchResult[] = [];
    codeSearchResultChunked: any[] = []; // chunked version of code results so we can deal with huge data sizes
    currentPageMaxCount: number;
    currentPageMinCount: number;
    defaultPageSize: number = 25;
    endCount: number;
    indexChildData: { [id: string]: IIndexSearchResult[] } = {};
    indexParentData: { [id: string]: IIndexSearchResult[] } = {};
    indexSearchResult: IIndexSearchResult[] = [];
    indexSearchResultChunked: any[] = []; // chunked version of index results so we can deal with huge data sizes
    indexTables: any = {};
    isTermSearch: boolean=false;
    pageIndex: number = 1;
    pageMax: number;
    pageMin: number = 1;
    pageSize: number = 25;
    paging: number[];
  //  referrer: string = document.referrer;
    resultCount: number;
    resultsLoading: boolean = false;
    searchTerm: string;
   // searchType: number;
    sortBy: string;
    startCount: number = 1;
    totalRecords: number = -1;



    constructor(@Inject(ICD10SearchService) public icd10SearchService: ICD10SearchService, @Inject(DOCUMENT) public document: Document) {
         
    }

    ngOnInit() {
        this.pageSize = this.defaultPageSize;
        this.sortBy = "RANK";


        this.icd10SearchService.searchtype = this.searchtype;
        this.icd10SearchService.keywords = this.keywords;
        this.icd10SearchService.year = this.year;
        this.endCount = this.defaultPageSize;
        this.search(25, 1, this.sortBy );
    }

    //performs the ICD-10 search
    search(pgSize: number, pgIndex: number, sortBy?: string) {
        if (sortBy) {
            this.sortBy = sortBy;
        }
        
        // Show the loading wheel in case pagination takes extra time
        this.resultsLoading = true;

        // Clear chunked results to make way for new search
        this.codeSearchResultChunked = [];
        this.indexSearchResultChunked = [];

        this.pageSize = pgSize; //if page size equals -1, return all results

        //set the page index
        if (pgIndex < this.pageMin || this.pageSize === -1) {
            this.pageIndex = this.pageMin;
        }
        else if (this.pageMax) {
            this.pageIndex = (pgIndex > this.pageMax) ? this.pageMax : pgIndex;
        }
        else {
            this.pageIndex = pgIndex;
        }
        
        //set sort order
        if (this.sortBy === "TERM" || this.sortBy === "RANK") {

            this.icd10SearchService.doSearch(this.searchtype, this.keywords, this.pageSize, this.pageIndex, this.sortBy, this.year).then(response => {

                const data = response;

                this.codeSearchResult = (data.CodeSearchResult === null ? [] : data.CodeSearchResult);
                this.indexSearchResult = (data.IndexSearchResult === null ? [] : data.IndexSearchResult);
                this.codeIconFlags = data.CodeIconFlags;
                this.isTermSearch = data.IsTermSearch;

                // If only one tabular result returned, go directly to details page
                if (this.codeSearchResult.length === 1 && this.pageIndex === 1) {
                    const singleCode = this.codeSearchResult[0].Code;
                    document.location.href = "/MVC/ICD10/Details/" + singleCode + "/" + (this.year ? this.year : "");
                    return;
                }
                else {

                    //find total number of records
                    if (this.codeSearchResult.length > 0) {
                        this.totalRecords = this.codeSearchResult[0].TotalRowCount;

                    }
                    else if (this.indexSearchResult.length > 0) {
                        this.totalRecords = this.indexSearchResult[0].TotalRowCount;
                    }
                    else {
                        this.totalRecords = 0;
                    }

                    this.resultsLoading = false;

                    //get the current record number view
                    if (this.pageSize === -1) {
                        this.currentPageMinCount = this.pageMin;
                        this.currentPageMaxCount = this.totalRecords;
                    }
                    else {
                        this.currentPageMinCount = this.pageIndex * this.pageSize - (this.pageSize - 1);
                        this.currentPageMaxCount = this.pageIndex * this.pageSize;
                        if (this.currentPageMaxCount > this.totalRecords) {
                            this.currentPageMaxCount = this.totalRecords;
                        }
                    }

                    //setup paging links
                    const mod = this.pageIndex % 4;
                    switch (mod) {
                        case 1:
                            this.paging = [this.pageIndex, (this.pageIndex + 1), (this.pageIndex + 2), (this.pageIndex + 3)];
                            break;
                        case 2:
                            this.paging = [(this.pageIndex - 1), this.pageIndex, (this.pageIndex + 1), (this.pageIndex + 2)];
                            break;
                        case 3:
                            this.paging = [(this.pageIndex - 2), (this.pageIndex - 1), this.pageIndex, (this.pageIndex + 1)];
                            break;
                        case 0:
                            this.paging = [(this.pageIndex - 3), (this.pageIndex - 2), (this.pageIndex - 1), (this.pageIndex)];
                            break;
                    }
                    if (this.pageSize === -1) {
                        this.pageMax = 1;
                    }
                    else {
                        this.pageMax = Math.floor(this.totalRecords / this.pageSize);
                        const mod2 = this.totalRecords % this.pageSize;
                        if (mod2 > 0) {
                            this.pageMax++;
                        }
                    }
                    for (let i = 3; i > 0; i--) {
                        if (this.paging[i] > this.pageMax) {
                            this.paging = this.paging.slice(0, i);
                        }
                    }

                    //get alternative search results
                    if (this.totalRecords === 0) {
                        this.icd10SearchService.getAltResults(this.searchtype, this.keywords).then(
                            (response) => {
                                const data = response
                                this.altLoading = false;

                                this.altSearchResults = data.Results;

                                this.altSearchGroups = [];
                                this.altSearchResults.forEach((value: IAltSearchSuggestion, key: number) => {
                                    let typeExists = false;
                                    this.altSearchGroups.forEach((value2: any, key2: number) => {
                                        if (value.TermType === value2.TermType) {
                                            typeExists = true;
                                        }
                                    });
                                    if (!typeExists) {
                                        this.altSearchGroups.push({ CodeType: value.CodeTypeId, TermType: value.TermType });
                                    }
                                });

                                this.altSearchGroups.sort(function (a, b) {
                                    if (a.CodeType > b.CodeType) {
                                        return -1;
                                    }
                                    else if (a.CodeType === b.CodeType) {
                                        return 0;
                                    }
                                    else {
                                        return 1;
                                    }
                                });
                            },
                            response => {
                                console.error(response);
                                this.altLoading = false;
                            });
                    }
                }
            });
        }
        else {
            this.resultsLoading = false;
        }
    }


    // BC 8/5/2015 - serves up code search results in chunks to combat latency in browser performance for huge data sets
    chunkCodeSearchResults(start: number) {
        const self = this;

        // figure out start of next chunk
        let new_start = start + this.chunkRecSize;
        if (new_start > this.codeSearchResult.length)
            new_start = this.codeSearchResult.length;

            for (let index = start; index < new_start; ++index) {
                this.codeSearchResultChunked.push(this.codeSearchResult[index]);
            }

        // process next chunk if we have recs left to display
        if (new_start < this.codeSearchResult.length) {
            setTimeout(() => {
                this.chunkCodeSearchResults(new_start);
            }, this.chunkTimeoutMillisecs);
        }
    }

    // BC 8/5/2015 - serves up index search results in chunks to combat latency in browser performance for huge data sets
    chunkIndexSearchResults(start: number) {
        // figure out start of next chunk
        let new_start = start + this.chunkRecSize;
        if (new_start > this.indexSearchResult.length)
            new_start = this.indexSearchResult.length;

        // Get angular scope

        for (let index = start; index < new_start; ++index) {
            this.indexSearchResultChunked.push(this.indexSearchResult[index]);
        }

        // process next chunk if we have recs left to display
        if (new_start < this.indexSearchResult.length) {
            setTimeout(() => {
                this.chunkIndexSearchResults(new_start);
            }, this.chunkTimeoutMillisecs);
        }

    }

    //get parent data of the ICD-10 search term
    getParentData(indexTermId: number, indexVersionId: number) {
        if (!Object.prototype.hasOwnProperty.call(this.indexParentData, indexTermId.toString())) {

            this.icd10SearchService.getIndexParentData(indexTermId, indexVersionId, this.year).then(
                response => {
                    const data = response;
                    //exclude the parent record if one result is returned and the search is the following types: Alpha Index and Index to External Causes
                    if (data.length === 1 && (this.searchtype === 8 || this.searchtype === 9)) {
                        this.indexParentData[indexTermId.toString()] = [];
                    }
                    else {
                        this.indexParentData[indexTermId.toString()] = data;
                    }
                });
        }
    }

    //get child term data for a ICD-10 term
    getChildData(indexTermId: number, indexVersionId: number) {
        var self = this;
        if (!Object.prototype.hasOwnProperty.call(this.indexChildData, indexTermId.toString())) {
            this.icd10SearchService.getIndexChildData(indexTermId, indexVersionId).then(response => {
                self.indexChildData[indexTermId.toString()] = response;
            })
        }
    }

    //load parent data into index table
    loadIndexTableParent(indexTermId: number, versionId: number) {
        if (!Object.prototype.hasOwnProperty.call(this.indexTables, indexTermId)) {
            this.icd10SearchService.getIndexTermParents(indexTermId, versionId).then(
                response => {
                    const parents = response;
                    for (let i = 0; i < parents.length; i++) {
                        parents[i].Depth = i;
                        parents[i].ShowNotes = false;
                        parents[i].IsChildrenDownloaded = true;
                        parents[i].ShowChildren = true;
                        parents[i].ShowRow = [true];
                    }
                    this.indexTables[indexTermId] = parents;

                   this.icd10SearchService.getIndexTermChildren(indexTermId, versionId).then(
                        response => {
                            const children = response;
                            const lastParent = parents[parents.length - 1];

                            for (let i = 0; i < children.length; i++) {
                                children[i].Depth = lastParent.Depth + 1;
                                children[i].ShowNotes = false;
                                children[i].IsChildrenDownloaded = false;
                                children[i].ShowChildren = false;
                                children[i].ShowRow = [true, true];
                            }
                            this.indexTables[indexTermId] = parents.concat(children);
                        });
                });
        }
    }

    //load child data into index table
    loadIndexTableChildren(indexTermId: number, parentDataRow: any, parentIndex: number) {
        var self = this;
        parentDataRow.ShowChildren = !parentDataRow.ShowChildren;

        if (!parentDataRow.IsChildrenDownloaded) {
            this.icd10SearchService.getIndexTermChildren(parentDataRow.ICD10IndexTermId, parentDataRow.ICD10IndexVersionId).then(
                function (response) {
                    const children = response;
                    for (let i = 0; i < children.length; i++) {
                        children[i].Depth = parentDataRow.Depth + 1;
                        children[i].ShowNotes = false;
                        children[i].IsChildrenDownloaded = false;
                        children[i].ShowChildren = false;
                        children[i].ShowRow = parentDataRow.ShowRow.slice();
                        children[i].ShowRow.push(parentDataRow.ShowChildren);
                    }
                    const args = [parentIndex + 1, 0].concat(children);
                    Array.prototype.splice.apply(self.indexTables[indexTermId], args);
                    parentDataRow.IsChildrenDownloaded = true;
                });
        }
        else {
            //update expand/collapse settings for the child records
            for (let i = parentIndex + 1; i < this.indexTables[indexTermId].length; i++) {
                if (this.indexTables[indexTermId][i].Depth > parentDataRow.Depth) {
                    this.indexTables[indexTermId][i].ShowRow[parentDataRow.Depth] = parentDataRow.ShowChildren;
                }
                else {
                    break;
                }
            }
        }
    }
   

    //builds the links to the code details page and builds the code icons in HTML format
    // mvl 5/18/2015 - Added optional includeResult param
    createCodeSearchLinks(codeString: string, codeIconFlags: ICodeIconFlag[], includeResults: boolean): string {
        //codeString can be formatted as "code1" or "code1 [code2]", so BuildICD10CodeSearchLinkForIndexSearchResult parse the codes to display the individual link and icons
        const html = this.buildICD10CodeSearchLinkForIndexSearchResult(codeString, codeIconFlags, includeResults);
        return html; //without $sce.trustAsHtml(), html will be rendered in plain text
    };

    //builds the links to the term or code search
    createIndexSearchLinks(text: string, refCodeSearch: any): string {
         return this.icd10SearchService.createIndexSearchLinks(text, refCodeSearch);
    }

    //highlight the searched keywords in a string
    highlightKeywords(text: string): string {
        let html = text;

        if (this.isTermSearch && html) {
            html = this.highlightText(html, this.keywords);
        }

        return html; //without $sce.trustAsHtml(), html will be rendered in plain text
    }

    //builds the links for the alternative search results
    buildSearchSuggestionLinks(searchType: string, term: string, index: number): string {
        let html = "";
        if (index > 0) {
            html += " &ndash; ";
        }

        switch (searchType.toString()) {
            // ICD-10 searches  
            case "7":
            case "8":
            case "9":
            case "10":
            case "11":
                html += " <a href='/MVC/ICD10/Search?cst=" + searchType + "&sk=" + encodeURIComponent(term) + "'>" + term + "</a> ";
                break;
            // OASIS searches
            case "20":
                html += " <a href='/MVC/OASIS/Search/Index?searchTerm=" + encodeURIComponent(term) + "'>" + term + "</a> ";
                break;
            // ICD-9 searches  
            default:
                html += " <a href='/MVC/ICD9/Search?cst=" + searchType + "&sk=" + encodeURIComponent(term) + "'>" + term + "</a> ";
                break;
        }
        return html;
    };

    // Retrieves the search results from the session
    getSessionSearchResults(year: string) {
        
        if (year) {
            this.year = year;
        }

        this.icd10SearchService.getSessionSearchResults().then(
            (response) => {
                const data = response;
                if (data && data.IndexSearchResult && data.IndexSearchResult.length > 0) {

                    //Save information for future searches
                    this.searchTerm = data.IndexSearchTerm;
                    this.resultCount = data.IndexSearchResult[0].TotalRowCount;
                    this.sortBy = data.SortBy;
                    this.pageIndex = data.PageIndex;
                    this.startCount = 1 + ((data.PageIndex - 1) * this.defaultPageSize);
                    this.endCount = this.startCount + (this.defaultPageSize - 1);

                   // Check if the end of the result set has been reached
                    if (this.endCount >= this.resultCount) {
                        this.endCount = this.resultCount;
                        this.btnNextPageShow = false;
                    }
                    else {
                        this.btnNextPageShow = true;
                    }

                    //Hide the Previous button on initial return
                    this.btnPrevPageShow = this.pageIndex !== 1;
                    this.indexSearchResult = data.IndexSearchResult;
                }
                else {
                    this.resultCount = 0;
                    $("#slide-panel").hide();
                }
            });
    }

    // Move to the next page of saved search results
    nextSearchPage() {

        // Advance the page count, if at last page, hide next button
        this.startCount = this.startCount + this.defaultPageSize;
        if (this.endCount + this.defaultPageSize >= this.resultCount) {
            this.endCount = this.resultCount;
            this.btnNextPageShow = false;
        }
        else {
            this.endCount = this.endCount + this.defaultPageSize;
        }

        this.pageIndex = this.pageIndex + 1;

        this.btnPrevPageShow = true;

        // Re-run the search
        this.search(this.defaultPageSize, this.pageIndex, this.sortBy);
    }

    //Move to the previous search results page
    previousSearchPage() {

        // Decrease counts, remove previous button if on first page
        this.startCount = this.startCount - this.defaultPageSize;
        this.endCount = this.endCount - (this.endCount%this.defaultPageSize || this.defaultPageSize);
        this.pageIndex = this.pageIndex - 1;

        this.btnNextPageShow = true;
        this.btnPrevPageShow = !(this.startCount === 1);

        this.search(this.pageSize, this.pageIndex, this.sortBy);

    }

    //build the ICD-10 search link
    createICD10SearchLink(searchType: number, searchTerm: string, linkText: string): string {
        return this.icd10SearchService.createICD10SearchLink(searchType, searchTerm, linkText);
    }

    //highlight words in text
    highlightText(text: string, termString: string): string {
        return this.icd10SearchService.highlightText(text, termString);
    }

    buildICD10CodeSearchLinkForIndexSearchResult(codeString: string, codeIconFlags: ICodeIconFlag[], includeResults: boolean): string {
        return this.icd10SearchService.buildICD10CodeSearchLinkForIndexSearchResult(codeString, codeIconFlags, includeResults);
        
    }

    //build the ICD-10 code details link
    createICD10CodeDetailsLink(code: string, includeResults: boolean): string {

        // strip trailing period from code link
        let codeStripped = code;
        if (code.charAt(code.length - 1) === ".")
            codeStripped = codeStripped.substring(0, codeStripped.length - 1);

        const year = this.year;

        let link = "<a href='/MVC/ICD10/Details/" + encodeURIComponent(codeStripped) + (year ? "/" + year : "") + "?searchTerm=" + this.keywords;
        if (includeResults) {
            link = link + "&includeResults=true";
        }

        link = link + "'>" + code + "</a>";

        return link;
    }

    ajaxIcd10SearchSuccess(response: angular.IHttpResponse<IAjaxResultI10Search>) {
        const self = this;
        const data = response.data;
        this.codeSearchResult = (data.CodeSearchResult === null ? [] : data.CodeSearchResult);
        this.indexSearchResult = (data.IndexSearchResult === null ? [] : data.IndexSearchResult);
        this.codeIconFlags = data.CodeIconFlags;
        this.isTermSearch = data.IsTermSearch;

        // If only one tabular result returned, go directly to details page
        if (this.codeSearchResult.length === 1 && this.pageIndex === 1) {
            const singleCode = this.codeSearchResult[0].Code;
            this.document.location.href = "/MVC/ICD10/Details/" + singleCode + "/" + (this.year ? this.year : "");
            return;
        }
        else {

            //find total number of records
            if (this.codeSearchResult.length > 0) {
                this.totalRecords = this.codeSearchResult[0].TotalRowCount;

                // start up chunking of search results
                setTimeout(() => {
                    this.chunkCodeSearchResults(0);
                }, 500);
            }
            else if (this.indexSearchResult.length > 0) {
                this.totalRecords = this.indexSearchResult[0].TotalRowCount;

                // start up chunking of search results
              //  setTimeout(() => { this.chunkIndexSearchResults(0); }, 500);
            }
            else {
                this.totalRecords = 0;
            }

            this.resultsLoading = false;

            //get the current record number view
            if (this.pageSize === -1) {
                this.currentPageMinCount = this.pageMin;
                this.currentPageMaxCount = this.totalRecords;
            }
            else {
                this.currentPageMinCount = this.pageIndex * this.pageSize - (this.pageSize - 1);
                this.currentPageMaxCount = this.pageIndex * this.pageSize;
                if (this.currentPageMaxCount > this.totalRecords) {
                    this.currentPageMaxCount = this.totalRecords;
                }
            }

            //setup paging links
            const mod = this.pageIndex % 4;
            switch (mod) {
                case 1:
                    this.paging = [this.pageIndex, (this.pageIndex + 1), (this.pageIndex + 2), (this.pageIndex + 3)];
                    break;
                case 2:
                    this.paging = [(this.pageIndex - 1), this.pageIndex, (this.pageIndex + 1), (this.pageIndex + 2)];
                    break;
                case 3:
                    this.paging = [(this.pageIndex - 2), (this.pageIndex - 1), this.pageIndex, (this.pageIndex + 1)];
                    break;
                case 0:
                    this.paging = [(this.pageIndex - 3), (this.pageIndex - 2), (this.pageIndex - 1), (this.pageIndex)];
                    break;
            }
            if (this.pageSize === -1) {
                this.pageMax = 1;
            }
            else {
                this.pageMax = Math.floor(this.totalRecords / this.pageSize);
                const mod2 = this.totalRecords % this.pageSize;
                if (mod2 > 0) {
                    this.pageMax++;
                }
            }
            for (let i = 3; i > 0; i--) {
                if (this.paging[i] > this.pageMax) {
                    this.paging = this.paging.slice(0, i);
                }
            }

            //get alternative search results
            if (this.totalRecords === 0) {
                this.altLoading = true;
                this.icd10SearchService.getAltResults(this.searchtype, this.keywords).then(
                    (response) => {
                        const data = response;
                        this.altLoading = false;

                        this.altSearchResults = data.Results;

                        this.altSearchGroups = [];
                        this.altSearchResults.forEach((value: IAltSearchSuggestion, key: number) => {
                            let typeExists = false;
                            this.altSearchGroups.forEach((value2: any, key2: number) => {
                                if (value.TermType === value2.TermType) {
                                    typeExists = true;
                                }
                            });
                            if (!typeExists) {
                                this.altSearchGroups.push({CodeType: value.CodeTypeId, TermType: value.TermType});
                            }
                        });

                        this.altSearchGroups.sort(function (a, b) {
                            if (a.CodeType > b.CodeType) {
                                return -1;
                            }
                            else if (a.CodeType === b.CodeType) {
                                return 0;
                            }
                            else {
                                return 1;
                            }
                        });
                    },
                    response => {
                        console.error(response);
                        this.altLoading = false;
                    });
            }
        }
    }

    //get list of icons to display for a ICD-10 code (Note: codeIconFlags[i]["IconNew"] and codeIconFlags[i]["IconRevised"] are not valid properties)
    buildICD10Icons(code: string, codeIconFlags: ICodeIconFlag[]): string {
        return this.icd10SearchService.buildICD10Icons(code, codeIconFlags);
        
    }

    getIcd10CodeDetailsUrl(input) {
        return input ? "/MVC/ICD10/Details/" + encodeURIComponent(input) + (this.year ? "/" + this.year : "") : "";
    }
}
