import { HttpClientModule } from "@angular/common/http";
import { Inject, Injector, NgModule} from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { NgbTypeaheadModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

import { CookieService } from 'ngx-cookie-service';

import { ContentModule } from './content/content.module';
import { BannersModule } from './banners/banners.module';
import { LoginModule } from './login/login.module';
import { CoreModule } from './core/core.module';
import { RecentCodesModule } from './recentCodes/recent-codes.module';
import { ForumModule } from './forum/forum.module';
import { DcpModule } from './dcp/dcp.module';
import { SearchBoxModule } from './search-box/search-box.module';
import { Icd10Module } from './icd10/icd10.module';
import { VideoModule } from './video/video.module';
import { LibraryModule } from './library/library.module';
import { UserNotesModule } from './userNotes/userNotes.module';
import { SiteContentModule } from './siteContent/siteContent.module';
import { PdgmCalculatorModule } from './pdgm-calculator/pdgm-calculator.module';
import { ManageUsersModule } from './manage-users/manage-users.module';
import { UsageReportingModule } from './usage-reporting-charts/usage-reporting-charts.module';
import { DictionaryModule } from "./dictionary/dictionary.module";
import { ProfileModule } from './profile/profile.module';
import { Icd9Module } from "./icd9/icd9.module";



import { ArticleSearchComponent } from './dcp/article-search.component';
import { ArticleSearchResultsComponent } from './dcp/article-search-results.component';
import { LatestHeadlinesComponent } from './dcp/latest-headlines.component';
import { BreakingNewsComponent } from './dcp/breaking-news.component';
import { ArticleWidgetComponent } from './dcp/article-widget.component';
import { LatestIssueWidgetComponent } from './dcp/latest-issue-widget.component';
import { LatestIssueComponent } from './dcp/latest-issue.component';
import { NewsletterArticleComponent } from './dcp/newsletter-article-component';
import { AdminUserLinkComponent } from './core/admin-user-link/admin-user-link.component';
import { SearchBoxComponent } from './search-box/search-box.component';
import { ToolboxComponent } from './core/toolbox/toolbox.component';
import { BillpadModalComponent } from './core/billpad/billpad-modal.component';
import { UserNotesModalComponent } from './userNotes/user-notes-modal-component';
import { BookmarksModalComponent } from './core/bookmarks/bookmarks-modal.component';
import { TipsModalComponent } from './siteContent/tips-modal.component';
import { TrainingWidgetComponent } from './core/training/training-widget.component';
import { TrainingComponent } from './core/training/training.component';
import { VideoComponent } from './video/video.component';
import { RecentCodesWidgetComponent } from './recentCodes/recent-codes-widget.component';
import { BookmarksToggleComponent } from './core/bookmarks/bookmarks-toggle.component';
import { BillpadToggleComponent } from './core/billpad/billpad-toggle.component';
import { LibraryComponent } from './library/library.component';
import { Icd10SearchComponent } from './icd10/icd10-search.component';
import { Icd10BrowseComponent } from './icd10/icd10-browse-component';
import { ICD9MappingWidgetComponent } from './icd9/icd9-mapping-widget.component';
import { UserNotesComponent } from './userNotes/user-notes.component';
import { UserNotesLookbackComponent } from './userNotes/user-notes-lookback.component';
import { ManageUsersComponent } from './manage-users/manage-users.component';
import { ForumComponent } from './forum/forum.component';
import { PDGMCalculatorComponent } from './pdgm-calculator/pdgm-calculator.component';
import { ProfileComponent } from './profile/profile.component';

import { UsageReportingChartsComponent } from "./usage-reporting-charts/usage-reporing-charts.component";

import { LoginComponent } from './login/login.component';
import { BulletinsDailyComponent } from "./core/bulletins/bulletins-daily.component";
import { BulletinsComponent } from "./core/bulletins/bulletins.component";
import { BulletinsReadComponent } from "./core/bulletins/bulletins-read.component";

import { NewsletterComponent } from "./dcp/newsletter-component";
import { ReadMessagesComponent } from "./core/messages/read-messages.component";

import { ArticleListComponent } from "./dcp/article-list.component";

import { InteractiveScenariosSearchComponent } from "./content/inter-scenarios-search.component"
import { Icd10SearchSlideComponent } from "./icd10/icd10-search-slide.component"

import { DictionaryDetailComponent } from "./dictionary/dictionary-detail.component";
import { DictionarySearchComponent } from "./dictionary/dictionary-search.component";
import { DictionaryAppendicesComponent } from "./dictionary/dictionary-appendices.component";
import { DictionaryResultsComponent } from "./dictionary/dictionary-results.component";

import { RecentCodesComponent } from "./recentCodes/recent-codes.component"
import { VideoWidgetComponent } from "./video/video-widget.component"

import { MessagesComponent } from "./core/messages/messages.component"

@NgModule({
    imports: [
        BrowserModule,
        CKEditorModule,
        FormsModule,
        HttpClientModule,
        NgbModalModule,
        NgbTypeaheadModule,
        //GoogleChartsModule,
        ContentModule,
        BannersModule,
        LoginModule,
        CoreModule,
        RecentCodesModule,
        ForumModule,
        DcpModule,
        SearchBoxModule,
        Icd10Module,
        VideoModule,
        LibraryModule,
        UserNotesModule,
        SiteContentModule,
        PdgmCalculatorModule,
        ManageUsersModule,
        UsageReportingModule,
        ProfileModule,
        Icd9Module,
        DictionaryModule
    ],
    declarations: [        
    ],
    providers: [
        CookieService
    ],
    entryComponents: [
        
        AdminUserLinkComponent,
        ArticleSearchComponent,
        ArticleWidgetComponent,
        BillpadModalComponent,
        BookmarksModalComponent,
        BookmarksToggleComponent,
        BreakingNewsComponent,
        ForumComponent,
        ICD9MappingWidgetComponent,
        Icd10BrowseComponent,
        Icd10SearchComponent,
        LatestHeadlinesComponent,
        LatestIssueWidgetComponent,
        LibraryComponent,
        LoginComponent,
        ManageUsersComponent,
        NewsletterArticleComponent,
        PDGMCalculatorComponent,
        ProfileComponent,
        RecentCodesWidgetComponent,
        SearchBoxComponent,
        TipsModalComponent,
        ToolboxComponent,
        TrainingComponent,
        TrainingWidgetComponent,
        UsageReportingChartsComponent,
        UserNotesComponent,
        UserNotesLookbackComponent,
        UserNotesModalComponent,
        VideoComponent,
        BulletinsDailyComponent,
        BulletinsReadComponent,
        BulletinsComponent,
        LatestIssueComponent,
        NewsletterComponent,
        ArticleListComponent,
        ArticleSearchResultsComponent,
        InteractiveScenariosSearchComponent,
        Icd10SearchSlideComponent,
        DictionaryDetailComponent,
        DictionarySearchComponent,
        DictionaryAppendicesComponent,
        DictionaryResultsComponent,
        RecentCodesComponent,
        BillpadToggleComponent,
        VideoWidgetComponent,
        MessagesComponent,
        ReadMessagesComponent
    ]
})
export class AppModule {
    constructor(@Inject(Injector) private injector: Injector) { }    
    ngDoBootstrap() {
        const loginWidget = createCustomElement(LoginComponent, { injector: this.injector });
        customElements.define('login-widget', loginWidget);

        const bookmarksToggleWidget = createCustomElement(BookmarksToggleComponent, { injector: this.injector });
        customElements.define('bookmarks-toggle-widget', bookmarksToggleWidget);

        const billpadtogglewidget = createCustomElement(BillpadToggleComponent, { injector: this.injector });
        customElements.define('billpad-toggle-widget', billpadtogglewidget);

        const icd9MappingWidget = createCustomElement(ICD9MappingWidgetComponent, { injector: this.injector });
        customElements.define('icd9-mapping-widget', icd9MappingWidget);

        const training = createCustomElement(TrainingComponent, { injector: this.injector });
        customElements.define('training-list', training);

        const trainingWidget = createCustomElement(TrainingWidgetComponent, { injector: this.injector });
        customElements.define('training-widget', trainingWidget);

        const recentCodesWidget = createCustomElement(RecentCodesWidgetComponent, { injector: this.injector });
        customElements.define('recent-codes-widget', recentCodesWidget);

        const forumWidget = createCustomElement(ForumComponent, { injector: this.injector });
        customElements.define('forum-widget', forumWidget);

        const latestHeadlinesWidget = createCustomElement(LatestHeadlinesComponent, { injector: this.injector });
        customElements.define('latest-headlines', latestHeadlinesWidget);

        const adminUserLinkWidget = createCustomElement(AdminUserLinkComponent, { injector: this.injector });
        customElements.define('admin-user-link', adminUserLinkWidget);

        const searchBoxWidget = createCustomElement(SearchBoxComponent, { injector: this.injector });
        customElements.define('search-box', searchBoxWidget);

        const icd10SearchWidget = createCustomElement(Icd10SearchComponent, { injector: this.injector });
        customElements.define('icd10-search', icd10SearchWidget);

        const videoPage = createCustomElement(VideoComponent, { injector: this.injector });
        customElements.define('video-page', videoPage);

        const libraryWidget = createCustomElement(LibraryComponent, { injector: this.injector });
        customElements.define('library-widget', libraryWidget);

        const userNotesModalWidget = createCustomElement(UserNotesModalComponent, { injector: this.injector });
        customElements.define('user-notes-modal', userNotesModalWidget);

        const toolboxWidget = createCustomElement(ToolboxComponent, { injector: this.injector });
        customElements.define('toolbox-widget', toolboxWidget);

        const billpadModalWidget = createCustomElement(BillpadModalComponent, { injector: this.injector });
        customElements.define('billpad-modal', billpadModalWidget);

        const bookmarksModalWidget = createCustomElement(BookmarksModalComponent, { injector: this.injector });
        customElements.define('bookmarks-modal', bookmarksModalWidget);

        const tipsModalWidget = createCustomElement(TipsModalComponent, { injector: this.injector });
        customElements.define('tips-modal', tipsModalWidget);

        const pdgmWidget = createCustomElement(PDGMCalculatorComponent, { injector: this.injector });
        customElements.define('pdgm-calculator', pdgmWidget);

        const articleSearchWidget = createCustomElement(ArticleSearchComponent, { injector: this.injector });
        customElements.define('article-search', articleSearchWidget);

        const newsletterArticleWidget = createCustomElement(NewsletterArticleComponent, { injector: this.injector });
        customElements.define('newsletter-article', newsletterArticleWidget);

        const newsletterArticle = createCustomElement(NewsletterComponent, { injector: this.injector });
        customElements.define('newsletter-list', newsletterArticle);

        const icd10BrowseWidget = createCustomElement(Icd10BrowseComponent, { injector: this.injector });
        customElements.define('icd10-browse', icd10BrowseWidget);

        const userNotesWidget = createCustomElement(UserNotesComponent, { injector: this.injector });
        customElements.define('user-notes-widget', userNotesWidget);

        const userNotesLookbackWidget = createCustomElement(UserNotesLookbackComponent, { injector: this.injector });
        customElements.define('user-notes-lookback-widget', userNotesLookbackWidget);

        const manageUsersWidget = createCustomElement(ManageUsersComponent, { injector: this.injector });
        customElements.define('manage-users-widget', manageUsersWidget);

        const usageReportingChartsWidget = createCustomElement(UsageReportingChartsComponent, { injector: this.injector });
        customElements.define('usage-reporting-charts-widget', usageReportingChartsWidget);

        const profileWidget = createCustomElement(ProfileComponent, { injector: this.injector });
        customElements.define('profile-widget', profileWidget);

        const latestIssueWidget = createCustomElement(LatestIssueWidgetComponent, { injector: this.injector });
        customElements.define('latest-issue-widget-component', latestIssueWidget);

        const articleWidget = createCustomElement(ArticleWidgetComponent, { injector: this.injector });
        customElements.define('article-widget-component', articleWidget);

        const bulletinsDaily = createCustomElement(BulletinsDailyComponent, { injector: this.injector });
        customElements.define('bulletins-daily-list', bulletinsDaily);

        const bulletins = createCustomElement(BulletinsComponent, { injector: this.injector });
        customElements.define('bulletins-list', bulletins);

        const bulletinRead = createCustomElement(BulletinsReadComponent, { injector: this.injector });
        customElements.define('bulletins-details', bulletinRead);

        const latestIssue = createCustomElement(LatestIssueComponent, { injector: this.injector });
        customElements.define('latest-issue-component', latestIssue);

        const articleList = createCustomElement(ArticleListComponent, { injector: this.injector });
        customElements.define('article-list-component', articleList);

        const breakingNews = createCustomElement(BreakingNewsComponent, { injector: this.injector });
        customElements.define('breaking-news-component', breakingNews);

        const articleSearchResults = createCustomElement(ArticleSearchResultsComponent, { injector: this.injector });
        customElements.define('article-search-results', articleSearchResults);

        const interactiveScenariosSearch = createCustomElement(InteractiveScenariosSearchComponent, { injector: this.injector });
        customElements.define('interactive-scenarios-search', interactiveScenariosSearch);

        const icd10SearchSlide = createCustomElement(Icd10SearchSlideComponent, { injector: this.injector });
        customElements.define('icd10-search-slide', icd10SearchSlide);

        const dictionaryDetail = createCustomElement(DictionaryDetailComponent, { injector: this.injector });
        customElements.define('dictionary-detail', dictionaryDetail);

        const dictionarySearch = createCustomElement(DictionarySearchComponent, { injector: this.injector });
        customElements.define('dictionary-search', dictionarySearch);

        const dictionaryAppendices = createCustomElement(DictionaryAppendicesComponent, { injector: this.injector });
        customElements.define('dictionary-appendecies', dictionaryAppendices);

        const dictionaryResults = createCustomElement(DictionaryResultsComponent, { injector: this.injector });
        customElements.define('dictionary-results', dictionaryResults);

        const recentCodes = createCustomElement(RecentCodesComponent, { injector: this.injector });
        customElements.define('recent-codes', recentCodes);

        const videoWidget = createCustomElement(VideoWidgetComponent, { injector: this.injector });
        customElements.define('video-widget', videoWidget);

        const messages = createCustomElement(MessagesComponent, { injector: this.injector });
        customElements.define('messages-widget', messages);

        const readMessagesComponent = createCustomElement(ReadMessagesComponent, { injector: this.injector });
        customElements.define('read-messages', readMessagesComponent);

        
        
    }
}