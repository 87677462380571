import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { LoginComponent } from "./login.component";
import { LoginService } from "./login.service";
import { EulaModalComponent } from "./eula-modal.component";

@NgModule({
    imports: [
        CommonModule,
        FormsModule
    ],
    providers: [
        LoginService
    ],
    declarations: [
        LoginComponent,
        EulaModalComponent
    ]
})
export class LoginModule { }

