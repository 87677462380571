import { Component, Inject, Input, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'

import { SiteContentService } from "./siteContent.service";
import tipsModalTemplate from './tips-modal.html';

@Component({
    selector: 'tips-modal',
    template: tipsModalTemplate
})


export class TipsModalComponent {

    constructor(@Inject(SiteContentService) private siteContentService: SiteContentService,
    @Inject(DomSanitizer) private sanitizer: DomSanitizer) {

    }

    content1: SafeHtml;
    
    ngOnInit() {
        this.siteContentService.loadContentBucket().then(response => {
            const data = response;
            this.content1 = this.sanitizer.bypassSecurityTrustHtml(data.Content1);

        })
    }
}
