import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';



@Component({
    selector: 'recent-codes-sort-order',
    template: `<div class="btn-group results">` +
        `<label class="btn btn-default col-xs-6 {{sorttype === 1 ? 'active' : ''}}"><input type="radio" autocomplete="off" style="display:none" (click)="sort(1)" />Most Viewed</label>` +
        `<label class="btn btn-default col-xs-6 {{sorttype === 2 ? 'active' : ''}}"><input type="radio" autocomplete="off" style="display:none" (click)="sort(2)" />Most Recent</label>` +
        `</div>`
})
export class RecentCodesSortOrderComponent  {
    @Output() onsortchange = new EventEmitter<number>();
    @Input() sorttype: number;
    
    constructor() {
        
    }


    sort(sortType: number) {
        this.onsortchange.emit(sortType);
    }
};