import { Injectable, Inject } from '@angular/core';
import { HttpClient } from "@angular/common/http";

import { IAjaxResultTrainingOpportunities } from "./training.models";


@Injectable({
    providedIn: 'root'
})
export class TrainingService {
    constructor(@Inject(HttpClient) private http: HttpClient) {
    }

    getTrainingOpps(count: number) {
        const url = "/MVC/About/GetTrainingOpportunities";
        const parameters = { params: { count: count.toString() } };
        
        return this.http.get<IAjaxResultTrainingOpportunities>(url, parameters).toPromise();
    }

    getWebinarsOpps(count: number) {
        const url = "/MVC/About/GetODWebinars";
        const parameters = { params: { count: count.toString() } };

        return this.http.get<IAjaxResultTrainingOpportunities>(url, parameters).toPromise();
    }

   
}