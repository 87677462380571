import { Component, Inject, Input, OnInit } from '@angular/core';
import { MessagesService } from './messages.service';

import readMessagesTemplate from './read-messages.html';

@Component({
    selector: 'read-messages',
    template: readMessagesTemplate
})
export class ReadMessagesComponent {

    @Input() messageid: number;
    
    subject: string;
    fromUser: string;
    category: string;
    body: string;

    constructor(@Inject(MessagesService) private messagesService: MessagesService) {
    }

    ngOnInit() {
        this.messagesService.getMessage(this.messageid).then(response => {
            const data = response;
            this.subject = response.Subject;
            this.fromUser = response.FromUser;
            this.category = response.Category;
            this.body = response.Body;

        })
    }

}