import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TrialBannerModule } from "./trial-banner/trial-banner.module";
import { InteractiveScenariosBannerModule } from "./interactive-scenarios-banner/interactive-scenarios-banner.module"

@NgModule({
    imports: [
        CommonModule, TrialBannerModule, InteractiveScenariosBannerModule
    ],
    providers: [

    ],
    declarations: [

    ]
})
export class BannersModule { }

//// Top-level module
//export default angular.module("codingCenterApp.banners", [
//    TrialBannerModule,
//    InteractiveScenarioBannerModule
//    ])
//    .name;