import { Component, Inject, Input, OnInit } from '@angular/core';
import * as models from "./article.models";
import { NewsletterService } from "./newsletter.service";
import { ArticleService } from "./article.service";
import articleListTemplate from './article-list.html';

@Component({
    selector: 'article-list',
    template: articleListTemplate
})
export class ArticleListComponent {

    constructor(@Inject(ArticleService) private articleService: ArticleService) {
    }

    @Input() tabindexvalue: number;
    @Input() viewarticleurl: string;
    @Input() viewallurl: string;
    @Input() articletype: string;
    @Input() articlesubtype: string;
    @Input() count: number = 3;
    @Input() showall: boolean = false;


    defaultPageSize: number = 3;
    articlesLoaded: boolean;
    articles: models.IArticle[];
    totalcount: number;
    ArticleNewIconStartDate: Date;
    Display: any[];
    PageIndex: number;


    // Displays a count of a specific type of articles
    ngOnInit() {
        
        // Indicates if we should show results or loading wheel
        this.articlesLoaded = false;
        this.articles = [];
        this.totalcount = 0;
        this.ArticleNewIconStartDate = new Date();
        this.ArticleNewIconStartDate.setDate(this.ArticleNewIconStartDate.getDate() - 14);
        if (this.showall) {

            this.articleService.getAllArticlesByType(this.articletype, this.articlesubtype).then(response => {
                const data = response;
                this.articles = data.ArticleList;
                this.totalcount = data.TotalCount;
                this.articlesLoaded = true;
            });

        }
        else {
            this.articleService.getArticlesByType(this.count, this.articletype, this.articlesubtype).then(response => {
                const data = response;
                this.articles = data.ArticleList.slice(0, 3);
                this.totalcount = data.TotalCount;
                this.articlesLoaded = true;
            });
        }
      

    }


    // Formats JSON dates correctly
    dateFormatter(dateVal: string, format): string {
        const month: string[] = [];
        month[0] = "January";
        month[1] = "February";
        month[2] = "March";
        month[3] = "April";
        month[4] = "May";
        month[5] = "June";
        month[6] = "July";
        month[7] = "August";
        month[8] = "September";
        month[9] = "October";
        month[10] = "November";
        month[11] = "December";

        const d = new Date(parseInt(dateVal.replace(/\/Date\((.*?)\)\//gi, "$1"), 10));

        if (format === "full") {
            return month[d.getMonth()] + " " + d.getDate() + ", " + d.getFullYear();
        } else {
            return (d.getMonth() + 1) + "/" + d.getDate() + "/" + d.getFullYear();
        }
    }
}
