import { Component, Inject, Input, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Icd10BrowseWebService } from "./icd10cmBrowseWebService.service";
import { IIcd10Chapter } from "./icd10.models";

import icd10BrowseTemplate from "./icd10-browse.html";

@Component({
    selector: 'icd10-browse',
    template: icd10BrowseTemplate
})
export class Icd10BrowseComponent {

    constructor(@Inject(Icd10BrowseWebService) private browseService: Icd10BrowseWebService, @Inject(DOCUMENT) private document: Document) {

    }

    @Input() chaptersvarname: string;
    chapters: IIcd10Chapter[] = [];
    @Input() codeYear: string;
   
    /**
     * This is a HACKY initialization function.
     * This is used in an ng-init expression to populate the JSON array that comes from a Razor view rendered output.
     * @param {string} chapters_var_name global variable name from which to read the chapters from to initialize this controller.
     */
    ngOnInit() {
        this.browseService.loadChapterTree(this.codeYear).then(data => {
            this.chapters = data;
            this.chapters.forEach(item => { item.expanded = false; });
        })
        
    }

    loadChapter(chapter) {
        if (chapter.Sections) {
            chapter.expanded = !chapter.expanded;
        } else {
            chapter.loading = true;

            this.browseService.loadChapter(chapter.ICD10ChapterVersionId).then(data => {
                chapter.Sections = data;
                chapter.expanded = !chapter.expanded;
                chapter.loading = false;
            });
        }
    }

    loadSection(section) {
        if (section.Codes) {
            section.expanded = !section.expanded;
        } else {
            section.loading = true;

            this.browseService.loadSection(section.ICD10SectionVersionId).then(data => {
                section.Codes = data;
                section.expanded = !section.expanded;
                section.loading = false;
            });
        }
    }

    loadCode(sectionId, code) {
        if (code.ChildCodes) {
            code.expanded = !code.expanded;
        } else {
            code.loading = true;

            this.browseService.loadCode(sectionId, code.Code).then(data => {
                code.ChildCodes = data;
                code.index = code.Code.length;
                code.expanded = !code.expanded;
                code.loading = false;
            });
        }
    }
}
