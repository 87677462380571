import { Injectable, Inject } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import * as adminUsersModels from './manage-users.models';

export interface IManageUsersService {
    getAdminUser(): Promise<adminUsersModels.IAdminUserProfile>;
    unlockUserAccount(userId: number): Promise<any>;
    getMember(customerId: number): Promise<adminUsersModels.IAccountMember>;
    getUserSort(sortColumn: adminUsersModels.UserSortOrder): string[];
}

@Injectable({
    providedIn: 'root'
})
export class ManageUsersService implements IManageUsersService {
    constructor(@Inject(HttpClient) private http: HttpClient) {
    }

    getAdminUser(): Promise<adminUsersModels.IAdminUserProfile> {
        return this.http.get<adminUsersModels.IAdminUserProfile>("/MVC/ManageUsers/GetAdminUser").toPromise();
    }

    unlockUserAccount(userId: number) {
        return this.http.post<any>("/MVC/ManageUsers/UnlockUser", { userId: userId.toString() }).toPromise();
    }

    getServiceLevelMembershipId(slMemberships: adminUsersModels.IHHCCMembership[]): number | null {
        let serviceLevel: adminUsersModels.IHHCCMembership | null = null;
        if (slMemberships.length > 1) {
            let expert: adminUsersModels.IHHCCMembership[] = slMemberships.filter(membership => {
                membership.Membership.Roles.filter(role => {
                    role.RoleCode == "HHCC_EXPERT"
                }).length > 0
            });
            if (expert.length > 0) {
                serviceLevel = expert[0];
            }
            else {
                let dcpAccess: adminUsersModels.IHHCCMembership[] = slMemberships.filter(membership => {
                    membership.Membership.Roles.filter(role => {
                        role.RoleCode == "CPH_ONLINE"
                    }).length > 0
                });

                let ccAccess: adminUsersModels.IHHCCMembership[] = slMemberships.filter(membership => {
                    membership.Membership.Roles.filter(role => {
                        role.RoleCode == "ICD_ONLINE"
                    }).length > 0
                });

                let professional: adminUsersModels.IHHCCMembership[] = dcpAccess.filter(membership => {
                    ccAccess.filter(m => {
                        m.Membership.Id == membership.Membership.Id
                    }).length > 0
                });

                if (professional.length > 0) {
                    serviceLevel = professional[0];
                }
                else if (ccAccess.length > 0) {
                    serviceLevel = ccAccess[0];
                }
                else if (dcpAccess.length > 0) {
                    serviceLevel = dcpAccess[0];
                }
            }
        }
        else if (slMemberships.length == 1) {
            serviceLevel = slMemberships[0];
        }

        let serviceLevelId: number | null = serviceLevel != null ? serviceLevel.Membership.Id : null;
        return serviceLevelId;
    }

    getAddOnMembershipIds(aoMemberships: adminUsersModels.IHHCCMembership[]): number[] {
        let aoMembershipIds: number[] = [];
        for (let ao of aoMemberships) {
            aoMembershipIds.push(ao.Membership.Id.valueOf());
        }

        return aoMembershipIds;
    }

    getMember(customerId: number): Promise<adminUsersModels.IAccountMember> {
        //console.log(customerId);
        return this.http.get<adminUsersModels.IAccountMember>("/MVC/ManageUsers/GetMember/", {
            params: {
                customerId: customerId.toString()
            }
        }).toPromise();
    }

    getUserSort(sortColumn: adminUsersModels.UserSortOrder): string[] {
        switch (sortColumn) {
            case adminUsersModels.UserSortOrder.FirstName:
                return ["FirstName", "LastName"];
            case adminUsersModels.UserSortOrder.Email:
                return ["Email", "LastName", "FirstName"];
            case adminUsersModels.UserSortOrder.Username:
                return ["UserName", "LastName", "FirstName"];
            case adminUsersModels.UserSortOrder.Company:
                return ["Company", "LastName", "FirstName"];
            case adminUsersModels.UserSortOrder.ServiceLevel:
                return ["ServiceLevel", "LastName", "FirstName"];
            case adminUsersModels.UserSortOrder.Admin:
                return ["-IsAdmin", "LastName", "FirstName"];
            case adminUsersModels.UserSortOrder.Locked:
                return ["-IsLockedOut", "LastName", "FirstName"];
            default: //adminUsersModels.UserSortOrder.LastName
                return ["LastName", "FirstName"];
        }
    }
}