import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ManageUsersService } from "./manage-users.service";
import { AddUserService } from "./add-user/add-user.service";
import { EditUserService } from "./edit-user/edit-user.service";
import { RemoveUsersService } from "./remove-users/remove-users.service";
import { ManageUsersComponent } from "./manage-users.component";
import { MembershipGridComponent } from "./membership-grid.component";
import { NotificationComponent } from "./notification.component";
import { AddUserComponent } from "./add-user/add-user.component";
import { EditUserComponent } from "./edit-user/edit-user.component";
import { RemoveUsersComponent } from "./remove-users/remove-users.component";
import { FilterUsersPipe } from "./filter-users.pipe";
import { SortUsersPipe } from "./sort-users.pipe";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule
    ],
    providers: [
        ManageUsersService,
        AddUserService,
        EditUserService,
        RemoveUsersService
    ],
    declarations: [
        ManageUsersComponent,
        MembershipGridComponent,
        NotificationComponent,
        AddUserComponent,
        EditUserComponent,
        RemoveUsersComponent,
        FilterUsersPipe,
        SortUsersPipe

    ]
})
export class ManageUsersModule { }