import { Component, Inject, Input, OnInit } from '@angular/core';

import { IAccountMember, IAdminUserProfile, IHHCCMembership, IHHCCUser, INotification, TransactionType, UserSortOrder } from './manage-users.models';
import { ManageUsersService } from './manage-users.service';
import { EditUserService } from './edit-user/edit-user.service';
import { AddUserService } from './add-user/add-user.service';
import manageUsersTemplate from './manage-users.html';



interface ITransactionModal {
    show: boolean;
    transaction: TransactionType;
}

class TransactionModal implements ITransactionModal {
    show: boolean = false;
    transaction: TransactionType = TransactionType.None;

    constructor(show: boolean, transaction: TransactionType) {
        this.show = show;
        this.transaction = transaction;
    }
}


@Component({
    selector: 'manage-users',
    template: manageUsersTemplate
})
export class ManageUsersComponent  {

    notification: INotification = {
        Show: false,
        Message: '',
        CssClass: ''
    };
    loadingInfo: boolean = true;
    searchText: string = "";
    serviceLevels: IHHCCMembership[] = [];
    addOns: IHHCCMembership[] = [];
    complimentaryAddOns: string[] = [];
    users: IHHCCUser[] = [];
    isAdmin: boolean = false;
    customerId: number = -1;
    userId: number = -1;
    userSortColumn: UserSortOrder = UserSortOrder.LastName;
    userReverseSort: boolean = false;
    userSortOrder: string[] = ["LastName", "FirstName"];
    transactionModal: TransactionModal = new TransactionModal(false, TransactionType.None);
    sendExistingUserNotficationEmail: boolean = false;


    constructor(@Inject(ManageUsersService) private usersService: ManageUsersService,
        @Inject(EditUserService) private editUserService: EditUserService, @Inject(AddUserService) private addUserService: AddUserService) {

    }

    ngOnInit() {
        this.getAdminUser();
        this.sendExistingUserNotficationEmail = false;
    }

    getAdminUser() {
        this.loadingInfo = true;
        this.usersService.getAdminUser()
            .then(response => {
                let adminUser: IAdminUserProfile = response;
                this.serviceLevels = adminUser.ServiceLevels.slice();
                this.addOns = adminUser.AddOns.slice();
                this.complimentaryAddOns = adminUser.ComplimentaryAddOns.slice();
                this.users = adminUser.Users.slice();
                this.isAdmin = adminUser.IsAdmin;
                this.customerId = adminUser.AdminCustomerId;
                this.userId = adminUser.AdminUserId;
                this.loadingInfo = false;
            }).catch(response => {
                console.error(response);
                this.notification = {
                    Show: true,
                    Message: "Something went wrong while trying to retrieve your user information.",
                    CssClass: "alert-danger"
                };
                this.loadingInfo = false;
            });
    }

    getSelectedUsersCount(): number {
        let selectedCount: number = 0;
        for (let u of this.users) {
            if (u.IsSelected) {
                selectedCount++;
            }
        };
        return selectedCount;
    }

    sortUsers(sortColumn: UserSortOrder) {
        if (this.userSortColumn == sortColumn) {
            //update sort direction
            this.userReverseSort = !this.userReverseSort;
        }
        else {
            //update sort column
            this.userSortColumn = sortColumn;
            this.userReverseSort = false;
        }

        this.userSortOrder = this.usersService.getUserSort(this.userSortColumn);

       
    }

    unlockSelectedUsers() {
        let selectedCount: number = this.getSelectedUsersCount();

        for (let u of this.users) {
            if (u.IsSelected) {
                this.usersService.unlockUserAccount(u.CustomerId)
                    .then(() => {
                        //console.log("User unlocked: " + u.UserName);
                        u.IsLockedOut = false;
                    }).catch(() => {
                        console.error("Unable to unlock " + u.UserName + " (unlockSelectedUsers)");
                    }).finally(() => {
                        selectedCount--;
                        if (selectedCount <= 0) {
                            this.notification = {
                                Show: true,
                                Message: "User(s) were unlocked successfully.",
                                CssClass: "alert-success"
                            };

                            this.getAdminUser();
                        }
                    });
            }
        };
    }

    sumAvailableServiceLevels(): number {
        let count: number = 0;
        for (let m of this.serviceLevels) {
            count += m.Membership.RemainingChildAccounts;
        }
        return count;
    }

    findServiceLevelMembershipsForUser(selectedUser: IHHCCUser): IHHCCMembership[] {
        let slMemberships: IHHCCMembership[] = [];

        this.serviceLevels.forEach(serviceLevelMembership => {
            for (let customer of serviceLevelMembership.Membership.Customers) {
                if (customer.Id == selectedUser.CustomerId) {
                    slMemberships.push(serviceLevelMembership);
                }
            }
        });

        let slActive: IHHCCMembership[] = slMemberships.filter(m => m.IsActive);
        if (slActive.length > 0) {
            return slActive;
        }

        return slMemberships;
    }

    findAddOnMembershipsForUser(selectedUser: IHHCCUser): IHHCCMembership[] {
        let aoMemberships: IHHCCMembership[] = [];

        this.addOns.forEach(addOnMembership => {
            for (let customer of addOnMembership.Membership.Customers) {
                if (customer.Id == selectedUser.CustomerId) {
                    aoMemberships.push(addOnMembership);
                }
            }
        });

        let aoActive: IHHCCMembership[] = aoMemberships.filter(m => m.IsActive);
        if (aoActive.length > 0) {
            return aoActive;
        }

        return aoMemberships;
    }

    setupTransactionModal(transaction: TransactionType): void {
        //display the correct form in the modal
        if (transaction != TransactionType.None) {
            this.transactionModal = new TransactionModal(true, transaction);
        }
        else {
            this.transactionModal = new TransactionModal(false, transaction);
        }
    }

    removeUsers(): void {
        this.setupTransactionModal(TransactionType.RemoveUsers);
    }

    transferToEdit(user: any) {
        //console.log('transferToEdit: ', user);
        this.loadEditUser(user);
    }

    loadEditUser(selectedUser: IHHCCUser): void {
        this.setupTransactionModal(TransactionType.EditUser);

        //console.log('loadEditUser: ', selectedUser);

        let slMemberships: IHHCCMembership[] = this.findServiceLevelMembershipsForUser(selectedUser);
        //console.log('Service level memberships: ', slMemberships);

        let serviceLevelId: number = this.usersService.getServiceLevelMembershipId(slMemberships);
        //console.log('Service level membership ID: ', serviceLevelId);

        let aoMemberships: IHHCCMembership[] = this.findAddOnMembershipsForUser(selectedUser);
        let aoMembershipIds: number[] = this.usersService.getAddOnMembershipIds(aoMemberships);

        if (slMemberships.length > 0 || aoMemberships.length > 0) {
            this.sendExistingUserNotficationEmail = false;
        }
        else {
            this.sendExistingUserNotficationEmail = true;
        }

        this.usersService.getMember(selectedUser.CustomerId).then(result => {
            let accountMember: IAccountMember = result;
            //console.log('getMember: ', accountMember);

            this.editUserService.setUser(accountMember, serviceLevelId, aoMembershipIds);
            //console.log('editUser: ', this.editUserService.user);
        }).catch(response => {
            console.error(response);
            this.editUserService.clearUser();
        });
    }

    addNewUser(): void {
        this.setupTransactionModal(TransactionType.AddUser);
    }

    transactionCancelled(): void {
       
        //hide forms
        this.setupTransactionModal(TransactionType.None);

        //clear add user fields for next use
        this.addUserService.clearUsername();
        this.addUserService.clearNewUser();

        //clear edit user fields for next use
        this.editUserService.clearUser();
    }

    removeCompleted(): void {
        this.transactionCompleted({
            Show: true,
            Message: "User(s) were removed successfully.",
            CssClass: "alert-success"
        });
    }

    updateCompleted(): void {
        this.transactionCompleted({
            Show: true,
            Message: "User account updated.",
            CssClass: "alert-success"
        });
    }

    addCompleted(): void {
        this.transactionCompleted({
            Show: true,
            Message: "User account created.",
            CssClass: "alert-success"
        });
    }

    transactionCompleted(notification: INotification): void {
        //hide forms
        this.setupTransactionModal(TransactionType.None);

        //update notification
        this.notification = notification;

        //clear add user fields for next use
        this.addUserService.clearUsername();
        this.addUserService.clearNewUser();

        //clear edit user fields for next use
        this.editUserService.clearUser();

        //refresh membership and user lists
        this.getAdminUser();
    }
}
