import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { BillpadApiService } from '../billpad/billpadApi.service';

import toolboxTemplate from './toolbox.html';

@Component({
    selector: 'toolbox',
    template: toolboxTemplate
})
export class ToolboxComponent {

    constructor(@Inject(BillpadApiService) private billpadService: BillpadApiService) {
    }

    @Input() isexpert: Boolean;
    @Input() haspdgmexclusion: Boolean;
    @Input() limittoolbox: Boolean;

    ngOnInit() {
    }

    get numCodes() : number {

        return this.billpadService.numCodes;
    };

}
