import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

//import icd10CodeDetailsUrl from "./icd10CodeDetailsUrl.filter";
import { Icd10BrowseComponent } from "./icd10-browse-component";
import { MappingIcd10CodeComponent } from "./mappingicd10code.component";
import { Icd10BrowseWebService } from "./icd10cmBrowseWebService.service";
import { Icd10SearchComponent } from "./icd10-search.component";
import { Icd10AlphaIndexComponent } from './icd10-alpha-index.component';
import { Icd10SearchSlideComponent } from './icd10-search-slide.component';
import { Icd10BrowseTreeComponent } from './icd10-browse-tree.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule
    ],
    providers: [
        Icd10BrowseWebService
    ],
    declarations: [
        Icd10BrowseComponent,
        MappingIcd10CodeComponent,
        Icd10SearchComponent,
        Icd10AlphaIndexComponent,
        Icd10SearchSlideComponent,
        Icd10BrowseTreeComponent
    ]
})
export class Icd10Module { }



//export default angular.module("codingCenterApp.icd10", [])
//    .directive("icd10Browse", downgradeComponent({ component: Icd10BrowseComponent }))
//    .directive("icd10Search", downgradeComponent({ component: Icd10SearchComponent }))
//    .directive("mappingIcd10Code", downgradeComponent({component: MappingIcd10CodeComponent}))
//    .factory("icd10CmBrowseWebService", downgradeInjectable(Icd10BrowseWebService))
//   // .filter("icd10CodeDetailsUrl", icd10CodeDetailsUrl)
//    .directive("icd10AlphaIndex", downgradeComponent({ component: Icd10AlphaIndexComponent }))
//    .directive("icd10SearchSlide", downgradeComponent({ component: Icd10SearchSlideComponent }))
//    .directive("icd10BrowseTree", downgradeComponent({ component: Icd10BrowseTreeComponent}))
//    .name;
