export enum UserSortOrder {
    LastName = "LastName",
    FirstName = "FirstName",
    Company = "Company",
    Email = "Email",
    Username = "Username",
    ServiceLevel = "ServiceLevel",
    Admin = "Admin",
    Locked = "Locked"
}

export interface IAdminUserProfile {
    AdminCustomerId: number;
    AdminUserId: number;
    IsAdmin: boolean;
    ServiceLevels: IHHCCMembership[];
    AddOns: IHHCCMembership[];
    ComplimentaryAddOns: string[];
    Users: IHHCCUser[];
}

export interface IHHCCMembership {
    Membership: IWebAuthMembership;
    IsActive: boolean;
}

export interface IWebAuthMembership {
    ChildAccounts: number;
    CreateDate: string;
    CustomerId: number;
    Customers: IWebAuthCustomer[];
    EffectiveDates: IEffectiveDate[],
    ExpirationDate: string;
    GrantedChildAccounts: number;
    GrantedQty: number;
    Id: number;
    IsDeleted: boolean;
    OriginalStartDate: string;
    OwnerIsMember: boolean;
    PlanId: number;
    Plans: IWebAuthPlan[];
    PrevMembershipId: number;
    Qty: number;
    RemainingChildAccounts: number;
    Roles: IWebAuthRole[];
}

export interface IWebAuthPlan {
    AfterRegistrationCmsPageId: number;
    Id: number;
    IsDeleted: boolean;
    MembershipRegistrationEmailTemplateId: number;
    Name: string;
    NewMembershipEmailTemplateId: number;
    SendMembershipRegistrationEmails: boolean;
    TermGranularity: string;
    TermLengthType: string;
}

export interface IWebAuthRole {
    ApplicationCode: string;
    RoleCode: string;
}

export interface IEffectiveDate {
    Id: number;
    MembershipId: number;
    StartDate: string;
    StopDate: string;
}

export interface IHHCCUser {
    CustomerId: number;
    FirstName: string;
    LastName: string;
    Email: string;
    UserName: string;
    Company: string;
    ServiceLevel: string;
    IsAdmin: boolean;
    IsLockedOut: boolean;
    IsSelected: boolean;
}

export interface IAccountMember {
    CustomerId: number;
    FirstName: string;
    LastName: string;
    Email: string;
    UserName: string;
    Company: string;
    IsAdmin: boolean;
    ServiceLevel: IWebAuthMembership;
    AddOns: IWebAuthMembership[];
}

export interface IWebAuthCustomer {
    Id: number;
    Type: string;
    ParentCustomerId: number;
    CreatedInApplicationId: number;
    CreateDate: string;
    LastUpdatedDate: string;
    IsDeleted: boolean;
    DefaultShippingAddressId: number;
    DefaultBillingAddressId: number;
    ProfileAddressId: number;
    Email: string;
    Timezone: string;
    EmailVerificationDate: string;
    ProfileAddress: IAddressDetail;
    Users: IWebAuthUser[];
}

export interface IAddressDetail {
    City: string;
    Company: string;
    Country: string;
    CustomerId: number;
    FaxNumber: string;
    FirstName: string;
    Id: number;
    IsDeleted: boolean;
    LastName: string;
    PhoneNumber: string;
    PostalCode: string;
    Region: string;
    Street: string;
    Title: string;
}

export interface IWebAuthUser {
    Id: number;
    UserName: string;
    Email: string;
    CreateDate: string;
    LastLoginDate: string;
    IsLockedOut: boolean;
    IsDeleted: boolean;
    LastPasswordChangeDate: string;
    LastFailedPasswordDate: string;
    FailedPasswordCount: number;
    IsAdmin: boolean;
    Links: IWebAuthUserLinks;
    FirstName: string;
    LastName: string;
    Customers: IWebAuthCustomer[];
    Grants: IWebAuthGrant[];
    Roles: IWebAuthRole[];
    Memberships: IWebAuthMembership[];
}

export interface IWebAuthGrant {
    Id: number;
    UserId: number;
    ApplicationId: number;
    RoleCode: string;
    GrantedBy: any;
    GrantDate: string;
    IsDeleted: boolean;
}

export interface IWebAuthUserLinks {
    Self: IWebAuthUserLinksSelf;
}

export interface IWebAuthUserLinksSelf {
    Href: string;
}

export interface INotification {
    Show: boolean;
    Message: string;
    CssClass: string;
}

export interface IUserBase {
    Username: string;
    FirstName: string;
    LastName: string;
    Company: string;
    IsAdmin: boolean;
    ServiceLevelMembershipId: number | null;
    AddOnMembershipIds: number[];
}

export interface IEditUser extends IUserBase {
    CustomerId: number;
    Email: string;
    PreviousServiceLevel: number | null;
    PreviousAddOns: number[];
}

export interface IAddUser extends IUserBase {
    Password: string;
}

export interface INewUserId {
    CustomerId: number;
    UserId: number;
}

export interface IAddOn {
    MembershipId: number;
}

export enum TransactionType {
    None = "None",
    UnlockUsers = "UnlockUsers",
    RemoveUsers = "RemoveUsers",
    EditUser = "EditUser",
    AddUser = "AddUser"
}