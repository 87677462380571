import { Component, Inject, Input, OnInit } from '@angular/core';
import { UsageReportingChartsService } from "../usage-reporting-charts.service";
import { IUsersSummaryInfo, IUserIndividualInfo } from "../usage-reporting-charts.models"

import template from './individual-chart-templates.html';

@Component({
    selector: 'users-summary-info-chart',
    template: template
})
export class UsersSummaryInfoChartComponent {
    chart: any = {};
    chartData: IUsersSummaryInfo[];
    chartDataRows: any[];

    individualUserData: IUserIndividualInfo | null;

    totalRecords: number = 0;
    isLoaded: boolean = false;
    searchQuery: string = "";

    constructor(@Inject(UsageReportingChartsService) private userReportingChartsService: UsageReportingChartsService) {
    }

    ngOnInit() {
        
        this.getUsersSummaryInfo();
    };

    search() {
        this.chart.data = this.userReportingChartsService.search(this.chartDataRows, this.searchQuery);
        this.totalRecords = this.chart.data.length;
        this.isLoaded = true;
    }

    getUsersSummaryInfo() {
        this.userReportingChartsService.getUsersSummaryInfo().then(response => {
            this.chartData = response ? response : [];
            this.individualUserData = null;

            if (this.chartData) {
                this.chart.type = "Table";
                    this.chart.columnNames =  [{ label: "User", type: "string" }, { label: "Days in last 90 days", type: "number" }, { label: "Total time", type: "string" }, { label: "Page Views", type: "number" } ] ;

                this.chart.data = [];
                var self = this;
                this.chartData.forEach(function (item, index) {
                    var chartItem =  [{ v: item.User, f: "<a >" + item.User + "</a>" }, { v: item.Days }, { v: item.TotalTimeSpent }, { v: item.PageViews }] ;
                    self.chart.data.push(chartItem);
                });
                this.chart.options = this.userReportingChartsService.getChartDefaultOptions();
                this.chartDataRows = this.chart.data;
                this.totalRecords = this.chart.data.length;
                this.isLoaded = true;
            }
        }).catch(error => {
            console.error(error);
        });
    }

    getUserIndividualInfo(selectedItem: any) {
        if (this.individualUserData != null)
            return;

        if (!!selectedItem) {
            var childId = this.chart.data.rows[selectedItem.row].c[0].v;
            this.userReportingChartsService.getUserIndividualInfo(childId).then(response => {
                this.individualUserData = response ? response : null;
                if (this.chartData) {
                    this.chart.type = "Table",
                        this.chart.data = { cols: [{ label: "Total", type: "string" }, { label: "Page URL", type: "string" }] };

                    this.chart.data.rows = [];
                    var self = this;
                    this.individualUserData.PagesInfo.forEach(function (item, index) {
                        var chartItem = { c: [{ v: item.Total }, { v: "<a href='" + item.PageUrl + "'>" + item.PageUrl + "</a>"}] };
                        self.chart.data.rows.push(chartItem);
                    })

                    this.chartDataRows = this.chart.data.rows;
                    this.totalRecords = this.chart.data.rows.length;
                    this.isLoaded = true;
                }
            })
        }
    }
}

