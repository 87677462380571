import { Component, Inject, Input, OnInit, ElementRef, ViewChild} from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of, OperatorFunction } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, tap, switchMap } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { SearchBoxService } from "./searchBox.service";
import { ICurrentSearchOption, IRecentSearchTerm } from "./search-box.models";
import { NgbTypeaheadConfig } from '@ng-bootstrap/ng-bootstrap';

import searchBoxTemplate from "./search-box.html";


@Component({
    selector: 'search-box',
    template: searchBoxTemplate,
    providers: [{ provide: NgbTypeaheadConfig, useFactory: ngbTypeaheadDefaultConfigFactory }]
})
export class SearchBoxComponent {
    

    DEFAULT_SEARCH_PLACEHOLDER_STR_1 = "Enter term(s)";
    DEFAULT_SEARCH_PLACEHOLDER_STR_2 = "Search by Item # or term(s)";
    DEFAULT_SEARCH_PLACEHOLDER_STR_3 = "Enter code, code range or term(s)";



    SEARCH_AUTOCOMPLETE_URL_LOOKUP_DICT = {
        // Web Service URLs for Search Autocomplete by CodeSet
        "i10": "/MVC/ICD10/GetAutoCompletionListForICD10",
        "oasis": "/MVC/OASIS/Search/GetAutoCompletionListForOASIS"
    };

    @Input() isexpert: Boolean;
    @Input() oasiscurrentsearchtext:string="" ;
    @Input() oasislookbackorforwardsearchtext: string = "";
    @Input() forwardorback: string = "back";

    @Input() enableIcdLookForward: boolean = false;
    icdLookForward: boolean = false;

    get SEARCH_TYPES_WITHOUT_OASIS_LOOKBACK() {
        return {
            "i10": [
                { searchTypeId: 7, searchTypeText: "Tabular Codes" },
                { searchTypeId: 8, searchTypeText: "Alpha Index" },
                { searchTypeId: 11, searchTypeText: "Neoplasm Table" },
                { searchTypeId: 10, searchTypeText: "Drugs & Chemicals" },
                { searchTypeId: 9, searchTypeText: "External Causes" }
            ],
            "oasis": [
                { searchTypeId: 20, searchTypeText: this.oasiscurrentsearchtext },
            ]
        };
    }

    get SEARCH_TYPE_LOOKUP_DICT()
        {
        return {
            "i10": [
                { searchTypeId: 7, searchTypeText: "Tabular Codes" },
                { searchTypeId: 8, searchTypeText: "Alpha Index" },
                { searchTypeId: 11, searchTypeText: "Neoplasm Table" },
                { searchTypeId: 10, searchTypeText: "Drugs & Chemicals" },
                { searchTypeId: 9, searchTypeText: "External Causes" }
            ],
            "oasis": [
                { searchTypeId: 20, searchTypeText: this.oasiscurrentsearchtext },
                { searchTypeId: this.forwardorback =="forward"? 22:21, searchTypeText: this.oasislookbackorforwardsearchtext },


            ]
        };
    }


    @ViewChild('codeSearch') codeSearch: ElementRef;

    browseCodesUrl: string;
    currentSearchOption: ICurrentSearchOption;
    searchTypes: string[];
    searchBoxClass: string;
    currentCodeYear: number;
    prevCodeYear: number;
    nextCodeYear: number;
    oasisCurrentCodeYear: number;
    oasisPrevCodeYear: number;
    oasisNextCodeYear: number;
    recentSearchesLoading: boolean;
    recentSearches: IRecentSearchTerm[];

    urlLookupObj: any;
    searchTypeLookupObj: any;

    isDateLoaded: boolean;
    displayOasisLookBackPage: boolean = true;

    searching = false;
    searchFailed = false;

    window: any;

    constructor(@Inject(SearchBoxService) private searchBoxService: SearchBoxService,
        @Inject(CookieService) private cookieService: CookieService, @Inject(DOCUMENT) private document: Document) {
        this.window = this.document.defaultView;
    }

    ngOnInit() {
        this.urlLookupObj = this.SEARCH_AUTOCOMPLETE_URL_LOOKUP_DICT; 
        this.searchTypeLookupObj = this.SEARCH_TYPE_LOOKUP_DICT;

        const _codeSet = this.readCurrentCodeSetCookieValue();
        const currd8 = new Date();

        let _codeYear;
        this.isDateLoaded = false;
        this.searchBoxService.getUtcNow().then(
            response => {
                const data = response;
                const today = new Date(parseInt(data.replace(/\/Date\((.*?)\)\//gi, "$1"), 10));
                _codeYear = today.getFullYear();
                this.icdLookForward = this.enableIcdLookForward && today.getUTCMonth() == 8;
                if (_codeYear < 2019) {
                    this.searchTypeLookupObj = this.SEARCH_TYPES_WITHOUT_OASIS_LOOKBACK;
                    this.displayOasisLookBackPage = false;
                    this.setSearchTypeAndText(_codeSet, this.readLastSearchTypeIdCookieValue(_codeSet));
                }
                this.isDateLoaded = true;
            });
        const strCodeYear = this.cookieService.get("CodeYear");;
        if (strCodeYear && !isNaN(parseInt(strCodeYear, 10))) {
            _codeYear = parseInt(strCodeYear, 10);
        }

        this.browseCodesUrl = "";
        this.searchTypes = this.searchTypeLookupObj[_codeSet];
        this.currentSearchOption = {
            codeSet: _codeSet,
            searchTerm: this.cookieService.get("LastSearchTerm"),
            searchTypeId: null,
            searchTypeText: null,
            codeYear: null
        };

        this.setSearchTypeAndText(_codeSet, this.readLastSearchTypeIdCookieValue(_codeSet));
        
        this.initAjax(_codeYear, _codeSet);
    }
    ngAfterViewInit() {
        const node = this.codeSearch.nativeElement;

        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                const activeValue = $(".dropdown-item.active").text();
                if (activeValue) {
                    this.currentSearchOption.searchTerm = activeValue;
                }
            });
        });

        observer.observe(node, {
            attributes: true,
            childList: false,
            attributeFilter: ['aria-activedescendant'],
            characterData: false
        });
    }
    //------------------------------------------------------------------------------------------------------------------------
    // member functions
    //------------------------------------------------------------------------------------------------------------------------
    initAjax(codeYear, codeSet) {
        //first calculate everything with the JS date functionality- which can potentially be inaccurate.
        var jsdate = new Date();
        this.currentCodeYear = jsdate.getUTCMonth() >= 9 ? jsdate.getUTCFullYear() + 1 : jsdate.getUTCFullYear();
        this.prevCodeYear = this.currentCodeYear - 1;
        this.nextCodeYear = this.currentCodeYear + 1;
        this.oasisPrevCodeYear = jsdate.getUTCFullYear() - 1;
        this.oasisCurrentCodeYear = jsdate.getUTCFullYear();
        this.oasisNextCodeYear = jsdate.getUTCFullYear() + 1;


        if (!codeYear) {
            codeYear = this.currentCodeYear;
        }

        this.setSearchBoxClass(codeSet, codeYear);

        this.browseCodesUrl = this.recalculateBrowseCodesUrl(codeSet, codeYear);
        this.currentSearchOption.codeYear = codeYear;

        //those values are just-in-case- go to the API and get a more reliable time, then set those values again.
        this.searchBoxService.getUtcNow().then(
            response => {
                const data = response;
                const today = new Date(parseInt(data.replace(/\/Date\((.*?)\)\//gi, "$1"), 10));

                this.currentCodeYear = today.getUTCMonth() >= 9 ? today.getFullYear() + 1 : today.getFullYear();
                this.prevCodeYear = this.currentCodeYear - 1;
                this.nextCodeYear = this.currentCodeYear + 1;
                this.oasisPrevCodeYear = today.getFullYear() - 1;
                this.oasisCurrentCodeYear = today.getFullYear();
                this.oasisNextCodeYear = today.getUTCFullYear() + 1;

                if (!codeYear) {
                    codeYear = this.currentCodeYear;
                }

                this.setSearchBoxClass(codeSet, codeYear);

                this.browseCodesUrl = this.recalculateBrowseCodesUrl(codeSet, codeYear);
                this.currentSearchOption.codeYear = codeYear;
            });
    }

    setSearchTypeAndText(codeSet: string, searchTypeId: number) {
        this.currentSearchOption.searchTypeId = searchTypeId;

        const array = this.searchTypeLookupObj[codeSet];
        for (let i = 0; i < array.length; i++) {
            if (array[i].searchTypeId === searchTypeId) {
                this.currentSearchOption.searchTypeText = array[i].searchTypeText;
                this.cookieService.set("SearchOption", array[i].searchTypeText, { "path": "/" });
                break;
            }
        }
        this.recalculateSearchTextBoxPlaceholderText(codeSet, searchTypeId);
    }

    recalculateSearchTextBoxPlaceholderText(codeSet: string, searchTypeId: number) {
        const searchTerm = this.currentSearchOption.searchTerm !== null ? this.currentSearchOption.searchTerm : "";

        if (!searchTerm ||
            searchTerm.trim() === "" ||
            searchTerm === this.DEFAULT_SEARCH_PLACEHOLDER_STR_1 ||
            searchTerm === this.DEFAULT_SEARCH_PLACEHOLDER_STR_2 ||
            searchTerm === this.DEFAULT_SEARCH_PLACEHOLDER_STR_3) {
            if (searchTypeId === 1 || searchTypeId === 7 || searchTypeId === 12) {
                this.currentSearchOption.searchTerm = this.DEFAULT_SEARCH_PLACEHOLDER_STR_3;
            } else if (searchTypeId === 20 || searchTypeId === 21 || searchTypeId === 22 || codeSet === "oasis") {
                this.currentSearchOption.searchTerm = this.DEFAULT_SEARCH_PLACEHOLDER_STR_2;
            } else {
                this.currentSearchOption.searchTerm = this.DEFAULT_SEARCH_PLACEHOLDER_STR_1;
            }
        }
    }

    setSearchBoxClass(codeSet: string, year: number) {
        if (codeSet === "i10") {
            this.searchBoxClass = (year === this.prevCodeYear || year === this.nextCodeYear)  ? "lookback" : "i10";
        } else if (codeSet === "oasis") {
            this.searchBoxClass = year === this.oasisPrevCodeYear || year === this.oasisNextCodeYear ? "oasis-lookback" : "oasis";
        }
    }

    changeSearchType($event, searchType) {
        $event.preventDefault();
        this.setSearchTypeAndText(this.currentSearchOption.codeSet, searchType.searchTypeId);

        //Changing oasis Browse items link by dropdown value
        if (searchType.searchTypeId == 21 || searchType.searchTypeId == 20 || searchType.searchTypeId == 22) {
            let year = searchType.searchTypeId == 20 ? this.oasisCurrentCodeYear : searchType.searchTypeId == 21 ? this.oasisPrevCodeYear : this.oasisNextCodeYear;
            this.setSearchBoxClass(this.currentSearchOption.codeSet, year)

            this.browseCodesUrl = this.recalculateBrowseCodesUrl(this.currentSearchOption.codeSet, year);
            this.setCodeYearCookie(year);
        }
    }

    changeCodeSet(newCodeSet, year) {
        const oldSearchTypeId = this.currentSearchOption.searchTypeId;

        if (!year) {
            year = this.currentCodeYear;
        }

        this.currentSearchOption.codeYear = year;
        this.browseCodesUrl = this.recalculateBrowseCodesUrl(newCodeSet, year);
        this.currentSearchOption.codeSet = newCodeSet;
        this.searchTypes = this.searchTypeLookupObj[newCodeSet];

        this.setCodeSetCookie(newCodeSet);
        this.setCodeYearCookie(year);
        this.setSearchBoxClass(newCodeSet, year);

        if (newCodeSet === "oasis") {
            switch (oldSearchTypeId) {
                case 7:
                case 8:
                case 9:
                case 10:
                case 11:
                case 20:
                    this.setSearchTypeAndText(newCodeSet, 20);
                    break;
                case 22:
                    this.setSearchTypeAndText(newCodeSet, 22);
                    break;
                default:
                    this.setSearchTypeAndText(newCodeSet, 21);
                    break;
            }
        } else if (newCodeSet === "i10") {
            switch (oldSearchTypeId) {
                case 1:
                    this.setSearchTypeAndText(newCodeSet, 7);
                    break;
                case 2:
                case 5:
                case 6:
                case 12:
                case 20:
                case 22:
                case 21:
                    this.setSearchTypeAndText(newCodeSet, 8);
                    break;
                case 3:
                    this.setSearchTypeAndText(newCodeSet, 10);
                    break;
                case 4:
                    this.setSearchTypeAndText(newCodeSet, 11);
                    break;
                case 13:
                    this.setSearchTypeAndText(newCodeSet, 9);
                    break;
            }
        }
    }

    doAuto: OperatorFunction<string, string[]> = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            tap(() => this.searching = true),
            switchMap(term => {
                if (term.length > 2) {
                    let remoteUrl = this.urlLookupObj[this.currentSearchOption.codeSet];
                    let searchTypeId = this.currentSearchOption.searchTypeId;
                    let year = this.getYearBySearchType(this.currentSearchOption.searchTypeId, this.currentSearchOption.codeYear, this.oasisCurrentCodeYear, this.oasisPrevCodeYear, this.oasisNextCodeYear);

                    return this.searchBoxService.getAutocompleteAsync(remoteUrl, term, searchTypeId, year).pipe(
                        tap(() => { this.searchFailed = false }),
                        catchError(() => {
                            this.searchFailed = true;
                            return of([]);
                        }))
                } else {
                    return [];
                }
            }),
            tap(() => this.searching = false)
        );

    performSearch() {

        const searchTerm = this.currentSearchOption.searchTerm !== null && this.currentSearchOption.searchTerm !== this.DEFAULT_SEARCH_PLACEHOLDER_STR_1
            && this.currentSearchOption.searchTerm !== this.DEFAULT_SEARCH_PLACEHOLDER_STR_2 && this.currentSearchOption.searchTerm !== this.DEFAULT_SEARCH_PLACEHOLDER_STR_3
            ? this.currentSearchOption.searchTerm.trim()
            : "";

        const searchTypeId = this.currentSearchOption.searchTypeId;
        const codeYear = this.currentSearchOption.codeYear;

        var oasisYear = searchTypeId == 21 ? this.oasisPrevCodeYear : searchTypeId == 22 ? this.oasisNextCodeYear : this.oasisCurrentCodeYear;

        if (searchTerm.length > 0) {
            this.cookieService.set("LastSearchTerm", searchTerm, { "path": "/" });

            // Save the recent search to the session
            this.searchBoxService.saveToRecentSearchesAsync(searchTypeId, searchTerm, codeYear);

            // Make sure it is a code before checking for only one result

            const regExICD10 = /(([A-Z]\d{2}(\.(\d){1,4})?)\s*-\s*([A-Z]\d{2}(\.(\d){1,4})?))|([A-Z]\d{2}(\.(\d){1,4})?)/gi;
            const regExOASIS = /(^[A-Z]{1,2}\d{4}[A-Z]{0,2})/gi;

            if (((regExICD10.test(searchTerm) && searchTypeId !== 20) || (regExICD10.test(searchTerm) && searchTypeId !== 21) || (regExICD10.test(searchTerm) && searchTypeId !== 22)) || ((regExOASIS.test(searchTerm) && searchTypeId === 20) || (regExOASIS.test(searchTerm) && searchTypeId === 21) || (regExOASIS.test(searchTerm) && searchTypeId === 22))) {
                this.searchBoxService
                    .getCount(searchTypeId, searchTerm, codeYear)
                    .then(
                        response => {
                            const data = response;
                            if (data === 1) {
                                if (this.currentSearchOption.codeSet === "oasis") {
                                    if (this.window.userData) {
                                        let params = this.window.userData;
                                        let searchUrl = `${this.window.location.origin}/MVC/OASIS/Search/Index?searchTerm=${encodeURIComponent(searchTerm)}&year=${oasisYear}`;
                                        this.window.simplify_inquiry_request(params.user_first_name, params.user_last_name, params.user_email, params.user_company, params.user_subscription_type, params.request_top_level, "Search", searchUrl, params.user_id, params.user_parent_id, searchUrl);
                                    }
                                    document.location.href = `/MVC/OASIS/MItem/Details/${searchTerm}/${oasisYear}`;
                                } else {
                                    //track the searched code
                                    if (this.window.userData) {
                                        let params = this.window.userData;
                                        let searchUrl = `${document.location.origin}/MVC/ICD10/Search?cst=${searchTypeId}&sk=${encodeURIComponent(searchTerm)}&year=${codeYear}`;
                                        this.window.simplify_inquiry_request(params.user_first_name, params.user_last_name, params.user_email, params.user_company, params.user_subscription_type, params.request_top_level, "Search", searchUrl, params.user_id, params.user_parent_id, searchUrl);
                                    }
                                    document.location.href = `/MVC/ICD10/Details/${searchTerm}/${codeYear}`;
                                }
                            } else {
                                if (this.currentSearchOption.codeSet === "oasis") {
                                    document.location.href =
                                        `/MVC/OASIS/Search/Index?searchTerm=${encodeURIComponent(searchTerm)}&year=${oasisYear}`;
                                } else {
                                    // ICD10 search
                                    const codesetFolder = "ICD10";
                                    document.location.href = `/MVC/${codesetFolder}/Search?cst=${searchTypeId}&sk=${encodeURIComponent(searchTerm)}&year=${codeYear}`;
                                }
                            }
                        },
                        function (response) {
                            console.log(response);
                        });
            } else {
                if (this.currentSearchOption.codeSet === "oasis") {
                    document.location.href = `/MVC/OASIS/Search/Index?searchTerm=${encodeURIComponent(searchTerm)}&year=${oasisYear}`;
                } else {
                    // ICD10 search
                    const codesetFolder = "ICD10";
                    document.location.href = `/MVC/${codesetFolder}/Search?cst=${searchTypeId}&sk=${encodeURIComponent(searchTerm)}&year=${codeYear}`;
                }
            }
        }
    }

    searchTextBoxOnFocus($event) {
        $event.target.select();
        const searchTerm = this.currentSearchOption.searchTerm;
        if (searchTerm === this.DEFAULT_SEARCH_PLACEHOLDER_STR_1 ||
            searchTerm === this.DEFAULT_SEARCH_PLACEHOLDER_STR_2 ||
            searchTerm === this.DEFAULT_SEARCH_PLACEHOLDER_STR_3) {
            this.currentSearchOption.searchTerm = "";
        }
    }


    loadRecentSearches() {
        this.recentSearchesLoading = true;

        this.searchBoxService.getSearches().then(
            response => {
                this.recentSearches = response;
                this.recentSearchesLoading = false;
            },
            response => {
                console.log(response);
                this.recentSearchesLoading = false;
            });
    }

    recentSearchClick(recentSearch: IRecentSearchTerm) {
        const thisSearch = recentSearch;

        const searchTypeId = parseInt(thisSearch.searchType, 10);
        var oasisYear = searchTypeId == 21 ? this.oasisPrevCodeYear : searchTypeId == 22 ? this.oasisNextCodeYear: this.oasisCurrentCodeYear;
        const searchTerm = thisSearch.searchTerm;
        const codeYear = thisSearch.year !== null && thisSearch.year !== 0 && thisSearch.year !== undefined
            ? thisSearch.year
            : this.currentCodeYear;

        // Make sure it is a code before checking for only one result

        const regExICD10 = /(([A-Z]\d{2}(\.(\d){1,4})?)\s*-\s*([A-Z]\d{2}(\.(\d){1,4})?))|([A-Z]\d{2}(\.(\d){1,4})?)/gi;
        const regExOASIS = /(^[A-Z]{1,2}\d{4}[A-Z]{0,2})/gi;

        if (((regExICD10.test(searchTerm) && searchTypeId !== 20) || (regExICD10.test(searchTerm) && searchTypeId !== 21) || (regExICD10.test(searchTerm) && searchTypeId !== 22)) || ((regExOASIS.test(searchTerm) && searchTypeId === 20) || (regExOASIS.test(searchTerm) && searchTypeId === 21) || (regExOASIS.test(searchTerm) && searchTypeId === 21))) {
            this.searchBoxService
                .getCount(searchTypeId, searchTerm, codeYear)
                .then(
                    response => {
                        const data = response;
                        if (data === 1) {
                            if (searchTypeId === 20 || searchTypeId === 21 || searchTypeId===22) {
                                document.location.href = `/MVC/OASIS/MItem/Details/${searchTerm}/${oasisYear}`;
                            } else {
                                document.location.href = `/MVC/ICD10/Details/${searchTerm}/${codeYear}`;
                            }
                        } else {
                            if (searchTypeId === 20 || searchTypeId === 21 || searchTypeId===22) {
                                document.location.href =
                                    `/MVC/OASIS/Search/Index?searchTerm=${encodeURIComponent(searchTerm)}&year=${oasisYear}`;
                            } else {
                                //ICD10 search
                                const codesetFolder = "ICD10";
                                document.location.href = `/MVC/${codesetFolder}/Search?cst=${searchTypeId}&sk=${encodeURIComponent(searchTerm)}&year=${codeYear}`;
                            }
                        }
                    },
                    function (response) {
                        console.log(response);
                    });
        } else {
            if (searchTypeId === 20 || searchTypeId === 21 || searchTypeId === 22) {
                document.location.href = `/MVC/OASIS/Search/Index?searchTerm=${encodeURIComponent(searchTerm)}&year=${oasisYear}`;
            } else {
                // ICD10 search
                const codesetFolder = "ICD10";
                document.location.href = `/MVC/${codesetFolder}/Search?cst=${searchTypeId}&sk=${encodeURIComponent(searchTerm)}&year=${codeYear}`;
            }
        }
    }

    GetClassForDropdownItem(searchTypeText: string) {
        return searchTypeText === 'OASIS-C2' ? 'oasis-lookback' : searchTypeText === 'OASIS-D' ? 'oasis' : this.searchBoxClass;
    }

    recalculateBrowseCodesUrl(codeSet: string, year: number) {
        if (codeSet === "i10") {
            return `/MVC/ICD10/Browse/${year}`;
        }
        return `/MVC/OASIS/Browse/${year}`;
    }

    getYearBySearchType(searchTypeId, icdCodeYear, oasisCurrentYear, oasisPrevYear, oasisNextYear) {
        if (searchTypeId == 20)
            return oasisCurrentYear
        else if (searchTypeId == 21)
            return oasisPrevYear
        else if (searchTypeId == 22)
            return oasisNextYear
        else
            return icdCodeYear
    }

    readLastSearchTypeIdCookieValue(codeSet: string): number {
        const v = this.cookieService.get("SearchOption");
        const arrayOfSearchTypes = this.searchTypeLookupObj[codeSet] || [];
        for (let i = 0; i < arrayOfSearchTypes.length; i++) {
            if (v === arrayOfSearchTypes[i].searchTypeText) {
                return arrayOfSearchTypes[i].searchTypeId;
            }
        }

        this.cookieService.set("SearchOption", "Alpha Index", { "path": "/" });
        return 8 /* default is I-10 "Alpha Index" */;
    }

    readCurrentCodeSetCookieValue(): string {
        const value = this.cookieService.get("CodeSet");
        if (value === "OASIS") {
            return "oasis";
        }
        this.cookieService.set("CodeSet", "ICD-10-CM", { "path": "/" });
        return "i10"; // default is I-10 codeset
    }

    setCodeSetCookie(codeSet: string) {
        const cookieName = "CodeSet";
        let translatedValue = "OASIS";
        if (codeSet === "i10") {
            translatedValue = "ICD-10-CM";
        }
        this.cookieService.set(cookieName, translatedValue, { "path": "/" });
    }

    setCodeYearCookie(codeYear: number) {
        const cookieName = "CodeYear";
        this.cookieService.set(cookieName, codeYear.toString(10), { "path": "/" });
    }
    selectedItem(item) {
        this.currentSearchOption.searchTerm = item.item;
        this.performSearch();
    }
}
export function ngbTypeaheadDefaultConfigFactory(): NgbTypeaheadConfig {
    const typeaheadConfig = new NgbTypeaheadConfig();
    typeaheadConfig.focusFirst = false;
    return typeaheadConfig;
}
